import { all } from "core-js/fn/promise";

export default function () {
  const complaintRequestFormWrapper = document.querySelector(
    ".complaint-request-form--wrapper"
  );
   let complaintRequestForm;
  const language = document.querySelector("html").getAttribute("lang");
  if (complaintRequestFormWrapper !== null) {
     complaintRequestForm = complaintRequestFormWrapper.querySelector(
       "#complaint-request-form"
     );
     // document loaded
    document.addEventListener("DOMContentLoaded", function () {
      const captchaRow = complaintRequestFormWrapper.querySelector("#captcha-row");
      if (captchaRow !== null) {
        const captchaElement = document.createElement("div");
        captchaElement.setAttribute("class", "frc-captcha");
        captchaElement.dataset.sitekey = "FCMMRK2P0U737H2U";
        captchaElement.setAttribute(
          "data-puzzle-endpoint",
          "https://eu-api.friendlycaptcha.eu/api/v1/puzzle"
        );
        captchaElement.dataset.callback = "frcCallback";
        captchaElement.dataset.lang = language;
        captchaRow.appendChild(captchaElement);
        captchaRow.classList.remove("hide");
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src =
          "/assets/correspondence-agreement/friendly-challenge-0.9.12.min.js";
        document.head.appendChild(script);
        // console.log("loadScript");
      }
    });

    if (complaintRequestForm !== null) {
      const submitButton = complaintRequestForm.querySelector(
        "#complaint-request-submit"
      );
      complaintRequestForm.addEventListener("change", function () {
        const checkFields = checkRequiredFields(complaintRequestForm, false);
        if (checkFields === true) {
          submitButton.disabled = false;
        } else {
          submitButton.disabled = true;
        }
      });

      // replace diacrictics
      function replaceDiacritics(input) {
        const diacriticsMap = {
          "\u0218": "S",
          "\u0219": "s",
          "\u021A": "T",
          "\u021B": "t",
          "\u00CE": "I",
          "\u00EE": "i",
          "\u0102": "A",
          "\u0103": "a",
          "\u00C2": "A",
          "\u00E2": "a",
          "\u015F": "s",
          "\u015E": "S",
          "\u0163": "t",
          "\u0162": "T",
        };

        return input.replace(
          /[\u0218\u0219\u021A\u021B\u00CE\u00EE\u0102\u0103\u00C2\u00E2\u015F\u015E\u0163\u0162]/g,
          function (match) {
            return diacriticsMap[match];
          }
        );
      }

      const inputsToReplaceDiacritics = complaintRequestForm.querySelectorAll(
        ".check_diacritics"
      );
      if (inputsToReplaceDiacritics !== null) {
        inputsToReplaceDiacritics.forEach(function (input) {
          input.addEventListener("keyup", function () {
            const value = input.value;
            const errorData = input.getAttribute("data-error");
            input.value = replaceDiacritics(value);
          });
        });
      }

      submitButton.addEventListener("click", function (e) {
        e.preventDefault();
        const checkFields = checkRequiredFields(complaintRequestForm, true);
        if (checkFields === true) {
          const formData = new FormData(complaintRequestForm);
          const submitUrl = "/services/complaintform/senddata";
          fetch(submitUrl, {
            method: "POST",
            enctype: "multipart/form-data",
            body: formData,
          })
            .then((response) => {
              if (!response.ok) {
                throw Error(response.statusText);
              }
              return response.text();
            })
            .then((data) => {
              console.log("response text: ", data);
              const formRows = complaintRequestForm.querySelectorAll(
                ".complaint-request-form-row"
              );
              if (formRows !== null) {
                formRows.forEach(function (row) {
                  row.classList.add("is-valid");
                  row.classList.remove("is-invalid");
                });
              }
              const inputs = complaintRequestForm.querySelectorAll("input");
              if (inputs !== null) {
                inputs.forEach(function (input) {
                  input.setAttribute("disabled", "disabled");
                });
              }
              //disable all select boxes
              const allSelectBoxes = complaintRequestForm.querySelectorAll("select");
              allSelectBoxes.forEach(function (selectBox) {
                selectBox.setAttribute("disabled", "disabled");
              });
              //disable all textareas
              const allTextareas = complaintRequestForm.querySelectorAll("textarea");
              allTextareas.forEach(function (textarea) {
                textarea.setAttribute("disabled", "disabled");
              });
              //disable all buttons
              const allButtons = complaintRequestForm.querySelectorAll("button");
              allButtons.forEach(function (button) {
                button.setAttribute("disabled", "disabled");
              });
              submitButton.classList.add("hide");
              const successMessage = complaintRequestFormWrapper.querySelector(
                "#success-message"
              );
              if (successMessage !== null) {
                successMessage.classList.remove("hide");
              }
              //window.location.replace(location.pathname);
            })
            .catch((error) => {
              alert(
                "An error has occurred, please try again later or contact us by email"
              );
              console.log("error", error);
            })
            .finally(() => {
              console.log("finally");
            });
        }
      });
    }

    function validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gim;
      return re.test(email);
    }

    // check required fields
    function checkRequiredFields(parentRow, addValidationClass) {
      const requiredInputFields = parentRow.querySelectorAll("input[required]");
      const errorMessages = parentRow.querySelectorAll(".error-message");
      if (errorMessages !== null) {
        errorMessages.forEach(function (message) {
          message.classList.add("hide");
        });
      }
      // console.log("requiredInputFields: ", requiredInputFields);
      let fieldsValid = false;
      for (const input of requiredInputFields) {
        const parentRow = input.closest(".complaint-request-form-row");
        // validate email
        if (input.type === "email") {
          const checkEmail = validateEmail(input.value);
          if (checkEmail === false && addValidationClass === true) {
            const errorMessage = parentRow.querySelector(".error-message");
            if (errorMessage !== null) {
              errorMessage.classList.remove("hide");
              errorMessage.textContent = "Please enter a valid email address";
            }
            if (parentRow !== null && addValidationClass === true) {
              parentRow.classList.add("is-invalid");
            }
            fieldsValid = false;
            break;
          }
        } else if (
          input.value === "" ||
          (input.type === "checkbox" && !input.checked) ||
          (input.type === "file" && input.files.length === 0)
        ) {
          if (parentRow !== null && addValidationClass === true) {
            parentRow.classList.add("is-invalid");
          }
          fieldsValid = false;
          break;
        } else {
          if (parentRow !== null && addValidationClass === true) {
            parentRow.classList.remove("is-invalid");
          }
          fieldsValid = true;
        }
      }
      return fieldsValid;
    }
    const copieIdentificationFiles = complaintRequestForm.querySelectorAll(
      "input[type=file]"
    );
    if (copieIdentificationFiles !== null) {
      copieIdentificationFiles.forEach(function (fileInput) {
          // Get input element and add event listener
          fileInput.onchange = function () {
            const maxSize = 1000000;
            if (this.files[0].size > maxSize) {
              const alertMessage = complaintRequestForm.querySelector(
                'label[for="document-1"] small'
              );
              if (alertMessage !== null) {
                alert(alertMessage.textContent);
              }
              this.files[0].value = "";
            } else {
              const inputId = this.id;
              const divSelector = "#" + inputId + "-name";
              complaintRequestForm.querySelector(divSelector).textContent =
                this.files[0].name + " (" + this.files[0].size + " bytes)";
            }
          };
        });
    }
    const allTextareas = complaintRequestForm.querySelectorAll("textarea");
    // check character count and limit to 2000
    if (allTextareas !== null) {
      allTextareas.forEach(function (textarea) {
        let maxCount = 200;
        const textareaId = textarea.id;
        if (textareaId === "complaint-details") {
          maxCount = 2000;
        }
        textarea.addEventListener("keyup", function () {
          const characterCount = textarea.value.length;
          if (characterCount >= maxCount) {
            textarea.value = textarea.value.substring(0, maxCount);
            alert("You have reached the maximum number of characters (" + maxCount +")");
          }
        });
      });
    }
  }
}
