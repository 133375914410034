export default function () {
 // set id attribute on all video elements
  const videoElements = document.querySelectorAll('.azuremediaplayer__wrapper video');
  if (videoElements.length > 0) {
    Array.prototype.forEach.call(videoElements, (videoElement, index) => {
      videoElement.setAttribute('id', `azuremediaplayer-${index}`);
      // add event listener to video element click
      let clickCounter = 0;
      videoElement.addEventListener('click', (event) => {
        if(dataLayer){
          clickCounter++;
          let eventValue = 'video_play';
          // if clickCounter is odd, set eventValue to video_play
          // if clickCounter is even, set eventValue to video_end
          if (clickCounter % 2 === 0) {
            eventValue = 'video_end';
          } else {
            eventValue = 'video_play';
          }
          // get data oid attribute
          const videoOid = videoElement.dataset.oid === undefined ? 'no-oid' : videoElement.dataset.oid;
          const dataLayerInfo = `'${videoOid}'`;
          dataLayer.push({
            event: eventValue,
            video_name: dataLayerInfo,
          });
        }
      });
    });

    // place script tag in head
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', '//amp.azure.net/libs/amp/2.3.11/azuremediaplayer.min.js');
    head.appendChild(script);

    // place link tag in head
    const link = document.createElement('link');
    link.setAttribute('type', 'text/css');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('href', '//amp.azure.net/libs/amp/2.3.11/skins/amp-default/azuremediaplayer.min.css');
    head.appendChild(link);
  }
}
