import loadMore from '../../03-molecules/load-more/load-more.js';
import triggerResize from '../../../assets/scripts/helpers/resize-trigger.js';
import { all } from 'core-js/fn/promise';

/**
 * This script is for generic filters.
 * It is based on filter.js, but has a generic approach.
 *
 * custom filter change:
 * search for those tabs only, where this filter is attached!
 * this could be determined by the custom style classes of the shown filters.
 *
 */
export default function () {

	let $ctx = $('.js-content-filter-view');

	if ($ctx.length < 0) {
		return;
	}

	let $searchField = $('.js-content-filter-view .js-search-field');
	let $searchButton = $('.js-content-filter-view .js-search-ajax');
	let $dateFrom = $('.js-content-filter-view .js-date-from');
	let $dateTo = $('.js-content-filter-view .js-date-to');
	let $newsHint = $('.js-content-filter-view .content-filter-search__hint');
  let $blogCategorySelect = $('.js-content-filter-view .js-blog-category');
  let $blogTagSelect = $('.js-content-filter-view .js-blog-tag');
  let $blogLocationSelect = $('.js-content-filter-view .js-blog-location');


	/**
	 * If the search phrase is shorter than 3 characters display the hint text in red, otherwise show the hint in grey.
	 */
	function validateSearchPhrase() {
		if ($searchField.val().length < 3) {
			$newsHint.addClass("warning");
			return false;
		} else {
			$newsHint.removeClass("warning");
		}
		return true;
	}


	/**
	 * Don't send the search phrase to the server if it's shorter than 3 characters.
	 * @returns {string}
	 */
	function getSearchPhrase() {
		return ($searchField.val().length < 3) ? '' : $searchField.val();
	}


	/**
	 * Collect all values for the query.
	 * Add the search phrase to the query string.
	 *
	 * We assume, that search and date-range filter are used in all tabs.
	 * All the other filters may be tab-specific and should be applied by the tab they uses it only.
	 *
	 * Collect the values of all select boxes, tag-triggers and date fields and add them to the query string.
	 * Set the page parameter to 1 because we should always start with the first page when we change the filters/search.
	 */
	function collectUrlParams(dataUrl, tab) {

		dataUrl = replaceParam(dataUrl, "&page=", 1);
		dataUrl = replaceParam(dataUrl, "&dateFrom=", ($dateFrom.val() || ""));
		dataUrl = replaceParam(dataUrl, "&dateTo=", ($dateTo.val() || ""));
		dataUrl = replaceParam(dataUrl, "&search=", getSearchPhrase());
    if ($blogCategorySelect.length) {
      let blogCategoryValue = $blogCategorySelect.val();
      dataUrl = replaceParam(dataUrl, "&blogcategory=", blogCategoryValue);
    }
    if ($blogTagSelect.length) {
      let blogTagValue = $blogTagSelect.val();
      dataUrl = replaceParam(dataUrl, "&blogtags=", blogTagValue);
    }
    if ($blogLocationSelect.length) {
      let blogLocationValue = $blogLocationSelect.val();
      dataUrl = replaceParam(dataUrl, "&location=", blogLocationValue);
    }


		// gather custom filters, tab-wise:
		$('.js-content-filter-view .js-content-filters .js-content-filter--custom--' + tab).each(function () {

			// this is a tag filter
			if ($(this).find('.filter__tags').length === 1) {
				const name = $(this).find('.filter__tags').data('name');
				let arr = [];
				$(this).find('.js-tag-trigger.is-active').each(function () {
					const val = $(this).data('value') || $(this).text();
					arr.push(val);
				});
				dataUrl = replaceParam(dataUrl, "&" + name + "=", arr.join());
			}

			// this is a dropdown filter
			if ($(this).find('select').length === 1) {
				const sel = $(this).find('select');
				dataUrl = replaceParam(dataUrl, "&" + sel.prop('name') + "=", sel.val());
			}
		});
		return dataUrl;
	}


	/**
	 * Set the url parameter and their values
	 *
	 * @param dataUrl: the url to the template which will send the data to the client
	 * @param paramString: the current param, for example "&category="
	 * @param value
	 * @returns {*}
	 */
	function replaceParam(dataUrl, paramString, value) {

		let start = dataUrl.indexOf(paramString);
		if (start !== -1) {
			// found the param -> replace it's value
			start = start + paramString.length; // here starts the value for this param

			// now get the first "&" after the current param
			const sub = dataUrl.substring(start, dataUrl.length);
			let end = sub.indexOf("&");
			if (end === -1) {
				end = dataUrl.length;
			}
			//assamble the new url
			dataUrl = dataUrl.substring(0, start) + value + dataUrl.substring(start + end, dataUrl.length);

		} else {
			// param not found, attach it to the url
			dataUrl += paramString + value;
		}
		return dataUrl;
	}


	/**
	 * For each load-more-button (one per tab) get the data-url (= template url) and fetch the data to render.
	 * If there's no tabs we should have only one load-more-button.
	 *
	 * if isCustomFilter, only the active tab should load data
	 *
	 * @param reset
	 * @param isCustomFilter
	 */
	function loadData(reset, isCustomFilter) {
		console.log("** loadData **", reset, isCustomFilter);

		if (isCustomFilter) {
			blockSearch();
		}

		$ctx.find('.js-load-more').each((index, btn) => {
      const tab = $(btn).data("for-tab");
      let dataUrl = $(btn).data("url")
        ? $(btn).data("url")
        : $(btn).data("init-url");

      let selectedTab = "";
      const allTabs = document.querySelectorAll('.tabcordion__input');
      allTabs.forEach((tab) => {
        if (tab.checked) {
          selectedTab = tab.value;
        }
      });
      console.log("jgdebug selectedTab", selectedTab);
      console.log("jgdebug tab", tab);
      if (isCustomFilter && selectedTab !== tab) {
        return; // continue, if not active tab
      }
      if (!dataUrl) {
        return;
      }

      dataUrl = collectUrlParams(dataUrl, tab);

      console.log("dataUrl for tab " + tab + ":", dataUrl);

      //TODO: search should return json, not rendered html.
      fetch(dataUrl)
        .then((r) => r.text())
        .then((data) => {
          releaseSearch();
          renderData(data, btn, tab);
        })
        .catch((e) => {
          releaseSearch();
          console.error(e);
        });
    });

    $ctx.find('.js-load-more-search').each((index, btn) => {
      const tab = $(btn).data('for-tab');
			let dataUrl = $(btn).data('url');
      console.log("jgdebug tab", tab);
      console.log("jgdebug tab-item-search checked", $("input[name='tab-item-search']:checked").val());
      if (isCustomFilter && $("input[name='tab-item-search']:checked").val() !== tab) {
				return; // continue, if not active tab
			}
			if (!dataUrl) {
				return;
			}
      const selectItems = document.querySelectorAll(
        ".js-filter select[multiple]"
      );
      let filterParams = "";
      // check datePicker
      if ($dateFrom.val()) {
        filterParams = filterParams + "&dateFrom=" + $dateFrom.val();
      }
      if ($dateTo.val()) {
        filterParams = filterParams + "&dateTo=" + $dateTo.val();
      }
      $(btn).data("filter", "");
      Array.prototype.forEach.call(selectItems, function (selectItem) {
        if (
          selectItem.parentNode.parentNode.classList.contains("is-active") ||
          selectItem.parentNode.classList.contains("is-active")
        ) {
          // console.debug("jgdebug filtering of search page", selectItem.name);
          const values = Array.prototype.slice
            .call(selectItem.querySelectorAll("option:checked"), 0)
            .map(function (v, i, a) {
              return v.value;
            });
          if (values.length > 0) {
            filterParams = filterParams + "&" + selectItem.name + "=" + values;
          }
        }
      });
      $(btn).attr("data-filter", filterParams);
      if(reset){
        $(btn).attr("data-reset", "true");
      }
      console.debug("jgddebug filter url", $(btn).data("filter"));
      console.debug("button: ",$(btn));
      $(btn).children("button").click();
      releaseSearch();
    });

		if (!reset) {
			$ctx.find('.js-filter-button').addClass('filters-applied');
		}
	}


	/**
	 * blockSearch: blocks UI to request a new search
	 */
	function blockSearch() {
		$('.js-content-filter-view').addClass('content-filter--in-process');
	}

	/**
	 * releaseSearch: unblocks search UI
	 */
	function releaseSearch() {
		$('.js-content-filter-view').removeClass('content-filter--in-process');
	}


	/**
	 * This function is called for each tab. So each tab (wrapper) is filled with data separately and only the load-more-button
	 * inside this tab is initialised (loadMore).
	 *
	 * @param data
	 * @param btn
	 * @param tab
	 */
	function renderData(data, btn, tab) {
    if (btn.classList.contains("news-archive")) {
      renderJSONData(data, btn);
    } else {
      // replace data
      $(btn).parent().html(data);

      triggerResize();

      // re-init the load more button, there may be a new one inside this wrapper sent from the back end template...
      // since one can't mix jquery with plain js selectors, we have to use js selectors here. yet.
      const loadMoreButton = document.querySelector(".js-content-filter-view .js-tab-content--" + tab + " .js-load-more");
      if (loadMoreButton) {
        loadMore(loadMoreButton.parentNode);
      }
    }
	}

  function renderJSONData(data, buttonWrapper) {
    console.log("** renderJSONData content filter **");
    const contentWrapper = buttonWrapper.parentNode;
    if (contentWrapper) {
      const dataInitUrl = buttonWrapper.getAttribute("data-init-url");
      const dataTab = buttonWrapper.getAttribute("data-for-tab");
      const isMansoryTab = contentWrapper.classList.contains("mansonry");
      const socialCardContainer = contentWrapper.querySelector(
        ".social-cards-container"
      );
      if (dataInitUrl !== null) {
        const urlParams = new URLSearchParams(dataInitUrl);
        // console.log("jgdebug urlParams", urlParams.get("tab"));
        if (urlParams.get("page") !== null) {
          const pageNumber = parseInt(urlParams.get("page"));
          const dataInitUrlLoadmore = dataInitUrl.replace(
            "page=" + pageNumber,
            "page=" + (pageNumber + 1)
          );
          buttonWrapper.setAttribute("data-init-url", dataInitUrlLoadmore);
        } else {
          const dataInitUrlLoadmore = dataInitUrl + "&page=2";
          buttonWrapper.setAttribute("data-init-url", dataInitUrlLoadmore);
        }
      }
      const socialTypes = [
        "facebook",
        "twitter",
        "linkedin",
        "youtube",
        "instagram",
      ];
      data = JSON.parse(data);
      const cards = data.data.sort((a, b) => {
        if (a.publishingdate === b.publishingdate) {
          const differenceinminA = parseInt(a.differenceinmin);
          const differenceinminB = parseInt(b.differenceinmin);
          return differenceinminA - differenceinminB;
        }
        return new Date(b.publishingdate) - new Date(a.publishingdate);
      });
      console.log("jgdebug newsjson", cards);
      let cardsTemplate = "";
      // get html lang attribute
      const htmlLang = document.querySelector("html").getAttribute("lang");
      let minutesLabel = "minutes";
      let hoursLabel = "hours";
      let yesterdayLabel = "yesterday";
      if (htmlLang !== null && htmlLang !== undefined && htmlLang === "de") {
        minutesLabel = "Minuten";
        hoursLabel = "Stunden";
        yesterdayLabel = "gestern";
      }

      cards.forEach((card) => {
        const cardType = card.type;
        const cardLabel = card.label;
        let dataDimensions = "1.7777777777777777";
        let socialClass = "card--owcs";
        let socialTypeClass = "";
        let gridRowEndClass = "";
        let linkTarget = "_self";
        const cardWithImage = card.image !== null && card.image !== "" && card.image !== undefined ? true : false;
        if (cardWithImage) {
          gridRowEndClass = "col--2x";
        } else if (cardType == "News") {
          gridRowEndClass = "col--2x";
        } else if (cardType == "InternalBlog") {
          gridRowEndClass = "col--2x";
        } else if (cardType == "FrontBlogPost") {
          gridRowEndClass = "col--2x";
        }
        let cardTitle = "";
        // convert minutes to hours
        const differenceinmin = card.differenceinmin;
        let cardTime = card.publishingdate;
        if (differenceinmin && differenceinmin > 0) {
          if (differenceinmin && differenceinmin < 60) {
            cardTime = differenceinmin + " "+ minutesLabel;
          } else if (differenceinmin && differenceinmin < 1440) {
            const hours = Math.floor(differenceinmin / 60);
            cardTime = hours + " "+ hoursLabel;
          } else if (
            differenceinmin &&
            differenceinmin >= 1440 &&
            differenceinmin < 2880
          ) {
            cardTime = " "+ yesterdayLabel;
          }
        }
        if (socialTypes.includes(cardType)) {
          linkTarget = "_blank";
          dataDimensions = "1.3333333333333333";
          if (cardType === "instagram") {
            dataDimensions = "1";
            gridRowEndClass = "col--2x";
          } else if (cardType === "facebook") {
            dataDimensions = "1";
            gridRowEndClass = "col--2x";
          }
          socialClass = "card--wallsio";
          socialTypeClass = "card--" + cardType;
        } else {
          cardTitle = `<h3>${card.title}</h3>`;
        }
        let contentTemplate = `<a href="${card.href}" class="card ${socialClass} ${gridRowEndClass} ${socialTypeClass}" data-target="card" target="${linkTarget}">`;
        if (cardWithImage) {
          contentTemplate += `<figure>
				  <img data-dimensions="${dataDimensions}" alt="${card.title}" title="${card.title}" width="auto" height="auto" src="${card.image}" fetchpriority="high" decoding="async">
				</figure>`;
        }
        let labelClass = cardType === "News" ? "press" : cardType.toLowerCase();
        let cardTextTemplate = `<div class="card__text">
                      <div>
                        ${cardTitle}
                        <p>
                          ${card.leadin}
                        </p>
                      </div>
                    </div>`;
            if (card.usermap !== null && card.usermap !== undefined && card.usermap !== "") {
              const authorPhotoURL = card.usermap.authorPhotoURL;
              const encodedPhoto = card.usermap.encodedPhoto;
              const usermapTitle = card.usermap.title !== null && card.usermap.title !== undefined && card.usermap.title !== "" ? card.usermap.title : "";
              const usermapCompany = card.usermap.omvCompany !== null && card.usermap.omvCompany !== undefined && card.usermap.omvCompany !== "" ? card.usermap.omvCompany : "";
              const authorImage = encodedPhoto !== null && encodedPhoto !== undefined && encodedPhoto !== "" ? `data:image/jpeg;base64,${encodedPhoto}` : authorPhotoURL;
              cardTextTemplate = `<div class="card__text">
                      <div>
                        <div class="card__author">
                            <figure class="author-image">
                              <img src="${authorImage}" data-dimensions="1" alt="" width="auto" height="auto"/>
                            </figure>
                          <div class="card__author-info">
                            <small>von <strong>${card.usermap.givenName} ${card.usermap.sn}</strong></small>
                            <small>${usermapTitle}</small>
                            <small>${usermapCompany}</small>
                          </div>
                        </div>
                        ${cardTitle}
                        <p>
                          ${card.leadin}
                        </p>
                      </div>
                    </div>`;
            }
        contentTemplate += `<div class="card__meta">
					  <small class="card__date">${cardTime}</small>
					  <div class="card__spacer"></div>
					  <small class="context-label context-label--${labelClass}">${cardLabel}</small>
					</div>
					${cardTextTemplate}
				  </a>`;
        cardsTemplate += contentTemplate;
      });
      buttonWrapper.classList.remove("loading");
      if (isMansoryTab) {
        if (socialCardContainer !== null) {
          socialCardContainer.innerHTML = cardsTemplate;
        }
      } else {
        contentWrapper.innerHTML = cardsTemplate;
      }
      const button = buttonWrapper.querySelector("button");
      if (cards.length > 0) {
        if (button) {
          const showMore = data.showmore;
          if (
            showMore !== null &&
            showMore !== undefined &&
            showMore === true
          ) {
            if (button && button.classList.contains("hide")) {
              button.classList.remove("hide");
            }
          } else {
            button.classList.add("hide");
          }
        }
        triggerResize();
      } else {
        if (button && !button.classList.contains("hide")) {
          button.classList.add("hide");
        }
      }
    }
  }


	/**
   When the search input field is in focus the search button is visible.
   After clicking the search button it turns into a delete button (is-active). When you click it in this state the
   search field's value is set to "" and the button is hidden.
   In both cases the search is triggered either with a certain value or with value "".
	 */
	function handleSearchButton() {

		if ($searchButton.hasClass("is-active")) {
			$searchField.val('');
			$newsHint.removeClass('warning');
			$searchButton.removeClass("is-active");
			loadData(true);
		} else {
			$searchButton.addClass("is-active");
			if (validateSearchPhrase()) {
				loadData(true);
			}
		}
	}


	/**
	 * When you click inside the search field always remove the class is-active from the search button.
	 */
	function handleSearchField() {
		$searchButton.removeClass("is-active");
	}


	/**
   Activate the clicked tags.
	 */
	function handleTagTriggers(tag, isCustomFilter) {
		$(tag).toggleClass('is-active');
    console.log("jgdebug handleTagTriggers isCustomFilter", isCustomFilter);
		loadData(false, isCustomFilter);
	}


	/**
   Open / close the overlay and the filters
   mobile only
	 */
	function toggleFilterOverlay() {

		$ctx.find('.js-content-filters').toggleClass('is-active');
    if (!document.querySelector(".js-content-filters").classList.contains("is-active")) {
      const badgeElement = document.querySelector(".filter-button .badge");
      const allCheckedFilter = document.querySelectorAll(".content-filter__filters .content-filter.is-active .multiselect-dropdown-list:has(div.checked)");
      console.log("jgdebug allCheckedFilter", allCheckedFilter);
      if (badgeElement !== null && allCheckedFilter !== null) {
        if (allCheckedFilter.length > 0) {
          badgeElement.textContent = allCheckedFilter.length;
          badgeElement.parentNode.classList.add("filters-applied");
        } else {
          badgeElement.parentNode.classList.remove("filters-applied");
        }
      }
    }
		// since »noscroll« will be removed by a few other events (hamburger, sticky bar), we can't rely on it here.
		$('body').toggleClass('noscroll-filter');
	}


	/**
   Reset all filters, including the date picker.
   @param init: true if called on page load -> don't get current values and don't load content
	 */
	function resetFilters(init) {
		$searchField.val('');
		$dateFrom.val('');
		$dateTo.val('');
    $('input[name="daterange"]').val("");
    const activeTab = $(".js-content-filter-view .js-tab-select").val();

		$ctx.find('.js-tag-trigger').removeClass("is-active");
		// $ctx.find('select option:first').attr('selected', 'selected');
		$ctx.find('.js-filter-button').removeClass('filters-applied');
		$ctx.find('.daterangepicker').find('.cancelBtn').click();

    // console.log("jgdebug resetFilters activeTab", activeTab);
    if (activeTab !== null && activeTab !== undefined) {
      $(".js-content-filter-view .js-tab-select").val(activeTab);
    }

		MultiSelectDropdown({reset:true});
		if (init === true) return;

		loadData(true,true);
	}

	/**
	 sort select box alphabetical
	 @param selectElement: select element
	 */
	function sortOptions(selectElement) {
		let options = selectElement.options;
		let optionsArray = [];
		let isPreSelected = false;
		for (let i = 0; i < options.length; i++) {
			if(options[i].selected) {
				isPreSelected = true;
			}
			optionsArray.push(options[i]);
		}
		optionsArray = optionsArray.sort(function (a, b) {
			return a.innerHTML.toLowerCase().charCodeAt(0) - b.innerHTML.toLowerCase().charCodeAt(0);
		});
		for (let i = 0; i <= options.length; i++) {
			options[i] = optionsArray[i];
		}
		if(isPreSelected) {
			options[0].selected = true;
		}
	}


	/**
   Assign event handlers
	 */
	if ($searchField && $searchButton) {

		$($searchButton).mousedown(handleSearchButton);

		$searchField
			.mousedown(handleSearchField)
			.keyup(e => {
				if (validateSearchPhrase() && e.keyCode === 13) {
					if ($searchButton.hasClass("is-active")) {
						//the delete button is visible, don't delete, but load data
						loadData(true);
					} else {
						//load data and show the delete button
						handleSearchButton();
					}
				} else {
					// any other key
					$searchButton.removeClass("is-active");
				}
			});
	}

	$ctx.find('.js-filter-reset').mouseup(resetFilters);
	$ctx.find('.js-filter-toggle').mouseup(toggleFilterOverlay);

	$ctx.find('.js-tag-trigger').mouseup(function() {
		handleTagTriggers($(this), $(this).closest('.js-filter').hasClass('js-content-filter--custom'));
	});

	$ctx.find('.js-content-filters select').change(function() {
		// loadData(false, $(this).closest('.js-filter').hasClass('js-content-filter--custom'));
	});


	// listen to the date change event of the date picker
	window.addEventListener("filterDateChange", function() {
    loadData(false,true);
  });

	const selectBoxes = document.querySelectorAll(".js-content-filters select");
	if(selectBoxes !== null) {
		Array.prototype.forEach.call(selectBoxes, function(selectBox) {
			sortOptions(selectBox);
		});
	}

	/*
	const selectBoxesMultiSelect = document.querySelectorAll("select.multiselect");
	if(selectBoxesMultiSelect !== null) {
		$("select.multiselect").multiselect({
			selectedList: 4 // 0-based index
		});
	}
	 */

	// on page load reset all filters (Firefox remembers content of input fields)
	// resetFilters(true);
	const MultiSelectDropdown = (params) => {
		let config = {
			search: false,
			hideX: false,
			useStyles: true,
			selectAll: false,
			placeholder: 'Select...',
			txtSelected: 'Selected',
			txtAll: 'All',
			txtRemove: 'Remove',
			txtSearch: 'Search...',
			minWidth: '10rem',
			// maxWidth: '360px',
			// maxHeight: '20rem',
			// borderRadius: 6,
			...params
		};

		if(config.reset !== undefined) {
			document.querySelectorAll('select[multiple]').forEach((multiSelect) => {
        if (!multiSelect.hasAttribute("data-search")) {
          multiSelect.selectedIndex = -1;
          document.querySelectorAll("div.checked").forEach((checkeItem) => {
            checkeItem.classList.remove("checked");
            if (checkeItem.querySelector("input") !== null){
              checkeItem.querySelector("input").checked = false;
            }
          });
        }
			});
			return;
		}

		let activeMultiSelectElement = null;

		const newElement = (tag, params) => {
			let element = document.createElement(tag);
			if (params) {
				Object.keys(params).forEach((key) => {
					if (key === 'class') {
						Array.isArray(params[key])
								? params[key].forEach((o) => (o !== '' ? element.classList.add(o) : 0))
								: params[key] !== ''
										? element.classList.add(params[key])
										: 0;
					} else if (key === 'style') {
						Object.keys(params[key]).forEach((value) => {
							element.style[value] = params[key][value];
						});
					} else if (key === 'text') {
						params[key] === '' ? (element.innerHTML = '&nbsp;') : (element.innerText = params[key]);
					} else {
						element[key] = params[key];
					}
				});
			}
			return element;
		};

		document.querySelectorAll('select[multiple]').forEach((multiSelect) => {
			let div = newElement('div', { class: 'multiselect-dropdown' });
      let isSearchContext = false;
      if (multiSelect.hasAttribute("data-search")) {
        // console.debug("jgdebug muliselect in search context");
        isSearchContext = true;
        multiSelect.selectedIndex = -1;
        document.querySelectorAll("div.checked").forEach((checkeItem) => {
          checkeItem.classList.remove("checked");
          if (checkeItem.querySelector("input") !== null) {
            checkeItem.querySelector("input").checked = false;
          }
        });
        div.setAttribute("data-search", "true");
      }
			multiSelect.style.display = 'none';
			multiSelect.parentNode.insertBefore(div, multiSelect.nextSibling);
			let dropdownListWrapper = newElement('div', { class: 'multiselect-dropdown-list-wrapper' });
			let dropdownList = newElement('div', { class: 'multiselect-dropdown-list' });
			let search = newElement('input', {
				class: ['multiselect-dropdown-search'].concat([config.searchInput?.class ?? 'form-control']),
				style: {
					width: '100%',
					display: config.search ? 'block' : multiSelect.attributes['search']?.value === 'true' ? 'block' : 'none'
				},
				placeholder: config.txtSearch
			});
			dropdownListWrapper.appendChild(search);
			div.appendChild(dropdownListWrapper);
			dropdownListWrapper.appendChild(dropdownList);

			multiSelect.loadOptions = () => {
				dropdownList.innerHTML = '';

				if (config.selectAll || multiSelect.attributes['select-all']?.value === 'true') {
					let optionElementAll = newElement('div', { class: 'multiselect-dropdown-all-selector' });
					let optionCheckbox = newElement('input', { type: 'checkbox' });
					optionElementAll.appendChild(optionCheckbox);
					optionElementAll.appendChild(newElement('label', { text: config.txtAll }));

					optionElementAll.addEventListener('click', () => {
						optionElementAll.classList.toggle('checked');
						optionElementAll.querySelector('input').checked = !optionElementAll.querySelector('input').checked;

						let ch = optionElementAll.querySelector('input').checked;
						dropdownList.querySelectorAll(':scope > div:not(.multiselect-dropdown-all-selector)').forEach((i) => {
							if (i.style.display !== 'none') {
								i.querySelector('input').checked = ch;
								i.srcElement.selected = ch;
							}
						});

						multiSelect.dispatchEvent(new Event('change'));
					});
					optionCheckbox.addEventListener('click', () => {
						optionCheckbox.checked = !optionCheckbox.checked;
					});
					dropdownList.appendChild(optionElementAll);
				}

				const inputType = multiSelect.attributes['select-single']?.value === 'true' ? "radio" : "checkbox";
				Array.from(multiSelect.options).map((option, index) => {
          if (isSearchContext === true && index === 0) {
            option.selected = true;
          }
					let optionElement = newElement('div', { class: option.selected ? 'checked' : '', srcElement: option });
					let optionCheckbox = newElement('input', { type: inputType , checked: option.selected });
					if(inputType === "radio") {
						optionCheckbox.setAttribute("name", "radio_"+multiSelect.attributes['name']);
					}
					optionElement.appendChild(optionCheckbox);
					optionElement.appendChild(newElement('label', { text: option.text }));

					optionElement.addEventListener('click', () => {
            if (isSearchContext) {

              const allCheckedItems = optionElement.parentNode.querySelectorAll(".checked");
              multiSelect.selectedIndex = -1;
              allCheckedItems.forEach((checkeItem) => {
                checkeItem.classList.remove("checked");
                if (checkeItem.querySelector("input") !== null) {
                  checkeItem.querySelector("input").checked = false;
                }
              });
            }
            optionElement.classList.toggle("checked");
						optionElement.querySelector('input').checked = !optionElement.querySelector('input').checked;
						optionElement.srcElement.selected = !optionElement.srcElement.selected;
						multiSelect.dispatchEvent(new Event('change'));
					});
					optionCheckbox.addEventListener('click', () => {
						if (inputType === "radio") {
              multiSelect.selectedIndex = -1;
            }
						optionCheckbox.checked = !optionCheckbox.checked;
					});
					option.optionElement = optionElement;
					dropdownList.appendChild(optionElement);
				});
				div.dropdownListWrapper = dropdownListWrapper;

				div.refresh = () => {
					div.querySelectorAll('span.optext, span.placeholder').forEach((placeholder) => div.removeChild(placeholder));
					let selected = Array.from(multiSelect.selectedOptions);
					if (selected.length > (multiSelect.attributes['max-items']?.value ?? 5)) {
						div.appendChild(
								newElement('span', {
									class: ['optext', 'maxselected'],
									text: selected.length + ' ' + config.txtSelected
								})
						);
					} else {
						selected.map((option) => {
							let span = newElement('span', {
								class: 'optext',
								text: option.text,
								srcElement: option
							});
							if (!isSearchContext && (!config.hideX || multiSelect.attributes['select-single']?.value === 'true')) {
								span.appendChild(
										newElement('span', {
											class: 'optdel',
											text: '',
											title: config.txtRemove,
											onclick: (e) => {
												span.srcElement.optionElement.dispatchEvent(new Event('click'));
												div.refresh();
												e.stopPropagation();
                        loadData(false,true);
											}
										})
								);
							}
							div.appendChild(span);
						});
					}
					if (multiSelect.selectedOptions?.length === 0) {
						div.appendChild(
								newElement('span', {
									class: 'placeholder',
									text: multiSelect.attributes?.placeholder?.value ?? config.placeholder
								})
						);
					} else {
						// loadData(false, multiSelect.closest('.js-filter').classList.contains('js-content-filter--custom'));
					}
				};
				div.refresh();
			};
			multiSelect.loadOptions();

			search.addEventListener('input', () => {
				dropdownList.querySelectorAll(':scope div:not(.multiselect-dropdown-all-selector)').forEach((div) => {
					let innerText = div.querySelector('label').innerText.toLowerCase();
					div.style.display = innerText.includes(search.value.toLowerCase()) ? 'flex' : 'none';
				});
			});

			div.addEventListener('click', () => {
        // console.log("jgdebug div clicked");
				div.dropdownListWrapper.style.display = 'block';
				search.focus();
				search.select();
				activeMultiSelectElement = div;
			});

			document.addEventListener('click', (e) => {
				if (!div.contains(e.target)) {
					dropdownListWrapper.style.display = 'none';
					div.refresh();
				}
			});
		});

		//if (activeMultiSelectElement !== null){
			document.addEventListener('click', (e) => {
				if (activeMultiSelectElement !== null && activeMultiSelectElement !== e.target && !activeMultiSelectElement.contains(e.target)) {
          const activeSearchTab = document.querySelector(".search-page .tabcordion--tab-content.is-active ");
          if (activeSearchTab !== null){
            const selectItems = document.querySelectorAll(".js-filter select[multiple]");
            const activeButton = activeSearchTab.querySelector(".js-load-more-search");
            if(activeButton !== null){
              let filterParams = "";
              if (activeButton.hasAttribute("data-filter")) {
                activeButton.setAttribute("data-filter", "init");
              }
              Array.prototype.forEach.call(selectItems, function (selectItem) {
                if (
                  selectItem.parentNode.parentNode.classList.contains(
                    "is-active"
                  ) || selectItem.parentNode.classList.contains("is-active")
                ) {
                  const values = Array.prototype.slice
                    .call(selectItem.querySelectorAll("option:checked"), 0)
                    .map(function (v, i, a) {
                      return v.value;
                    });
                  if (values.length > 0) {
                    filterParams =
                      filterParams + "&" + selectItem.name + "=" + values;
                  }
                }
              });
              activeButton.setAttribute("data-filter", filterParams);
              activeButton.setAttribute("data-reset","true");
                console.debug(
                  "jgddebug filter url",
                  activeButton.getAttribute("data-filter")
                );
              activeMultiSelectElement = null;
              // console.log(activeButton.querySelector("button"));
              activeButton.querySelector("button").click();
            }
          } else if (activeMultiSelectElement.hasAttribute("data-search")) {
            return;
          } else {
					  loadData(false, activeMultiSelectElement.closest('.js-filter').classList.contains('js-content-filter--custom'));
          }
					activeMultiSelectElement = null;
				}
			});
		//}

		const createStyles = () => {
			let styles = {
				':root': {
					'--color-background': '#ffffff',
					'--color-border': '#ced4da',
					'--color-background--option': '#d6dde6',
					'--color-background--option--hover': '#cbd5e0a1',
					'--color-text--normal': '#0c0c0c',
					'--color-text--grey': '#24262c',
					'--color-text--red': '#cc6666',
					'--color-text--placeholder': '#ced4da',
					'--border-radius--base': '6px',
					'--border-radius--small': '4px'
				},
				'.multiselect-dropdown': {
					position: 'relative',
					display: 'inline-flex',
					'flex-wrap': 'wrap',
					padding: '6px 0px 6px 6px',
					gap: '6px',
					border: 'solid 1px var(--color-border)',
					background: 'var(--color-background)',
					'background-image':
							"url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e\")",
					'background-repeat': 'no-repeat',
					'background-position': 'right 10px center',
					'background-size': '25px 18px',
					'width': '97%',
					cursor: 'pointer'
				},
				'span.optext, span.placeholder': {
					display: 'inline-flex',
					'justify-content': 'center',
					'align-items': 'center',
					'font-size': '16px',
					'border-radius': 'var(--border-radius--small)'
				},
				'span.optext': {
					'background-color': 'var(--color-background--option)',
					padding: '0 12px 2px 6px',
					cursor: 'default',
					'-webkit-user-select': 'none',
					'-moz-user-select': 'none',
					'-ms-user-select': 'none',
					'user-select': 'none'
				},
				'span.optext .optdel': {
					position: 'relative',
					top: '0px',
					left: '10px',
					'font-size': '12px',
					cursor: 'pointer',
					color: 'transparent'
				},
				'span.optext .optdel:hover': {
					color: 'var(--color-text--red)'
				},
				'span.placeholder': {
					color: 'var(--color-border)'
				},
				'.multiselect-dropdown-list-wrapper': {
					'z-index': 100,
					'border-radius': 'var(--border-radius--base)',
					border: 'solid 1px var(--color-border)',
					display: 'none',
					margin: '-1px',
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					background: 'var(--color-background)'
				},
				'.multiselect-dropdown-search': {
					padding: '5px 6px 6px 5px',
					'border-top-left-radius': 'var(--border-radius--base)',
					'border-top-right-radius': 'var(--border-radius--base)',
					border: 'solid 1px transparent',
					'border-bottom': 'solid 1px var(--color-border)',
					'font-size': 'inherit'
				},
				'.multiselect-dropdown-search::placeholder': {
					color: 'var(--color-text--placeholder)',
					'font-size': '16px'
				},
				'.multiselect-dropdown-search:focus, .multiselect-dropdown-search:focus-visible': {
					outline: 'none'
				},
				'.multiselect-dropdown-list': {
					'overflow-y': 'auto',
					'overflow-x': 'hidden',
					height: '100%',
					'max-height': `${config.maxHeight}` ?? '160px'
				},
				'.multiselect-dropdown-list::-webkit-scrollbar': {
					width: '4px'
				},
				'.multiselect-dropdown-list::-webkit-scrollbar-thumb': {
					'background-color': 'var(--color-background--option)',
					'border-radius': '1000px'
				},
				'.multiselect-dropdown-list div, .multiselect-dropdown-list div > input, .multiselect-dropdown-list div > label':
						{
							cursor: 'pointer',
							'border-radius': 'var(--border-radius--base)'
						},
				'.multiselect-dropdown-list div': {
					display: 'flex',
					'align-items': 'center',
					'justify-content': 'flex-start',
					'column-gap': '6px',
					padding: '6px',
					margin: '6px 8px 6px 6px',
					transition: '100ms cubic-bezier(0.455, 0.03, 0.515, 0.955)'
				},
				'.multiselect-dropdown-list div:hover': {
					'background-color': 'var(--color-background--option--hover)'
				},
				'.multiselect-dropdown-list-input': {
					height: '14px',
					width: '14px',
					border: 'solid 1px var(--color-text--grey)',
					margin: 0
				},
				'.multiselect-dropdown span.maxselected': {
					width: '100%'
				},
				'.multiselect-dropdown-all-selector': {
					'border-bottom': 'solid 1px var(--color-border)'
				}
			};
			const style = document.createElement('style');
			style.setAttribute('type', 'text/css');
			style.innerHTML = `${Object.keys(styles)
			.map(
					(selector) =>
							`${selector} { ${Object.keys(styles[selector])
							.map((property) => `${property}: ${styles[selector][property]}`)
							.join('; ')} }`
			)
			.join('\n')}`;
			document.head.appendChild(style);
		};

		config.useStyles && createStyles();
	};

	window.addEventListener('load', () => {
		MultiSelectDropdown(window.MultiSelectDropdownOptions);
	});
};
