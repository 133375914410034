import resizeIframe from '../../../02-atoms/_inline-frame/iframe-controller';


export default function () {


  /**
   * This is not cross-origin-save! - It works only if iframe is located on the same server.
   */

  const commentFrame = document.querySelector(".js-commenting");

  if(!commentFrame) { return; }

  /**
   * Call the resize function inside iframe-controller.
   */
  function dispatchResizing() {
    setTimeout(function () {
      resizeIframe(commentFrame);
    }, 300);
  }


  /**
   * add event listeners
   */
  function createEventListener() {

    const innerDoc = commentFrame.contentDocument || commentFrame.contentWindow.document;

    // disable submit buttons when form is submitted
    const submitBtns = innerDoc.querySelectorAll("button.button--submit");

    Array.prototype.forEach.call(submitBtns, (btn) => {
      btn.disabled = true;
      btn.form.addEventListener('submit', e => {
        btn.disabled = true;
      });
    });


    // reply-buttons inside the iframe:
    // when textareas are displayed, more space and thus a new height of the iframe is necessary.
    const replyBtns = innerDoc.getElementsByClassName("js-reply");
    const editBtns = innerDoc.getElementsByClassName("js-edit-btn");
    const deleteBtns = innerDoc.getElementsByClassName("js-delete-btn");
    const cancelBtns = innerDoc.getElementsByClassName("js-cancel-btn");
    const cancelDeleteBtns = innerDoc.getElementsByClassName("js-cancel-delete-btn");
    const confirmDeleteBtns = innerDoc.getElementsByClassName("js-confirm-delete-btn");

    Array.prototype.forEach.call(replyBtns, (btn) => {
      btn.addEventListener('mouseup', dispatchResizing);
    });

    Array.prototype.forEach.call(editBtns, (btn) => {
      btn.addEventListener('mouseup', dispatchResizing);
    });

    Array.prototype.forEach.call(deleteBtns, (btn) => {
      btn.addEventListener('mouseup', dispatchResizing);
    });

    Array.prototype.forEach.call(cancelBtns, (btn) => {
      btn.addEventListener('mouseup', dispatchResizing);
    });

    Array.prototype.forEach.call(cancelDeleteBtns, (btn) => {
      btn.addEventListener('mouseup', dispatchResizing);
    });

    Array.prototype.forEach.call(confirmDeleteBtns, (btn) => {
      btn.addEventListener('mouseup', dispatchResizing);
    });
  }


  commentFrame.addEventListener('load', createEventListener);

};
