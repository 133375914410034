/**
 *  Every time a component is opened other components which listen to this event are closed first
 *
 *  Additionally, when clicking on certain elements specified in the querySelectors below, components are closed as well.
 */

function stopAllComponents() {
	const stopEvent = document.createEvent('Event');
	stopEvent.initEvent('stopAllComponents', true, true);
	window.dispatchEvent(stopEvent);
}

const containers = document.querySelectorAll("footer, .span-12, .grid, .js-slider");

Array.prototype.forEach.call(containers, (container) => {
	if (container) {
		container.addEventListener('mousedown', stopAllComponents);
	}
});


const clickTargets = document.querySelectorAll("body, .meta-panel, .meta-panel__wrapper, .channel__panel, .main-navigation-wrapper");

Array.prototype.forEach.call(clickTargets, (clickTarget) => {
	clickTarget.addEventListener('mouseup', (e) => {
		if (e.target === this) {
			// do this only if the click is on the element itself, not it's children
			stopAllComponents();
		}
	});
});

export default stopAllComponents;
