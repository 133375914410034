import translate from "../../../assets/scripts/helpers/translations.js";

export default function () {
  const bivWForm = document.querySelector(".biv-form--wrapper");
  const ePayHost = "https://precision-api.epayworldwide.com"; //"https://precision-api-test.demu1wgw.epayworldwide.com";
  const bivResultWrapper = document.querySelector(".biv-result--wrapper");
  const bivErrorWrapper = document.querySelector(".biv-error--wrapper");
  const bivInputWrapper = document.querySelector(".biv-form--wrapper");
  const extendExpireDateForm = document.querySelector("#extend-expire-date");
  const language = document.querySelector("html").lang || "de_AT";
  const languageWithUnderscore = language.replace("-", "_");
  const transactionTable = document.querySelector("#transactions");
  let spinner = null;
  let captcha_id = null;
  if (bivWForm !== null) {
    const checkBoxShowPin = document.querySelector("#showPin");
    if (checkBoxShowPin !== null) {
      checkBoxShowPin.addEventListener("change", function () {
        const pinInput = document.querySelector("#internet_pin");
        if (pinInput !== null) {
          if (this.checked) {
            pinInput.type = "text";
          } else {
            pinInput.type = "password";
          }
        }
      });
    }

    const bivSubmitBtn = document.querySelector("#bivSubmitBtn");
    if (bivSubmitBtn !== null) {
      bivSubmitBtn.addEventListener("click", function (e) {
        e.preventDefault();
        if (transactionTable !== null) {
          const parent = transactionTable.parentNode;
          spinner = document.createElement("div");
          spinner.id = "bivSpinner";
          spinner.classList.add("spinner");
          parent.insertBefore(spinner, transactionTable);
        }
        let showResult = true;
        const bivInputs = bivWForm.querySelectorAll(
          "input:not([type='checkbox'])"
        );
        if (bivInputs !== null) {
          bivInputs.forEach((input) => {
            const inputId = input.id;
            const inputLabel = document.querySelector(
              `label[for="${inputId}"]`
            );
            if (input.value === "") {
              showResult = false;
              input.classList.add("input--marked");
              if (inputLabel !== null) {
                inputLabel.classList.add("error");
              }
            } else {
              input.classList.remove("input--marked");
              if (inputLabel !== null) {
                inputLabel.classList.remove("error");
              }
            }
          });
        }
        if (showResult === true) {
          if (spinner !== null) {
            spinner.style.display = "flex";
          }
          getCardDetails();
        }
      });
    }

    const bivResetBtn = document.querySelector("#bivResetBtn");
    if (bivResetBtn !== null) {
      bivResetBtn.addEventListener("click", function (e) {
        e.preventDefault();
        if (bivResultWrapper !== null) {
          bivResultWrapper.style.display = "none";
        }
        const bivInputs = bivWForm.querySelectorAll("input");
        if (bivInputs !== null) {
          bivInputs.forEach((input) => {
            const inputId = input.id;
            const inputLabel = document.querySelector(
              `label[for="${inputId}"]`
            );
            input.value = "";
            input.classList.remove("input--marked");
            if (inputLabel !== null) {
              inputLabel.classList.remove("error");
            }
          });
        }
        getCaptchImage();
      });
    }

    addButtonListener();

    const captchaResetBtn = document.querySelector("#captchaResetBtn");
    if (captchaResetBtn !== null) {
      captchaResetBtn.addEventListener("click", function (e) {
        e.preventDefault();
        getCaptchImage();
      });
    }

    getCaptchImage();
  }

  function addButtonListener() {
    const bivReloadBtns = document.querySelectorAll(".bivReloadBtn");
    const bivResetBtn = document.querySelector("#bivResetBtn");
    bivReloadBtns.forEach((bivReloadBtn) => {
      bivReloadBtn.addEventListener("click", function (e) {
        e.preventDefault();
        if (bivResetBtn !== null) {
          bivResetBtn.click();
          bivInputWrapper.style.display = "flex";
          bivErrorWrapper.style.display = "none";
          if (transactionTable !== null) {
            transactionTable.classList.remove("show");
          }
          if (spinner !== null) {
            document.querySelector("#bivSpinner").remove();
          }
          if (extendExpireDateForm !== null) {
            const extenExpireDateHeadline =
              extendExpireDateForm.previousElementSibling;
            if (
              extenExpireDateHeadline !== null &&
              !extenExpireDateHeadline.classList.contains("extend-expire-date")
            ) {
              extenExpireDateHeadline.classList.add("extend-expire-date");
            }
            extendExpireDateForm.classList.add("extend-expire-date");
            extendExpireDateForm.innerHTML = `<button class="button button--white" id="bivChangeExpireDateBtn">Karte verlängern</button>`;
          }
        }
      });
    });
  }

  function getCaptchImage() {
    const getCaptchImageUrl = `${ePayHost}/api/v1/captcha/generate`;
    fetch(getCaptchImageUrl, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((json) => {
        captcha_id = json.data.captcha_id;
        const encoded_image = json.data.encoded_image;
        const captchaImage = document.querySelector("#captchaImage");
        captchaImage.src = encoded_image;
      });
  }

  function getCardDetails() {
    const captchaInput = document.querySelector("#captcha_code");
    if (captcha_id !== null && captchaInput !== null) {
      const cardHolderLogin = `${ePayHost}/api/v1/cardholder/login`;
      fetch(cardHolderLogin, {
        method: "POST",
        // add post parameters
        body: JSON.stringify({
          card_number: document.querySelector("#card_number").value,
          internet_pin: document.querySelector("#internet_pin").value,
          captcha_id: captcha_id,
          captcha_code: captchaInput.value,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          //console.log("jgdebug getCardDetails response:", response);
          if (response.status === 200) {
            return response.json();
          } else {
            //console.log("jgdebug getCardDetails response:", response);
            if (bivResultWrapper !== null) {
              bivResultWrapper.style.display = "none";
            }
            if (bivErrorWrapper !== null) {
              bivErrorWrapper.style.display = "flex";
            }
            if (bivInputWrapper !== null) {
              bivInputWrapper.style.display = "none";
            }
            return null;
          }
        })
        .then((json) => {
          if (json !== null) {
            console.log("jgdebug getCardDetails json:", json);
            const cardId = json.data.card_id;
            const token = json.data.token;
            const voucherStatusMap = new Map();
            // replace "-" with "_" in language
            voucherStatusMap.set(
              "Created",
              translate("voucher_is_not_active", languageWithUnderscore)
            );
            voucherStatusMap.set(
              "Activated",
              translate("voucher_is_active", languageWithUnderscore)
            );
            voucherStatusMap.set(
              "temp. Deactivated",
              translate("voucher_is_blocked", languageWithUnderscore)
            );
            voucherStatusMap.set(
              "Deactivated",
              translate("voucher_is_blocked", languageWithUnderscore)
            );
            voucherStatusMap.set(
              "Expired",
              translate("voucher_is_expired", languageWithUnderscore)
            );
            voucherStatusMap.set(
              "invoice cancelled",
              translate("voucher_is_not_active", languageWithUnderscore)
            );
            voucherStatusMap.set(
              "invoice paid",
              translate("voucher_is_not_active", languageWithUnderscore)
            );
            voucherStatusMap.set(
              "Issued",
              translate("voucher_is_active", languageWithUnderscore)
            );
            voucherStatusMap.set(
              "In delivery",
              translate("voucher_is_not_active", languageWithUnderscore)
            );
            voucherStatusMap.set(
              "Reserved",
              translate("voucher_is_not_active", languageWithUnderscore)
            );
            voucherStatusMap.set(
              "Returned",
              translate("voucher_is_not_active", languageWithUnderscore)
            );
            voucherStatusMap.set(
              "Exchanged",
              translate("voucher_is_exchanged", languageWithUnderscore)
            );
            voucherStatusMap.set(
              "Manufacturer",
              translate("voucher_in_production", languageWithUnderscore)
            );
            voucherStatusMap.set(
              "Archived",
              translate("voucher_is_not_active", languageWithUnderscore)
            );
            voucherStatusMap.set(
              "Redeemed",
              translate("voucher_in_redeemed", languageWithUnderscore)
            );
            if (bivResultWrapper !== null) {
              const voucherStatus = json.data.card_details.status;
              const voucherStatusText = voucherStatusMap.get(voucherStatus);
              if (voucherStatusText !== undefined) {
                bivResultWrapper.querySelector(
                  "#voucher-status"
                ).innerHTML = voucherStatusText;
              }
              const balance = json.data.card_details.accounts[0].balance;
              const currency = json.data.card_details.accounts[0].currency;
              if (
                balance !== undefined &&
                currency !== undefined &&
                balance !== null &&
                currency !== null
              ) {
                // cent to euro
                const balanceEuro = (balance / 100).toFixed(2);
                bivResultWrapper.querySelector(
                  "#voucher-balance"
                ).innerHTML = `${balanceEuro} ${currency}`;
              }
              let validUntil = json.data.card_details.valid_until;
              let validUntilDate = null;
              if (validUntil !== undefined && validUntil !== null) {
                if (validUntil.indexOf("T") !== -1) {
                  const [dateString, timeString] = validUntil.split("T");
                  const [
                    validUntilYear,
                    validUntilMonth,
                    validUntilDay,
                  ] = dateString.split("-");
                  const [hours, minutes, seconds] = timeString.split(":");
                  const slicedSeconds = seconds.slice(0, 2);
                  validUntil = `${validUntilDay}.${validUntilMonth}.${validUntilYear}`;
                  validUntilDate = new Date(
                    +validUntilYear,
                    +validUntilMonth - 1,
                    +validUntilDay,
                    +hours,
                    +minutes,
                    +slicedSeconds
                  );
                }
                bivResultWrapper.querySelector(
                  "#voucher-valid-until"
                ).innerHTML = validUntil;
              }

              const cardDetails = `${ePayHost}/api/v1/cards/${cardId}`;
              // console.log("jgdebug cardDetails: ", cardDetails);
              // console.log("jgdebug token: ", token);
              const bearerToken = `Bearer ${token}`;
              const myHeaders = new Headers();
              myHeaders.append("Authorization", bearerToken);
              const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };

              fetch(cardDetails, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                  // console.log(result);
                  const cardProfileId = result.data.card.card_profile_id;
                  const transactionsUrl = `${ePayHost}/api/v1/transactions?card_id=${cardId}&card_profile_id=${cardProfileId}&page=1&limit=100`; //https://precision-api-test.demu1wgw.epayworldwide.com/api/v1/transactions?card_id=630045423&card_profile_id=24996&page=1&limit=10
                  fetch(transactionsUrl, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                      // console.log(result);
                      const transactions = result.data.transactions;
                      const tBody = bivResultWrapper.querySelector(
                        "#transactions tbody"
                      );
                      let tBodyHtml = "";
                      if (transactions !== undefined && transactions !== null) {
                        const doNotShowTypes = [
                          "Card login",
                          "Activation",
                          "PreAuth",
                          "Status",
                        ];
                        transactions.forEach((transaction) => {
                          let showTransaction = true;
                          const {
                            date_server,
                            type_en,
                            amount,
                            orig_currency,
                            currency,
                            result,
                          } = transaction;
                          showTransaction =
                            result !== undefined &&
                            result !== null &&
                            result === 0;
                          if (showTransaction) {
                            showTransaction = !doNotShowTypes.includes(type_en);
                            if (showTransaction) {
                              let currencyString = orig_currency;
                              if (type_en === "Cancel") {
                                currencyString = currency;
                                showTransaction = checkCancelTransaction(
                                  transaction,
                                  transactions
                                );
                              }
                              if (showTransaction) {
                                // cent to euro with 2 decimals also for 0.00
                                const transactionAmountEuro = (
                                  amount / 100
                                ).toFixed(2);

                                const dateString = formatDate(
                                  date_server,
                                  true
                                );
                                let typeString = "";
                                switch (type_en) {
                                  case "Temp. Deactivation":
                                    typeString = translate(
                                      "transaction_type_temp_deactivation",
                                      languageWithUnderscore
                                    );
                                    break;
                                  case "Capture (redeem the amount - online)":
                                    typeString = translate(
                                      "transaction_type_capture",
                                      languageWithUnderscore
                                    );
                                    break;
                                  default:
                                    typeString = translate(
                                      `transaction_type_${type_en.toLowerCase()}`,
                                      languageWithUnderscore
                                    );
                                    break;
                                }
                                tBodyHtml += `
                                  <tr>
                                    <td>${dateString}</td>
                                    <td>${typeString}</td>
                                    <td>${transactionAmountEuro} ${currencyString}</td>
                                  </tr>
                                `;
                              }
                            }
                          }
                        });
                        tBody.innerHTML = tBodyHtml;
                        if (spinner !== null) {
                          spinner.style.display = "none";
                        }
                        if (transactionTable !== null) {
                          transactionTable.classList.add("show");
                        }
                      }
                    })
                    .catch((error) => console.log("error", error));
                  if (
                    validUntilDate !== null &&
                    cardProfileId !== null &&
                    cardId !== null &&
                    token !== null
                  ) {
                    handleExtendExpireDate(
                      validUntilDate,
                      cardProfileId,
                      cardId,
                      token
                    );
                  }
                })
                .catch((error) => console.log("error", error));
              bivResultWrapper.style.display = "flex";
              bivInputWrapper.style.display = "none";
            }
          } else {
            console.log("jgdebug getCardDetails json is null");
          }
        });
    }
  }

  function checkCancelTransaction(cancelTransaction, transactions) {
    let showTransaction = false;
    const { txref } = cancelTransaction;
    console.log("***** JGDEBUG checkCancelTransaction txref: ", txref);
    transactions.forEach((transaction) => {
      const { txid, type_en } = transaction;
      if (txid === txref && (type_en === "Redeem" || type_en === "Refund")) {
        console.log("***** JGDEBUG checkCancelTransaction txid: ", txid);
        console.log("***** JGDEBUG checkCancelTransaction type_en: ", type_en);
        showTransaction = true;
      }
    });
    console.log(
      "***** JGDEBUG checkCancelTransaction showTransaction: ",
      showTransaction
    );

    return showTransaction;
  }

  function formatDate(date, withTime) {
    if (date.indexOf("T") !== -1) {
      const [dateString, timeString] = date.split("T");
      if (withTime) {
        const [
          validUntilYear,
          validUntilMonth,
          validUntilDay,
        ] = dateString.split("-");
        const [hours, minutes, seconds] = timeString.split(":");
        const slicedSeconds = seconds.slice(0, 2);
        return `${validUntilDay}.${validUntilMonth}.${validUntilYear} ${hours}:${minutes}:${slicedSeconds}`;
      } else {
        const [
          validUntilYear,
          validUntilMonth,
          validUntilDay,
        ] = dateString.split("-");
        return `${validUntilDay}.${validUntilMonth}.${validUntilYear}`;
      }
    } else {
      return date;
    }
  }

  function handleExtendExpireDate(
    validUntilDate,
    cardProfileId,
    cardId,
    token
  ) {
    console.log("JGDEBUG handleExtendExpireDate cardProfileId ", cardProfileId);
    const profileIds = [
      "26511",
      "26510",
      "26509",
      "26508",
      "26507",
      "26506",
      "26505",
      "26504",
      "21947",
      "21946",
      "21945",
      "21944",
      "12398",
      "11632",
      "11631",
      "11630",
      "10140",
      "10139",
      "10138",
      "10137",
      "10136",
      "10135",
      "10134",
      "10133",
      "9010",
      "9009",
      "8965",
      "8964",
      "8963",
      "8962",
      "8957",
      "8955",
      "8953",
      "8951",
      "8857",
      "8856",
      "8855",
      "8854",
      "8853",
      "456",
      "406",
      "404",
      "403",
      "402",
      "349",
      "348",
      "347",
      "346",
      "345",
      "344",
      "343",
      "342",
    ];
    const canExpire = profileIds.includes(cardProfileId.toString());
    console.log(
      "JGDEBUG handleExtendExpireDate profileIds include cardProfileId: ",
      canExpire
    );
    if (canExpire) {
      console.log(
        "JGDEBUG handleExtendExpireDate validUntilDate: ",
        validUntilDate
      );
      // today
      const today = new Date();
      // if validUntilDate is in the past or validUntilDate is 14 days before today
      const twoWeeks = 1000 * 60 * 60 * 24 * 14;
      const before14Days = new Date(validUntilDate.getTime() - twoWeeks);
      console.log(
        "JGDEBUG handleExtendExpireDate before14Days: ",
        before14Days
      );
      console.log("JGDEBUG handleExtendExpireDate today: ", today);
      console.log(
        "JGDEBUG handleExtendExpireDate validUntilDate: ",
        validUntilDate
      );
      if (before14Days.getTime() <= today.getTime()) {
        const extendExpireDateButtons = document.querySelector(
          "#bivChangeExpireDateBtn"
        );
        if (extendExpireDateButtons !== null) {
          // add event listener to extendExpireDateButtons
          extendExpireDateButtons.addEventListener("click", (event) => {
            event.preventDefault();
            const extendExpireDateApiUrl = `${ePayHost}/api/v1/cards/${cardId}/change-expiry`;
            // extend expire date tody + 6 months
            validUntilDate.setMonth(validUntilDate.getMonth() + 6);
            // format newValidUntilDate to yyyy-mm-dd hh:mm:ss
            const newValidUntilDateFormatted = validUntilDate
              .toISOString()
              .slice(0, 19)
              .replace("T", " ");
            fetch(extendExpireDateApiUrl, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              // request body
              body: JSON.stringify({
                valid_until: newValidUntilDateFormatted,
              }),
            })
              .then((response) => {
                console.log("jgdebug extendExpireDate response: ", response);
                const dateString = formatDate(
                  validUntilDate.toISOString(),
                  false
                );
                const extendExpireFeedBack = translate(
                  "updateExpireFeedback",
                  languageWithUnderscore
                );
                if (extendExpireDateForm !== null) {
                  extendExpireDateForm.innerHTML = `${extendExpireFeedBack} ${dateString}`;
                }
              })
              .catch((error) => console.log("error", error));
          });
        }
        const extendExpireDate = document.querySelectorAll(
          ".extend-expire-date"
        );
        if (extendExpireDate !== null) {
          extendExpireDate.forEach((element) => {
            element.classList.remove("extend-expire-date");
          });
        }
      }
    }
  }
}
