export default function () {
  const modalButtons = document.querySelectorAll('.video__wrapper .modal button');
  Array.prototype.forEach.call(modalButtons, (modalButton) => {
    modalButton.addEventListener('click', e => {
      const modal = modalButton.parentNode;
      const modalParent = modal.parentNode
      const iframe = modalParent.firstChild.nextSibling;
      modal.classList.remove("active");
      iframe.classList.remove("hide");
      iframe.classList.add("active");
    });
  });
}
