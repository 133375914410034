import stopAllComponents from '../../../assets/scripts/helpers/stop-components';


export default function () {

	const overlay = document.querySelector(".js-overlay");
	const overlayClose = document.querySelector(".js-overlay-close");

	const hamburger = document.querySelector('#main-navigation__toggle');
	const navigation = document.querySelector('#main-navigation');

	function toggleMenu(state) {
		const isV2Version = navigation.classList.contains("main-navigation--v2");
		const mainNavigationElement =  isV2Version ? document.querySelector(".main-navigation--v2") : document.querySelector(".main-navigation");
		if (state === 'true') { // open menu

			stopAllComponents();
			hamburger.classList.add('is-active');
			mainNavigationElement.classList.add("is-active");
			document.querySelector("body").classList.add("noscroll");
			//document.querySelector(".meta-panel").classList.add("is-active");
			if (overlay) {
				overlay.classList.add("is-active", "for-navigation");
			}

      // open active expanders
			const selector = "li.active";
      Array.prototype.forEach.call(navigation.querySelectorAll(selector), (liActiveItem) => {
        const expander = liActiveItem.querySelector('.expander');//
        if(expander) {
          expander.classList.add("expander--is-open");
        }
      });

		} else { // close menu
			hamburger.classList.remove('is-active');
			mainNavigationElement.classList.remove("is-active");
			document.querySelector("body").classList.remove("noscroll");
			//document.querySelector(".meta-panel").classList.remove("is-active");

			if (overlay) {
				overlay.classList.remove("is-active", "for-navigation");
			}

			// close the expanders
			Array.prototype.forEach.call(navigation.querySelectorAll('.expander'), (expander) => {
				expander.classList.remove('expander--is-open');
			});
		}

		// …and only then update the attribute for `aria-expanded`
		hamburger.setAttribute('aria-expanded', state);

		// …and update global value so other elements can query state
		document.body.setAttribute('data-menu-expanded', state);
	}


	// add listeners
	if (hamburger && navigation) {
		hamburger.addEventListener('mouseup', e => {
			const state = hamburger.getAttribute('aria-expanded') === 'false' ? 'true' : 'false';
			toggleMenu(state);
			e.preventDefault();
		});
	}

	window.addEventListener('stopAllComponents', () => {
		toggleMenu(false);
	});

	if (overlayClose) {
		overlayClose.addEventListener('mouseup', () => {
			toggleMenu(false);
		});
	}

	// close menu on [ESC]
	window.addEventListener('keyup', e => {
		if (e.keyCode === 27) {
			toggleMenu(false);
		}
	});
}
