import debounce from './debounce.js';


export default function () {

  /**
   * Round image size up
   *
   * @param width
   * @returns {number}
   */
  function roundWidth(width) {
    let roundingPoint = 25;

    if (width > 300) {
      roundingPoint = 50;
    }
    else if (width > 600) {
      roundingPoint = 100;
    }
    else if (width > 1000) {
      roundingPoint = 200;
    }
    else if (width > 1500) {
      roundingPoint = 500;
    }
    if (width % roundingPoint === 0) {
      // don't add the roundingPoint if the width is already round
      return width;
    }
    return Math.floor(width - width % roundingPoint + roundingPoint);
  }


  /**
   * Loop through all images, get their data-src and the dimension attribute, calculate the available width and height
   * for the image and set the image path.
   * Allow static images: If there's no {w} in the url and the data-src exists just copy the image path from data-src to src.
   * If there's no ratio we assume 16:9 (1.777778).
   *
   * Lightbox
   * There's no data-dimensions and no {h} for the large image. Only the width is set. The image server will send the image with
   * the set width and the original ratio of the image.
   *
   * @param images
   * @param attr: src for images or href for a-tags (lightbox)
   * @param isLightBoxImage
   * @param isBackground
   */
  function handleImages(images, attr, isLightBoxImage, isBackground) {

    Array.prototype.forEach.call(images, function(image) {
      let dataSrc = image.getAttribute("data-src");
      let ratio = image.getAttribute("data-dimensions") || 1.77777778;
      ratio = parseFloat(ratio);

      if (dataSrc && dataSrc.indexOf("{w}") !== -1) {

        let imageWidth = isLightBoxImage ? document.body.offsetWidth : image.parentNode.offsetWidth;
        if (imageWidth === 0) {
          imageWidth = image.parentNode.parentNode.offsetWidth;
        }
        if (imageWidth === 0) {
          return;
        }

        imageWidth = roundWidth(imageWidth);
        let imageHeight = Math.ceil(imageWidth / ratio);
        dataSrc = dataSrc.replace( "{w}", `${imageWidth}` );
        dataSrc = dataSrc.replace( "{h}", `${imageHeight}` );
        if (isBackground) {
          dataSrc = `background-image: url(${dataSrc})`;
        }
        image.setAttribute(attr, dataSrc);

      } else if (dataSrc) {
        image.setAttribute(attr, dataSrc);
      }
    });
  }


  /**
   *  Get all images and enlargeable images (lightbox) and call further functions to set their path.
   */
  function getImages() {
    const images = document.querySelectorAll("img");
    const lightBoxImages = document.querySelectorAll(".glightbox");
    const sliderImages = document.querySelectorAll(".js-slider-slide figure");

    handleImages(images, "src", false, false);

    handleImages(lightBoxImages, "href", true, false);

    handleImages(sliderImages, "style", false, true);
  }


  window.addEventListener('resize', debounce(() => {
    getImages();
  }, 300));

  getImages();
}
