export default function () {

  const accordions = document.getElementsByClassName("js-accordion");

  if(accordions.length == 0) return;

  function triggerAccordion() {

    const closedItem = this.parentNode.parentNode.querySelector(".quickClose");
    if(closedItem) {
      closedItem.classList.remove("quickClose");
    }

    const clickedItem = this.parentNode;
    if(clickedItem.classList.contains("accordion-is-open")) {
      clickedItem.classList.remove("accordion-is-open");
      return;
    }

    const openItem = this.parentNode.parentNode.querySelector(".accordion-is-open");
    if(openItem) {
      openItem.classList.add("quickClose");
      openItem.classList.remove("accordion-is-open");
    }

    this.parentNode.classList.add("accordion-is-open");
  }


  Array.prototype.forEach.call(accordions, function(accordion) {
    const triggers = accordion.getElementsByClassName("js-accordion-trigger");

    Array.prototype.forEach.call(triggers, function(trigger) {
      trigger.addEventListener('mouseup', triggerAccordion);
    });
  });


};
