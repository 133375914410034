export default function (swipe) {

	// console.log("swipe:", swipe, JSON.stringify(swipe));

	let swipeLeft;
	let swipeRight;
	let swipeWrapper;

	/**
	 * Find and assign swipe elements (only direct children because nested swipers are possible)
	 */
	Array.prototype.forEach.call(swipe.children, (el) => {
		// console.log("children el:", el);

		if (el.className.indexOf("js-swipe-left") !== -1) {
			swipeLeft = el;

		} else if (el.className.indexOf("js-swipe-right") !== -1) {
			swipeRight = el;

		} else if (el.className.indexOf("js-swipe-wrapper") !== -1) {
			swipeWrapper = el;
		}
	});

	if (typeof swipeLeft === "undefined" || typeof swipeRight === "undefined" || typeof swipeWrapper === "undefined") {
		// console.error("Error: The Swiper has no buttons or no swipe wrapper! The components stops here.");
		return false;
	}

	let swipeLeftBtn = swipeLeft;
	let swipeRightBtn = swipeRight;
	let canScroll = true;
	const isRtl = document.querySelector("html").getAttribute("dir") === "rtl";

	/**
	* On RTL pages you still scroll from left to right and vice versa (that's not different from LTR pages. But the opposite button has to be enabled/disabled.
	* And on RTL pages the scrolling happens in negative direction. That's why Math.abs is used for the scrollLeft property.
	*/
	if (isRtl) {
		swipeLeftBtn = swipeRight;
		swipeRightBtn = swipeLeft;
	}

	function scrollLeft() {
		if (!canScroll) return;

		animate(swipeWrapper,
			"scrollLeft",
			"",
			swipeWrapper.scrollLeft,
			(swipeWrapper.scrollLeft - swipeWrapper.offsetWidth),
			1000,
			true
		);
	}

	function scrollRight() {
		if (!canScroll) return;

		animate(swipeWrapper,
			"scrollLeft",
			"",
			swipeWrapper.scrollLeft,
			(swipeWrapper.scrollLeft + swipeWrapper.offsetWidth),
			1000,
			true
		);
	}

	function controlSwipeButtons() {

		if (Math.abs(swipeWrapper.scrollLeft) === 0) {
			swipeLeftBtn.classList.add("is-disabled");

		} else if (Math.abs(swipeWrapper.scrollLeft) >= swipeWrapper.scrollWidth - swipeWrapper.offsetWidth) {
			swipeRightBtn.classList.add("is-disabled");

		} else {
			swipeLeftBtn.classList.remove("is-disabled");
			swipeRightBtn.classList.remove("is-disabled");
		}
	}

	function animate(elem, style, unit, from, to, time, prop) {
		if (!elem) return;


		let start = new Date().getTime();

		let	timer = setInterval(function () {
			let step = Math.min(1, (new Date().getTime() - start) / time);
			if (prop) {
				elem[style] = (from + step * (to - from)) + unit;
			} else {
				elem.style[style] = (from + step * (to - from)) + unit;
			}
			if (step === 1) {
				clearInterval(timer);
			}
			},25
		);

		if (prop) {
			elem[style] = from + unit;
		} else {
			elem.style[style] = from + unit;
		}
	}


	function handleResize() {
		// console.log("swipe handleResize (scrollWidth / offsetWidth): ", swipeWrapper, swipeWrapper.scrollWidth, swipeWrapper.offsetWidth);

		let blockedElement;
		if (swipeWrapper.scrollWidth === 0 && swipeWrapper.offsetWidth === 0 && swipeWrapper.classList.contains('main-navigation--level-2')) {

			// bubble up to the display-none element...
			blockedElement = swipeWrapper.parentElement.parentElement;
			blockedElement.style.opacity = '0';
			blockedElement.style.display = 'block';
		}

		if (swipeWrapper.offsetWidth > 0 && swipeWrapper.scrollWidth <= swipeWrapper.offsetWidth) {
			swipeRightBtn.classList.add("is-disabled");
			swipeLeftBtn.classList.add("is-disabled");
			canScroll = false;
		} else {
			swipeRightBtn.classList.remove("is-disabled");
			canScroll = true;
		}

		if (typeof blockedElement === 'object') {
			blockedElement.style.opacity = '';
			blockedElement.style.display = '';
		}
	}

	handleResize();


	swipeLeft.addEventListener('click', scrollLeft);
	swipeRight.addEventListener('click', scrollRight);
	swipeWrapper.addEventListener('scroll', controlSwipeButtons);
	window.addEventListener('resize', handleResize);
}
