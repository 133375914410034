export default function () {

  const newsSlider = document.querySelector(".news-slider");
  if (newsSlider === null) return;

  const mediaQuery = window.matchMedia("(max-width: 767px)");
  const isMobile = mediaQuery.matches;
  const tabletQuery = window.matchMedia("(min-width: 768px) and (max-width: 1024px)");
  const isTablet = tabletQuery.matches;

  if (newsSlider !== null) {
    newsSlider.addEventListener('resize', () => {
      location.reload();
      console.log("jgdebug: onresize reload");
    }, 300);
  }
  let touchstartX,
    touchstartY,
    touchendX,
    touchendY = 0;
  const carousel = newsSlider.querySelector("[data-target='carousel']");
  const buttonWrapper = newsSlider.querySelector(".button-wrapper");
  const card = carousel.querySelector("[data-target='card']");
  const leftButton = newsSlider.querySelector("[data-action='slideLeft']");
  const rightButton = newsSlider.querySelector("[data-action='slideRight']");
  const dots = newsSlider.querySelector(".dots");
  const sliderDots = newsSlider.getElementsByClassName("slider__dot");
  let carouselIndex = 0;
  const viewItems = isMobile ? 1 : 3;
  const carouselEndIndex = 3;
  const firstCard = newsSlider.getElementsByClassName("card")[0];
  const secondCard = newsSlider.getElementsByClassName("card")[1];
  let first_pos,second_pos = 0;
  if (firstCard !== null && secondCard !== null) {
    first_pos = firstCard.getBoundingClientRect().left + firstCard.offsetWidth;
    second_pos = secondCard.getBoundingClientRect().left;
  }

  // Prepare to limit the direction in which the carousel can slide,
  // and to control how much the carousel advances by each time.
  // In order to slide the carousel so that only three cards are perfectly visible each time,
  // you need to know the carousel width, and the margin placed on a given card in the carousel
  const carouselWidth = carousel.offsetWidth;
  const cardStyle = card.currentStyle || window.getComputedStyle(card);
  const cardMarginRight = isTablet ? second_pos - first_pos : isMobile ? second_pos - first_pos : 0;
 console.log("jgdebug cardMarginRight", cardMarginRight);

  // Count the number of total cards you have
  const cardCount = carousel.querySelectorAll("[data-target='card']").length;

  // Define an offset property to dynamically update by clicking the button controls
  // as well as a maxX property so the carousel knows when to stop at the upper limit
  let offset = 0;
  const maxX = -(
    (cardCount / viewItems) * carouselWidth +
    cardMarginRight * (cardCount / viewItems) -
    carouselWidth -
    cardMarginRight
  );

  // Add the click events
  leftButton.addEventListener("click", goLeft);

  rightButton.addEventListener("click", goRight);

  if (newsSlider.hasAttribute("data-feed-url")) {
    const newsJson = newsSlider.getAttribute("data-feed-url");
    fetch(newsJson)
      .then((response) => response.json())
      .then((data) => {
        console.log("jgdebug: data:", data);
      });
  }

  carousel.addEventListener(
    "touchstart",
    function (event) {
      touchstartX = event.changedTouches[0].screenX;
      touchstartY = event.changedTouches[0].screenY;
    },
    { passive: true }
  );

  carousel.addEventListener(
    "touchend",
    function (event) {
      touchendX = event.changedTouches[0].screenX;
      touchendY = event.changedTouches[0].screenY;
      handleGesture();
    },
    false
  );

  Array.prototype.forEach.call(
    newsSlider.querySelectorAll(".slider__dot"),
    (sliderDot) => {
      sliderDot.addEventListener(
        "click",
        function (event) {
          const dotItemIndex = Array.from(
            sliderDot.parentNode.children
          ).indexOf(sliderDot);
          const currentCarouselIndex = carouselIndex;
          if (dotItemIndex > currentCarouselIndex) {
            for (let i = currentCarouselIndex + 1; i <= dotItemIndex; i++) {
              goRight();
            }
          } else if (dotItemIndex < currentCarouselIndex) {
            for (let i = currentCarouselIndex - 1; i >= dotItemIndex; i--) {
              goLeft();
            }
          }
        },
        false
      );
    }
  );

  function handleGesture() {
    if (touchendX < touchstartX) {
      console.log("Swiped Left");
      goRight();
    }

    if (touchendX > touchstartX) {
      console.log("Swiped Right");
      goLeft();
    }

    if (touchendY === touchstartY) {
      console.log("Tap");
    }
  }

  function goLeft() {
    if (offset !== 0) {
      offset += carouselWidth + cardMarginRight;
      carousel.style.transform = `translateX(${offset}px)`;
      if (carouselIndex > 0) {
        carouselIndex--;
      }
      dots
        .querySelector(".slider__dot.is-active")
        .classList.remove("is-active");
      sliderDots[carouselIndex].classList.add("is-active");
    }
  }

  function goRight() {
    if (offset !== maxX && carouselIndex < carouselEndIndex) {
      offset -= carouselWidth + cardMarginRight;
      carousel.style.transform = `translateX(${offset}px)`;
      carouselIndex++;
      dots
        .querySelector(".slider__dot.is-active")
        .classList.remove("is-active");
      sliderDots[carouselIndex].classList.add("is-active");
    }
  }
}
