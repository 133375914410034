import stopAllComponents from '../../../assets/scripts/helpers/stop-components';


export default function () {

  const userMenuTriggers = document.getElementsByClassName('js-user-menu-trigger');
  const userLogoutTriggers = document.getElementsByClassName('js-logout');


  if (userMenuTriggers.length === 0) return;
  
  function toggleLayer() {
    if (this.classList.contains("is-active")) {
      closeLayer();
    } else {
      stopAllComponents();
      this.classList.add("is-active");
    }
  }
  
  function closeLayer() {
    Array.prototype.forEach.call(userMenuTriggers, (t) => {
      t.classList.remove("is-active");
    });
  }
  
  /**
   * If the user clicks the logout link inside the menu, do logout.
   */
  function logout() {
    // invalidate cookie
    document.cookie = "omv=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.href = "/Logout";
  }
  
  Array.prototype.forEach.call(userMenuTriggers, (t) => {
    t.addEventListener('mouseup', toggleLayer);
  });


  if (userLogoutTriggers) {
    Array.prototype.forEach.call(userLogoutTriggers, (t) => {
      t.addEventListener('mouseup', logout);
    });
  }
  
  window.addEventListener('stopAllComponents', closeLayer);
  
  // Close the settings dropdown if escape key is pressed
  window.addEventListener('keyup', e => {
    if (e.keyCode === 27) {
      closeLayer();
    }
  });
};
