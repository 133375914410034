export default function () {
  let adaptText = false;
  let bigTextClass = "bigtext";
  Array.prototype.forEach.call(document.querySelectorAll('.link-module-flex-item'), (linkItem) => {
    linkItem.addEventListener('click', () => {
      if(linkItem.hasAttribute("data-target-url")) {
        window.location.href = linkItem.getAttribute("data-target-url");
      }
    });

    const spanElement = linkItem.querySelector("span");
    if (spanElement !== null){
      const text = spanElement.textContent;
      if (text.length > 20) {
        adaptText = true;
      }
      if (text.length > 22) {
        adaptText = true;
        bigTextClass = "hughtext";
      }
    }
  });
  if (adaptText) {
    Array.prototype.forEach.call(document.querySelectorAll('.link-module-flex-item span'), (linkItem) => {
      linkItem.classList.add(bigTextClass);
    });
  }
}
