import GLightbox from './glightbox';



export default function () {

  const images = document.getElementsByClassName("glightbox");

  if(images.length > 0) {
    GLightbox();
  }

};