export default function () {
  const externIFrames = document.querySelectorAll(".js-responsiveIframe");
	function loadIframes(iframes) {
		// console.log(`** loadIframes **`);

		Array.prototype.forEach.call(iframes, (iframe) => {
      let src = iframe.getAttribute("src");
      let dataSrc = iframe.getAttribute("data-src");

      if (src === '') {
        // console.log(`jgdebug set src to: ${dataSrc}`);
        if (dataSrc !== null && dataSrc.indexOf("apps-omv.com") !== -1) {
          //OMVW-7393
          dataSrc = readSourceCookies(dataSrc);
          // console.log(`jgdebug set src read source cookies to: ${dataSrc}`);
        }
        iframe.setAttribute('src', dataSrc);
      }
    });

    let monitor = setInterval(intervals, 100);

    function readSourceCookies(dataStrc) {
      //reead source cookies and append to data-src
      // console.log(`jgdebug readSourceCookies: ${dataStrc}`);
      let dataSrcUrl = new URL(dataStrc);
      let cookies = document.cookie;
      let cookieArray = cookies.split(";");
      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        let cookieName = cookie.split("=")[0];
        const cookieValue = cookie.split("=")[1];
        // console.log(`jgdebug cookieName: ${cookieName} with value: ${cookieValue}`);
        const encodedCookieValue = encodeURIComponent(cookieValue);
        if (cookieName.trim() === "source_session") {
          // console.log(`jgdebug found source_session cookie: ${cookieValue}`);
          dataSrcUrl.searchParams.append(
            "source_session",
            encodedCookieValue
          );
        } else if (cookieName.trim() === "source_user") {
          //console.log(`jgdebug found source_user cookie: ${cookieValue}`);
          dataSrcUrl.searchParams.append("source_user", encodedCookieValue);
        }
      }
      return dataSrcUrl.toString();
    }


    function intervals() {
      let elem = document.activeElement;
      let isTracked = false;
      if(elem && elem.tagName === 'IFRAME'){
        const hasDataSrc =  elem.hasAttribute("data-src");
        if (hasDataSrc && !isTracked){
          const dataSrc = elem.getAttribute("data-src");
          if (dataSrc !== undefined && dataSrc.indexOf('mediadatabase') !== -1) {
            isTracked = true;
            console.log('clicked '+dataSrc, isTracked);
            if (dataLayer) {
              dataLayer.push({'event': 'video_play'});
            }
          }
        }
        clearInterval(monitor);
        monitor = setInterval(exitIframe.bind(null, elem), 100);
      }
    }

    function exitIframe(iframe){
      let elem = document.activeElement;
      if((elem && elem.tagName !== 'IFRAME') || (elem && elem !== iframe)){
        console.log('exited');
        clearInterval(monitor);
        monitor = setInterval(intervals, 100);
      }
    }
	}

	/**
	 *  gather iframes and call handler
	 */
	const formFrames	= document.querySelectorAll("iframe.form");
	const youtubeFrames	= document.querySelectorAll("iframe.youtube");
	const embedFrames	= document.querySelectorAll("iframe.embed");

	if(formFrames && formFrames.length > 0) {
	  loadIframes(formFrames);
  }
	if(youtubeFrames && youtubeFrames.length > 0){
	  loadIframes(youtubeFrames);
  }
	if(embedFrames && embedFrames.length > 0) {
    loadIframes(embedFrames);
  }

  /**
   *  gather like iframe and track button clicks
   */
  let likesIframe = document.querySelector('iframe#likes');
  if(likesIframe !== null) {
    let loadCounter = 0;
    // console.log("found likes iframe");
    $('#likes').on('load', function() {
      loadCounter++;
      if (loadCounter > 1) {
        // console.log('frame has reloaded ');
        likesIframe = document.querySelector('iframe#likes');
        let iframeForm = likesIframe.contentWindow.document.body.getElementsByTagName(
          'form');
        if (iframeForm.length > 0) {
          const formAction = iframeForm[0].getAttribute('action');
          // console.log("formAction", formAction);
          if(formAction && dataLayer ) {
            if(formAction.indexOf("add") >= 0) {
              console.log("clicked unlike");
              dataLayer.push({'event': 'unlike'});
            } else if(formAction.indexOf("remove") >= 0) {
              console.log("clicked like");
              dataLayer.push({'event': 'like'});
            }
          }
        }
      }
    });
  }

	/**
	 * For iFrames located on a different server we use responsiveIFrame.js
	 *
   */
  Array.prototype.forEach.call(externIFrames, (externIFrame) => {
    console.log("jgdebug found external IFrame");
    window.addEventListener('message', function(e) {
      const $iframe = externIFrame;
      const eventName = e.data[0];
      const data = e.data[1];
      switch(eventName) {
        case 'setHeight':
          console.log("jgdebug ", data);
          $iframe.style.height = data+"px";
          break;
      }
    }, false);
  });

  window.addEventListener(
    "message",
    function (e) {
      var eventName = e.data[0];
      var data = e.data[1];
      switch (eventName) {
        case "setHeight":
          try {
            const iFrameElement = document.querySelector("#" + e.source.frameElement.id);
            const iFrameHeight = parseInt(data) + 32;
            if (iFrameElement !== null) {
              iFrameElement.style.height = iFrameHeight + "px";
            }
          } catch (exception) {}
          break;
      }
    },
    false
  );
}
