
export default function () {

  const viewToggles = document.getElementsByClassName('js-view-toggle');

  if( viewToggles.length == 0) return;


  /**
    Switch between list and card view.
    Get view classes from the view button's data values and set the active button.
    Set the new view class for each content wrapper.
   */
  function handleView() {
    if(this.classList.contains("is-active-view")) {
      return;
    }

    const activeButton = document.querySelector('.js-view-toggle.is-active-view');
    const activeViewStyle = activeButton.getAttribute("data-value");
    activeButton.classList.remove("is-active-view");

    this.classList.add("is-active-view");
    const nextViewStyle = this.getAttribute("data-value");

    const cardWrappers = document.querySelectorAll("."+activeViewStyle);
    Array.prototype.forEach.call(cardWrappers, function(cardWrapper) {
      cardWrapper.classList.remove(activeViewStyle);
      cardWrapper.classList.add(nextViewStyle);
    });
  }



  Array.prototype.forEach.call(viewToggles, function(viewToggle) {
    viewToggle.addEventListener('mouseup', handleView);
  });

};
