import debounce from '../../../assets/scripts/helpers/debounce.js';


/**
 * This is not cross-origin-save! - It works only if the iframe is located on the same server.
 *  Therefore, try/catch should be used to avoid hard js errors.
 */

const iframes = document.querySelectorAll(".js-iframe");
const MAX_CHARS = 500;


/**
 * Do stuff after the iframe has loaded.
 */
function handleLoad(frame) {

  try {
    const innerDoc = frame.contentDocument || frame.contentWindow.document;
    resizeIframe(frame, innerDoc);

    if (innerDoc.querySelectorAll(".comments").length > 0) {
      createListener(frame, innerDoc);
    }
  }
  catch(e) {
    console.log("Error handleLoad iframe: ", e)
  }
}


/**
 * Resize the iframe according to it's content height
 */
function resizeIframe(frame){
  try {
    const innerDoc = frame.contentDocument || frame.contentWindow.document;
    frame.style.height = 0;
    frame.style.height = innerDoc.querySelector("body").scrollHeight + 'px';
  }
  catch(e) {
    console.log("Error resizeIframe: ", e)
  }
}


/**
 * Create an event listener for all text areas and buttons.
 */
function createListener(frame, innerDoc) {

  const textAreas = innerDoc.querySelectorAll("textarea");
  if(textAreas.length > 0) {
    Array.prototype.forEach.call(textAreas, function(textArea) {
      textArea.addEventListener('input', function () {
        handleTyping(textArea, frame);
      });
    });
  }

  const editBtns = innerDoc.querySelectorAll(".js-edit-btn");
  Array.prototype.forEach.call(editBtns, function(editBtn) {
    editBtn.addEventListener('mouseup', function () {
      this.parentElement.nextElementSibling.classList.add("isEditMode");
    });
  });

  const cancelBtns = innerDoc.querySelectorAll(".js-cancel-btn");
  Array.prototype.forEach.call(cancelBtns, function(cancelBtn) {
    cancelBtn.addEventListener('mouseup', function () {
      this.parentElement.parentElement.parentElement.classList.remove("isEditMode");
    });
  });

  const deleteBtns = innerDoc.querySelectorAll(".js-delete-btn");
  Array.prototype.forEach.call(deleteBtns, function(deleteBtn) {
    deleteBtn.addEventListener('mouseup', function () {
      this.parentElement.parentElement.querySelector(".form-alert--confirmation").classList.add("is-active");
    });
  });

  const confirmDeleteBtns = innerDoc.querySelectorAll(".js-confirm-delete-btn");
  Array.prototype.forEach.call(confirmDeleteBtns, function(btn) {
    btn.addEventListener('mouseup', function () {
      const parent = this.parentElement.parentElement;
      parent.classList.remove("isEditMode");
      parent.parentElement.classList.add("is-deleted");
      const textElement = parent.querySelector(".comments__text");
      textElement.classList.add("comments__text--deleted");
      textElement.innerHTML = "Comment deleted";
      parent.parentElement.querySelector("")
      // todo: send message to Server
    });
  });

  const cancelDeleteBtns = innerDoc.querySelectorAll(".js-cancel-delete-btn");
  Array.prototype.forEach.call(cancelDeleteBtns, function(btn) {
    btn.addEventListener('mouseup', function () {
      this.parentElement.classList.remove("is-active");
    });
  });
}



/**
 * Handle keyboard input in text areas:
 * Count characters and display the count:
 * - if more than MAX_CHARS hide the submit button and show an alert
 * - if less then MAX_CHARS show the submit button and hide the alert
 */
function handleTyping(textArea, frame) {
  const charContainer = textArea.parentNode.querySelector(".comments__chars");
  const btn = textArea.parentNode.querySelector(".button--submit");
  const alert = textArea.parentNode.querySelector(".form-alert");
  const charCount = MAX_CHARS - textArea.value.length;
  const minHint = charContainer.querySelector(".characters_min");
  const leftHint = charContainer.querySelector(".characters_left");
  const charCountText = textArea.parentNode.querySelector(".comments__char-count");

  charCountText.textContent = charCount;

  /**
  *   Show the hint for the minimum characters if the user has typed less than 3 characters.
  *   OMVW-7290
  */
  if (charCount < MAX_CHARS - 3) {
    btn.disabled = false;
    minHint.classList.add("is-hidden");
    leftHint.classList.remove("is-hidden");
    charCountText.classList.remove("is-hidden");
  } else if (charCount >= MAX_CHARS - 3) {
    btn.disabled = true;
    minHint.classList.remove("is-hidden");
    leftHint.classList.add("is-hidden");
    charCountText.classList.add("is-hidden");
  }

  if(charCount < 0) {
    charContainer.classList.add("char-alert");
    btn.classList.add("is-hidden");
    alert.classList.add("is-active");
  } else {
    charContainer.classList.remove("char-alert");
    btn.classList.remove("is-hidden");
    alert.classList.remove("is-active");
  }
  if (textArea.value.length > 500) {
    textArea.value = textArea.value.substring(0, 500);
  }
  resizeIframe(frame);
}


/**
 * Create listener to resize the iframe on page load and when the page is resized.
 */
if(iframes.length > 0) {

  window.addEventListener('resize', debounce(() => {
    Array.prototype.forEach.call(iframes, function (iframe) {
      resizeIframe(iframe);
    });
  }, 350));

  Array.prototype.forEach.call(iframes, function (iframe) {
    iframe.addEventListener('load', function () {
      handleLoad(iframe);
    });
  });
}

export default resizeIframe;
