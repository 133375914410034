
export default function () {

  /**
   * catch all clicks on .download links and open them in a hidden iFrame
   */

  $('a.download').each((index, item) => {
    
    $(item).click(function(e) {
      e.preventDefault();
      e.stopPropagation();
      
      const src = $(this).prop("href");
      
      if (src === 'undefined') {
        return false;
      }
      
      const styles = {
        display:    "none",
        visibility: "hidden",
        width:      "0px",
        height:     "0px",
        padding:    "0px",
        border:     "none",
        position:   "absolute"
      };
      
      if ($('iframe#downloader').length < 1) {
        $('body').append('<iframe id="downloader" scrolling="no" frameborder="0" allowtransparency="true">');
        $('iframe#downloader').css(styles)
      }
      
      $('iframe#downloader').attr("src", src);
    });
  });
};

