export default function () {
  const backLinks = document.querySelectorAll('.back--link');
  const contactsWrapper = document.querySelector('.contacts--wrapper');
  const contactsDetails = document.querySelector('.contacts--wrapper .contacts--details');
  const formsWrapper = document.querySelector('.contacts--wrapper .forms--wrapper');
  const embedWrapper = document.querySelector('.contacts--wrapper .embeds--wrapper');
  const stepsWrapper = document.querySelector('.steps--wrapper');
  const stepModuleElement = document.querySelector("#step-module");
  const descriptionElement = document.querySelector("#step-description");
  const stepItems = document.querySelectorAll(".step--module-item");
  let select = document.querySelector('.contacts--navigation select');
  let modulStep = 1;
  let modulClickPath;

  let json = null;
  let indexClickPath = null;

  let getSiblings = function (parentItem) {
    // for collecting siblings
    let siblings = [];
    if (parentItem !== undefined && parentItem !== null) {
      const { items } = parentItem;
      items.forEach((sibling) => {
        const { label } = sibling;
        siblings.push(label);
      });
    }
    return siblings;
  };

  if(select) {
    select.addEventListener("change", function() {
      location.hash = select.value;
      location.reload();
    });
  }
  Array.prototype.forEach.call(stepItems, (stepItem) => {
    stepItem.addEventListener('click', function() {
      stepItem.classList.toggle("touch-button");
    });
  });

  function hideBackLinks() {
    Array.prototype.forEach.call(backLinks, (backLink) => {
      backLink.classList.remove("active");
    });
  }

  function showBackLinks(step) {
    Array.prototype.forEach.call(backLinks, (backLink) => {
      backLink.classList.add("active");
      backLink.setAttribute("data-step", step);
    });
    addClickEvent(backLinks);
  }

  function addClickEvent(selector){
    Array.prototype.forEach.call(selector, (selectorItem) => {
      selectorItem.addEventListener('click', function() {
        stepsWrapper.classList.add("active");
        contactsWrapper.classList.remove("active");
        hideBackLinks();
        Array.prototype.forEach.call(document.querySelectorAll('.c-stepper__item'), (step) => {
          step.classList.remove("active");
          step.classList.remove("done");
          const titleElement = step.querySelector("h3");
          titleElement.textContent = "";
          const parentNode = step.parentNode;
          const cloneStep = step.cloneNode(true);
          parentNode.removeChild(step);
          parentNode.appendChild(cloneStep);
        });
        document.querySelector('#step-1').classList.add("active");
        indexClickPath = null;
        if (contactsDetails !== null) {
          contactsDetails.innerHTML = "";
        }
        if (formsWrapper !== null) {
          formsWrapper.innerHTML = "";
        }
        const stepper = document.querySelector("#stepper");
        if (stepsWrapper.querySelector("#stepper") === null) {
          stepsWrapper.querySelector(".stepper-wrapper").appendChild(stepper);
          if(contactsWrapper.querySelector(".stepper-wrapper").querySelector("#stepper") !== null){
            contactsWrapper.querySelector(".stepper-wrapper").removeChild(stepper);
          }
        }
        location.hash = "";
        initialView();
        window.scrollTo(0, 0);
      });
    });
  }

  function initClickEvents() {
    Array.prototype.forEach.call(document.querySelectorAll('.step--module-item'), (item) => {
      const hasChilds = item.getAttribute("data-childs");
      if (hasChilds !== null && hasChilds === "true") {
        item.addEventListener('click', function() {
          const dataStep = parseInt(item.getAttribute("data-step"));
          const dataItem = parseInt(item.getAttribute("data-item"));
          const textContent = item.textContent;
          createCurrentStepItems(dataStep, dataItem, textContent);
        });
      }
    });
    const mobileSelectBox = document.querySelector(".step--module select");
    if (mobileSelectBox !== null) {
      mobileSelectBox.addEventListener("change", function () {
        if (mobileSelectBox.value !== null) {
          const stepModuleItem = document.querySelector(".step--module-item[data-item='" + mobileSelectBox.value + "']");
          if (stepModuleItem !== null) {
            stepModuleItem.click();
          }
        }
      });
    }
  }

  function createCurrentStepItems(dataStep, dataItem, textContent) {
    let next = dataStep + 1;
    const currentStepIndex = dataStep - 1;
    const currentItemIndex = dataItem - 1;
    let clicked = currentItemIndex;
    if(indexClickPath === null){
      indexClickPath = currentItemIndex;
    } else {
      clicked = indexClickPath;
      indexClickPath += "," + currentItemIndex;
    }
    if(next > 1) {
      showBackLinks(next.toString());
    }
    const nextStep = document.querySelector('#step-' + next);
    if(nextStep !== null) {
      Array.prototype.forEach.call(document.querySelectorAll('.c-stepper__item'), (step) => {
        step.classList.remove("active");
      });
      const doneStep = document.querySelector('#step-' + dataStep);
      doneStep.classList.add("done");
      addDoneClickeEvent(doneStep, clicked);
      document.querySelector('#step-' + dataStep + ' .c-stepper__title').textContent = textContent;
      nextStep.classList.add("active");
    }
    if(json !== null) {
      //console.log(`jgdebug currentStepIndex: ${currentStepIndex}, currentItemIndex: ${currentItemIndex} `);
      let currentItem;
      if(currentStepIndex === 0) {
        currentItem = json.items[currentItemIndex];
        descriptionElement.textContent = currentItem.description;
        console.log("jgdebug hi");
        createItemsMarkup(currentItem.items, next.toString());
        modulStep = 1;
        modulClickPath = null;
      } else {
        let parentItem;
        //console.log(`jgdebug indexClickPath: ${indexClickPath} `);
        modulClickPath = indexClickPath;
        const clicks = indexClickPath.split([',']);
        clicks.forEach ((click, index) => {
          parentItem = currentItem;
          if(index === 0){
            currentItem = json.items[click];
          } else {
            currentItem = currentItem.items[click];
          }
        });
        const { label } = currentItem;
        //console.log(`jgdebug currentItem: ${currentItem} `);
        let siblings = getSiblings(parentItem);
        console.log("jgdebug siblings: ", siblings);
        modulStep = next;
        if (siblings.length > 0) {
          //updateSelectBox(siblings, label);
        }
        const { contacts, forms, embeds, items } = currentItem;
        const stepper = document.querySelector("#stepper");
        if(contacts !== null && contacts !== undefined) {
          //console.log("jgdebug contacts: ", contacts);
          createContactMarkup(contacts);
          stepsWrapper.classList.remove("active");
          if(stepsWrapper.querySelector(".stepper-wrapper").querySelector("#stepper") !== null){
            stepsWrapper.querySelector(".stepper-wrapper").removeChild(stepper);
          }
          contactsWrapper.classList.add("active");
          contactsWrapper.querySelector(".stepper-wrapper").appendChild(stepper);
        } else {
          contactsDetails.textContent = "";
        }
        if(forms !== null && forms !== undefined) {
          createFormsMarkup(forms);
          stepsWrapper.classList.remove("active");
          if(stepsWrapper.querySelector(".stepper-wrapper").querySelector("#stepper") !== null){
            stepsWrapper.querySelector(".stepper-wrapper").removeChild(stepper);
          }
          contactsWrapper.classList.add("active");
          contactsWrapper.querySelector(".stepper-wrapper").appendChild(stepper);
        } else {
          formsWrapper.textContent = "";
          formsWrapper.style.display = "none";
        }
        if (embeds !== null && embeds !== undefined) {
          console.log("jgdebug contacts: ", contacts);
          createEmbedsMarkup(embeds);
          stepsWrapper.classList.remove("active");
          if(stepsWrapper.querySelector(".stepper-wrapper").querySelector("#stepper") !== null){
            stepsWrapper.querySelector(".stepper-wrapper").removeChild(stepper);
          }
          contactsWrapper.classList.add("active");
          contactsWrapper.querySelector(".stepper-wrapper").appendChild(stepper);
        } else {
          embedWrapper.textContent = "";
        }
        if(items !== null && items !== undefined) {
          createItemsMarkup(items, next.toString());
        }
      }
    }
  }

  if(stepsWrapper != null){
    if(module !== undefined) {
      initialView();
    }
  }

  function addDoneClickeEvent(doneStep, clickedPath) {
    doneStep.setAttribute("data-click", clickedPath);
    doneStep.addEventListener("click", function () {
      const id = doneStep.id;
      const index = id.indexOf("-");
      const step = parseInt(id.substring(index + 1));
      if (step === 1) {
        const backLink = document.querySelector(
          ".steps--navigation .back--link"
        );
        if (backLink !== null) {
          backLink.click();
        }
      } else {
        const dataClickPath = doneStep.getAttribute("data-click");
        const dataClicks = dataClickPath.split("-");
        //console.log("jgdebug addDoneClickeEvent dataClick", dataClicks);
        let currentItems = json.items;
        dataClicks.forEach((dataClick) => {
          currentItems = currentItems[parseInt(dataClick)].items;
        });
        //console.log("jgdebug addDoneClickeEvent currentItems", currentItems);
        modulClickPath = dataClickPath;
        indexClickPath = dataClickPath;
        createItemsMarkup(currentItems, step);
        const stepper = document.querySelector("#stepper");
        if(contactsWrapper.querySelector(".stepper-wrapper").querySelector("#stepper") !== null){
            contactsWrapper.querySelector(".stepper-wrapper").removeChild(stepper);
            stepsWrapper.querySelector(".stepper-wrapper").appendChild(stepper);
        }
        stepsWrapper.classList.add("active");
        contactsWrapper.classList.remove("active");
        Array.prototype.forEach.call(document.querySelectorAll('.c-stepper__item'), (step) => {
          step.classList.remove("active");
        });
        doneStep.classList.remove("done");
        doneStep.classList.add("active");
        const titleElement = doneStep.querySelector("h3");
        titleElement.textContent = "";
      }
    });
  }

  function updateSelectBox(options, currentOption) {
    select.options.length = 0;
    options.forEach((option) => {
      let opt = document.createElement("option");
      opt.value = option + "-" + modulStep + "-" +modulClickPath;
      if(option === currentOption) {
        opt.selected = true;
      }
      opt.textContent = option;
      select.appendChild(opt);
    });
  }

  function initialView() {
    json = JSON.parse(module);
    modulStep = 1;
    const steps = json.steps;
    steps.forEach ((step, index) => {
      //console.log(`i:${index} | Step:`, step);
      const stepNumber = index + 1;
      /*
      const titleSelector = `#step-${stepNumber} .c-stepper__title`;
      const titleElement = document.querySelector(titleSelector);
      titleElement.textContent = step.title;
      */
    });
    descriptionElement.textContent = json.description;
    let items = json.items;
    if(location.hash !== null && location.hash !== undefined && location.hash.length > 2 && location.hash.indexOf("-") >= 0) {
      const hashParameter = decodeURIComponent(location.hash.substring(1));
      const hashItems = hashParameter.split('-');
      const hash = hashItems[0];
      modulStep = hashItems[1];
      modulClickPath = hashItems[2];
      // console.log("jgdebug hash:", hash);
      let parentItem;
      // console.log(`jgdebug initialView hash indexClickPath: ${modulClickPath} `);
      const clicks = modulClickPath.split([',']);
      clicks.forEach ((click, index) => {
        parentItem = items;
        if(index === 0){
          items = json.items[click];
        } else {
          items = items.items[click];
        }
      });
      // console.log("jgdebug initialView hash parentItem:",parentItem.items);
      let foundedItem = null;
      let parentSiblings = [];
      let found = false;
      let stepCounter = 0;
      const iterate = (obj, step) => {
        stepCounter = step;
        if (!found) {
          let siblings = [];
          obj.forEach((siblingItem) => {
            const { label } = siblingItem;
            siblings.push(label);
          });
          parentSiblings = siblings;
        }
        // console.log("jgdebug found", found);
        obj.forEach ((item) => {
          if(item.label === hash) {
            found = true;
            foundedItem = item;
          } else {
            if(item.items !== undefined && item.items !== null) {
              iterate(item.items, step++);
            }
          }
        });
      };
      iterate(parentItem.items, modulStep);
      stepCounter = modulStep;
      // console.log("jgdebug parentSiblings:", parentSiblings);
      // console.log("jgdebug foundedItem:", foundedItem);
      // console.log("jgdebug stepCounter:", stepCounter);
      const stepper = document.querySelector("#stepper");
      if(foundedItem !== null) {
        if(foundedItem.items !== null && foundedItem.items !== undefined) {
          Array.prototype.forEach.call(document.querySelectorAll('.c-stepper__item'), (step) => {
            step.classList.remove("active");
          });
          const doneStep = document.querySelector('#step-' + stepCounter);
          doneStep.classList.add("done");
          addDoneClickeEvent(doneStep);
          document.querySelector('#step-' + stepCounter + ' .c-stepper__title').textContent = hash;
          const nextStep = document.querySelector('#step-' + (stepCounter + 1));
          if(nextStep !== null) {
            nextStep.classList.add("active");
          }
          stepsWrapper.classList.add("active");
          if (stepsWrapper.querySelector("#stepper") === null) {
            stepsWrapper.querySelector(".stepper-wrapper").appendChild(stepper);
            if(contactsWrapper.querySelector(".stepper-wrapper").querySelector("#stepper") !== null){
              contactsWrapper.querySelector(".stepper-wrapper").removeChild(stepper);
            }
          }
          contactsWrapper.classList.remove("active");
          createItemsMarkup(foundedItem.items, stepCounter);
        } else {
          stepsWrapper.classList.remove("active");
          contactsWrapper.classList.add("active");
          if (contactsWrapper.querySelector("#stepper") === null) {
            stepsWrapper.querySelector(".stepper-wrapper").removeChild(stepper);
            contactsWrapper.querySelector(".stepper-wrapper").appendChild(stepper);
          }
          /*
          if (parentSiblings.length > 0) {
            updateSelectBox(parentSiblings, hash);
          }
          */
          const { contacts } = foundedItem;
          if(contacts !== null && contacts !== undefined) {
            createContactMarkup(contacts);
          }
          const { forms } = foundedItem;
          if(forms !== null && forms !== undefined) {
            createFormsMarkup(forms);
          }
        }
        showBackLinks(stepCounter.toString());
      }
    } else {
      createItemsMarkup(items, "1");
    }
  }

  function createItemsMarkup(items, dataStep){
    // console.log("jgdebug createItemsMarkup", dataStep);
    // console.log("jgdebug createItemsMarkup items:", items);
    let itemsMarkup = ``;
    let itemsMobileMarkup = `<select name="step--module-items--mobile">`;
    itemsMobileMarkup += `
      <option>Select</option>
    `;
    let columnClass = "two-column";
    if(items.length > 6){
      columnClass = "four-column";
    } else if(items.length > 2) {
      columnClass = "three-column";
    }
    items.forEach ((item, index) => {
      const itemNumber = index + 1;
      const hasChilds =  item.hasOwnProperty("items") || item.hasOwnProperty("contacts") || item.hasOwnProperty("forms") || item.hasOwnProperty("embeds");
      itemsMarkup += `
        <div class="step--module-item ${columnClass}" data-step="${dataStep}" data-item="${itemNumber}" data-click="${indexClickPath}" data-childs="${hasChilds}">
          ${item.label}
        </div>
      `;
      itemsMobileMarkup += `
        <option value="${itemNumber}">${item.label}</option>
      `;
    });
    itemsMobileMarkup += `</select>`;
    itemsMarkup += itemsMobileMarkup;
    stepModuleElement.innerHTML = itemsMarkup;
    initClickEvents();
  }

  function createContactMarkup(contacts){
    let contactsMarkup = ``;
    contacts.forEach ((contact) => {
      const { image, name, shoptitle, organisation, phone, fax, mail, link } = contact;
      let { address } = contact;
      address = address.replace(/(?:\r\n|\r|\n)/g, "<br>");
      const portraitMarkup = image !== null && image !== undefined && image.indexOf("gender__default.jpg") >=0 ? "" : `<div class="portrait"><img src="${image}" alt="" width="auto" height="auto"/></div>`;
      const phoneMarkup = phone !== null && phone !== undefined && phone !== "" ? `<a href="tel:${phone}">Tel: ${phone}</a>` : "";
      const faxMarkup = fax !== null && fax !== undefined && fax !== "" ? `Fax: ${fax}` : "";
      const mailMarkup = mail !== null && mail !== undefined && mail !== "" ? `<a href="mailto:${mail}">${mail}</a>` : "";
      const googleLink = link !== null && link !== undefined && link !== "" ? `<a href="${link}" target="_blank">Google Maps</a>` : "";
      contactsMarkup += `
        <div class="details--wrapper">
          <div class="contact--detail-container">
              <div class="contact-name--container">
                  <div class="contact-name">${name}</div>
                  <div class="shoptitle">${shoptitle}</div>
                  <div class="organisation">${organisation}</div>
              </div>
              <div class="remote">
                  <div class="postal">
                      <div class="address">${address}</div>
                      <div class=" link">
                        ${googleLink}
                      </div>
                  </div>
                  <div class="postal">
                    <div class="phone">
                        ${phoneMarkup}
                    </div>
                    <div class="fax">${faxMarkup}</div>
                    <div class="mail">
                        ${mailMarkup}
                    </div>
                  </div>
              </div>
          </div>
          ${portraitMarkup}
        </div>
      `;
    });
    contactsDetails.innerHTML = contactsMarkup;
  }

  function createFormsMarkup(forms){
    let formsMarkup = ``;
    forms.forEach ((form) => {
      const { externalid, langtag } = form;
      formsMarkup += `
        <div class="form__wrapper">
          <iframe class="module--form proxyform"
                  width="100%"
                  height="100%"
                  data-src="/services/forms/delivery/zones/pub/forms/${externalid}?langtag=${langtag}"
                  src=""
                  onload='(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));'
                  style="border:0; margin:0; padding: 0;"></iframe>
        </div>
      `;
    });
    formsWrapper.innerHTML = formsMarkup;
    const iframes = document.querySelectorAll(".module--form.proxyform");
    Array.prototype.forEach.call(iframes,(iframe) => {
      let src = iframe.getAttribute("src");
      let dataSrc = iframe.getAttribute("data-src");
      if (src === '') {
        iframe.setAttribute('src', dataSrc);
      }
    });
  }
  function createEmbedsMarkup(embeds) {
    let embedsMarkup = ``;
    embeds.forEach((embed) => {
      const { sourceurl, IframeHeightInPixel, title } = embed;
      const headline = title !== null && title !== undefined ? "<h3>"+title+"</h3>" : "";
      embedsMarkup += `
        ${headline}
        <div class="form__wrapper">
          <iframe class="module--form embed"
                  width="100%"
                  height="${IframeHeightInPixel}"
                  data-src="${sourceurl}"
                  src=""
                  style="border:0; margin:0; padding: 0;"></iframe>
        </div>
      `;
    });
    embedWrapper.innerHTML = embedsMarkup;
    const iframes = document.querySelectorAll(".module--form.embed");
    Array.prototype.forEach.call(iframes, (iframe) => {
      let src = iframe.getAttribute("src");
      let dataSrc = iframe.getAttribute("data-src");
      if (src === "") {
        iframe.setAttribute("src", dataSrc);
      }
    });
  }
}
