export default function () {

  const investorPage = document.querySelector(".investors");
  let hash = window.location.hash;

  if (!investorPage) {
    return;
  }

  function activateTab() {
    const element = document.getElementById(hash);
    if (element !== null && element !== undefined) {
      document.getElementById(hash).click();
    }
  }

  if (hash !== null && hash !== undefined && hash !== "") {
    hash = hash.substring(1, hash.length);
    if (hash !== "") {
      activateTab();
    }
  }

  const selectAllBtn = investorPage.getElementsByClassName("js-select-all");
  Array.prototype.forEach.call(selectAllBtn, function (el) {
    const selectText = el.nextElementSibling.getAttribute("data-selectText");
    const deSelectText = el.nextElementSibling.getAttribute("data-deselectText");
    el.addEventListener('change', e => {
      el.nextElementSibling.textContent = el.checked ? deSelectText : selectText;
      toggleSelectAll(el);
    });
  });

  function toggleSelectAll(el) {
    const inputs = investorPage.getElementsByClassName(el.name);
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].checked = el.checked;
    }
  }

  function download(zip, name) {
    if (name == null)
      name = "download";
    // generate the zip
    zip.generateAsync({type: "blob"})
      .then(function (content) {
        // see FileSaver.js
        saveAs(content, name + ".zip");
      });

  }

  const downloadButtons = investorPage.getElementsByClassName("js-download-selected");
  Array.prototype.forEach.call(downloadButtons, function (downloadButton) {
    downloadButton.addEventListener('click', e => {
      // filter checked items
      var inputs = investorPage.getElementsByClassName(downloadButton.id);
      var files = [];
      for (var i = 0; i < inputs.length; i++) {
        var item = inputs[i];
        if (item.checked && item.value != 'on')
          files.push(item);
      }

      // check if there is something to do
      var itemsToProcess = files.length;
      if (itemsToProcess == 0)
        return;

      var zip = new JSZip();
      for (var i = 0; i < files.length; i++) {
        var file = files[i];
        var path = file.value;
        var filename = file.name;
        if (filename == null || filename.length == 0)
          filename = path.substr(path.lastIndexOf("/") + 1);
        console.debug('add ' + filename + ' to zip file');

        // download a file from server by its relative path and add it to the zip file
        JSZipUtils.getBinaryContent(path, filename, function (err, data, name) {
          if (err) {
            console.log(err);
            if (--itemsToProcess == 0)
              download(zip, downloadButton.name);
            throw err; // or handle the error
          }
          zip.file(name, data, {binary: true});

          if (--itemsToProcess == 0)
            download(zip, downloadButton.name);
        });
      }
    });
  });
};
