export default function () {
  const monogramTextInput = document.querySelector('.monogram--text');
  const svgCircle = document.querySelector('#svg circle');
  if(svgCircle !== null){
    const siteTitel = document.querySelector('.meta-panel__site-title a');
    svgCircle.setAttribute("stroke", "#6c0");
    if(siteTitel !== null && siteTitel.innerHTML !== null && siteTitel.innerHTML.indexOf("Petrom") >= 0){
      svgCircle.setAttribute("stroke", "#ffeb00");
    }
  }
  if(monogramTextInput) {
    monogramTextInput.maxLength = "3";
    monogramTextInput.addEventListener("keyup", function() {
      const svgText = document.querySelector('#svg text');
      if(svgText !== null) {
        const inputText = monogramTextInput.value;
        svgText.setAttribute("font-size", "70");
        svgText.setAttribute("y", "130");
        if(inputText.length === 3){
          svgText.setAttribute("font-size", "63");
          svgText.setAttribute("y", "130");
        }
        svgText.innerHTML = inputText.toUpperCase();
      }
    });
  }

  const monogramRadios = document.querySelectorAll('input[name="company"]');
  monogramRadios.forEach(function(radioItem) {
    const radioValue = radioItem.value;
    radioItem.addEventListener("click", function() {
      switch(radioValue) {
        case "petrom":
          svgCircle.setAttribute("stroke", "#ffeb00");
          svgCircle.setAttribute("fill", "url(#gradient)");
          break;
        case "borealis":
          svgCircle.setAttribute("stroke", "#0b5d9c");
          svgCircle.setAttribute("fill", "#032751");
          break;
        default:
          svgCircle.setAttribute("stroke", "#6c0");
          svgCircle.setAttribute("fill", "url(#gradient)");
      }
    });
  });

  const monogramButton = document.querySelector('#save-to-png');
  if(monogramButton !== null) {
    monogramButton.addEventListener("click", function() {
      console.log("click...");
      let svgString = new XMLSerializer().serializeToString(document.querySelector('svg'));
      let canvas = document.getElementById("canvas");
      let ctx = canvas.getContext("2d");
      ctx.font = "Source Sans Pro"
      let DOMURL = self.URL || self.webkitURL || self;
      let img = new Image();
      let svg = new Blob([svgString], {
        type: "image/svg+xml;charset=utf-8"
      });
      let url = DOMURL.createObjectURL(svg);
      img.onload = function() {
        ctx.drawImage(img, 0, 0);
        let imgURL = canvas.toDataURL("image/png");
        DOMURL.revokeObjectURL(imgURL);
        let dlLink = document.createElement('a');
        dlLink.download = "Mymonogram.png";
        dlLink.href = imgURL;
        dlLink.dataset.downloadurl = [
          "image/png",
          dlLink.download,
          dlLink.href].join(':');
        document.body.appendChild(dlLink);
        dlLink.click();
        document.body.removeChild(dlLink);
      }
      img.src = url;
    });
  }
};
