import stopAllComponents from '../../../assets/scripts/helpers/stop-components';

export default function () {
  const footer = document.querySelector("footer.omv-footer");

  if (footer !== null && footer.hasAttribute("data-json")) {
    // console.log("jgdebug footer JSON:", footer.dataset.json);
    fetch(footer.dataset.json)
      .then((response) => response.json())
      .then((data) => {
        const { footeritems, metanavitems, footerbottomitems, sharepanels } = data;
        // console.log("jgdebug JSON footeritems", footeritems.__size);
        if (footeritems !== null && footeritems !== undefined) {
          renderFooterItems(footeritems, sharepanels);
        }
        if (metanavitems !== null && metanavitems !== undefined) {
          renderMetaNavItems(metanavitems, footer.dataset.languages);
        }
        if (footerbottomitems !== null && footerbottomitems !== undefined) {
          renderFooterBottomItems(footerbottomitems);
        }
        initEventListener(true);
      });
  }

  function renderFooterBottomItems(footerBottomItems) {
    const footerBottomList = footer.querySelector(".footer__wrapper--bottom .footer__inner ul");
    if(footerBottomList === null){
      return;
    }
    let markup = "";
    for (const [key,value] of Object.entries(footerBottomItems)) {
      const footerBottomItem = value;
      markup += `
        <li><a href="${footerBottomItem._link_}">${footerBottomItem.label}</a></li>
      `;
    }
    footerBottomList.insertAdjacentHTML("beforeend", markup);
  }

  function renderMetaNavItems(items, languages) {
    const footerMeta = footer.querySelector(".footer__meta");
    if (footerMeta === null) {
      return;
    }
    let markup = "";
    items.forEach((arrayItem) => {
      markup += `
        <a itemprop="url" title="${arrayItem.label}"  itemprop="name" href="${arrayItem._link_}" class="footer__meta-item footer__meta-link" >${arrayItem.label}</a>
      `;
    });
    footerMeta.insertAdjacentHTML("beforeend", markup);
    if (languages === 'show') {
      footerMeta.insertAdjacentHTML("beforeend", `<div class="footer__meta-item meta-nav__language"></div>`);
    }
  }

  function renderFooterItems(items,sharepanels) {
    const accordion = footer.querySelector(".js-accordion");
    if(accordion === null){
      return;
    }
    const itemsSize = Object.keys(items).length;
    accordion.classList.add("accordion--items-" + itemsSize);
    let markup = "";
    let accordionItemsCount = 0;
    for (const [key, section] of Object.entries(items)) {
      accordionItemsCount++;
      const accordionTrigger = section.link !== null && section.link !== undefined && section.link.length>0 ? `<a  itemprop="url"  itemprop="name" title="${section.label}" href='${section.link}'>${section.label}</a>` : `<strong>${section.label}</strong>`;
      let accordionContentListItems = "";
      section.childs.forEach((arrayItem) => {
        const target = arrayItem.target !== null && arrayItem.target !== undefined ? arrayItem.target : "";
        accordionContentListItems += `
          <li><a itemprop="url"  itemprop="name" title="${arrayItem.label}" href="${arrayItem._link_}" ${target}>${arrayItem.label}</a></li>
        `;
      });
      if (accordionItemsCount === itemsSize && sharepanels !== null && sharepanels !== undefined) {
        accordionContentListItems += getSharePanel(sharepanels);
      }
      markup += `
        <div class="accordion__item">
          <div class="accordion__trigger js-accordion-trigger">${accordionTrigger}</div>
          <div class="accordion__content">
            <ul>${accordionContentListItems}</ul>
          </div>
        </div>
      `;
    }
    accordion.innerHTML = markup;
  }

  function getSharePanel(sharepanels) {
    let sharePanelMarkup = `
      <li>
        <span class="share-panel share-panel--white">
	        <small class="share-panel__title"></small>
    `;

    for (const [key, sharePanelValues] of Object.entries(sharepanels)) {
      const cssClass = key.toLowerCase();
      sharePanelMarkup += `<a href="${sharePanelValues.href}" target="_blank" rel="noopener" class="share-panel__item share-panel__${cssClass}" aria-label="${key}" title="${key}" alt="${cssClass} link"></a>`;
    }
    sharePanelMarkup += `</span></li>`;
    return sharePanelMarkup;
  }


  function initEventListener(triggerAccordion) {
    const languageSwitcher = document.getElementsByClassName(
      "language-switcher"
    );
    const userMenuSwitcher = document.getElementsByClassName(
      "js-user-menu-trigger"
    );
    const metaNavUserMenu = document.getElementsByClassName(
      "meta-nav__usermenu"
    );
    const metaNavLanguageSwitcher = document.getElementsByClassName(
      "meta-nav__language"
    );

    if (languageSwitcher.length > 0 && metaNavLanguageSwitcher.length > 0) {
      const clnLanguageSwitcher = languageSwitcher[0].cloneNode(true);
      metaNavLanguageSwitcher[0].appendChild(clnLanguageSwitcher);
    }

    if (triggerAccordion === true) {
      if (userMenuSwitcher.length > 0 && metaNavUserMenu.length > 0) {
        const clnUserMenuSwitcher = userMenuSwitcher[0].cloneNode(true);
        clnUserMenuSwitcher.classList.remove("js-user-menu-trigger");
        clnUserMenuSwitcher.classList.add("js-usermenu-trigger");
        metaNavUserMenu[0].appendChild(clnUserMenuSwitcher);
        const footerUserMenuSwitcher = footer.querySelector(".js-usermenu-trigger");
        footerUserMenuSwitcher.addEventListener("mouseup", toggleLayer);
      }
      const triggers = footer.getElementsByClassName("js-accordion-trigger");
      Array.prototype.forEach.call(triggers, function (trigger) {
        trigger.addEventListener("mouseup", triggerAccord);
      });
    }
  }

  function triggerAccord() {
    const closedItem = this.parentNode.parentNode.querySelector(".quickClose");
    if (closedItem) {
      closedItem.classList.remove("quickClose");
    }

    const clickedItem = this.parentNode;
    if (clickedItem.classList.contains("accordion-is-open")) {
      clickedItem.classList.remove("accordion-is-open");
      return;
    }

    const openItem = this.parentNode.parentNode.querySelector(
      ".accordion-is-open"
    );
    if (openItem) {
      openItem.classList.add("quickClose");
      openItem.classList.remove("accordion-is-open");
    }

    this.parentNode.classList.add("accordion-is-open");
  }

	function toggleLayer() {
    let footerUserMenuSwitcher = footer.querySelector(".user-menu");
		if (footerUserMenuSwitcher.classList.contains("is-active")) {
      footerUserMenuSwitcher.classList.remove("is-active");
    } else {
      stopAllComponents();
      footerUserMenuSwitcher.classList.add("is-active");
    }
	}

	function closeLayer() {
    const userMenuTriggers = document.getElementsByClassName(
      "js-user-menu-trigger"
    );
		Array.prototype.forEach.call(userMenuTriggers, (t) => {
			//t.classList.remove("is-active");
		});
	}
}
