import resizeIframe from '../../02-atoms/_inline-frame/iframe-controller';


export default function () {

  const routextFrame = document.querySelector(".js-routex");

  if(routextFrame){

    /**
     * Call the resize function inside iframe-controller.
     */
    function dispatchResizing() {
      setTimeout(function () {
        resizeIframe(routextFrame);
      }, 600);
    }


    /**
     * Event listener for the reply-buttons inside the iframe.
     * When the textareas are displayed more space and thus a new height of the iframe is necessary.
     */
    function createEventListener() {

      const innerDoc = routextFrame.contentDocument || routextFrame.contentWindow.document;
      const accordionTriggers = innerDoc.getElementsByClassName("js-accordion-trigger");
      const selectBox = innerDoc.querySelector(".routex select");

      Array.prototype.forEach.call(accordionTriggers, function(trigger) {
        trigger.addEventListener('mouseup', dispatchResizing);
      });

      selectBox.addEventListener('change', dispatchResizing);
    }

    routextFrame.addEventListener('load', createEventListener);
  }







  if(document.getElementsByClassName("routex").length > 0) {

    const selectBox = document.querySelector(".routex select");

    function switchContent() {
      const value = selectBox[selectBox.selectedIndex].value;
      const currentItem = document.querySelector(".routex__data.active");
      if (currentItem) {
        currentItem.classList.remove("active")
      }
      document.getElementById(value).classList.add("active");
    }


    selectBox.addEventListener('change', switchContent);

    // switch to selected content on load - in case a change occurred and the page was reloaded
    switchContent();
  }
};
