// import triggerResize from '../../../assets/scripts/helpers/resize-trigger.js';
import swipe from "../swipe/swipe.js";
export default function () {
  // unified navigation for all sites

  if (document.querySelector("nav.omv-main") == null) return;

  const navigation = document.querySelector("#main-navigation");
  const isV2Version = navigation.classList.contains("main-navigation--v2");
  const desktopQuery = window.matchMedia("(min-width: 1025px)");
  const isDesktop = desktopQuery.matches;
  let nav = document.querySelector("nav.omv-main");
  let body = document.querySelector("body");
  let header = document.querySelector("header");
  if (isDesktop) {
    header.classList.add("is-sticky");
    body.classList.add("stickyMode");
    nav.classList.add("is-sticky");
  }
  // OMVW-6787 change focus on arrow keys
  let level3Counter = 0;
  function initNavigation() {
    let level1Id = 0;
    for (let i = 0; i < navigation.children.length; i++) {
      const item = navigation.children[i];
      if (item.hasAttribute("tabindex")) {
        level1Id++;
        item.setAttribute("id", level1Id);
        prepareSubListItems(item, level1Id, false);
        if (isV2Version) {
          // console.log("jgdebug isV2Version:");
          const hoverlay = item.querySelector(".main-navigation__hoverlay");
          item.addEventListener("click", (e) => {
            for (let i = 0; i < navigation.children.length; i++) {
              const navigationItem = navigation.children[i];
              if(item !== navigationItem){
                navigationItem.classList.remove("active", "active--border");
              }
            }
            // console.debug("jgdebug item clicked 5", e.target);
            if (e.target.nodeName === 'A' || e.target.classList.contains("close")) {
              item.classList.toggle("active");
              item.classList.toggle("active--border");
            } else if (document.activeElement === item) {
              item.firstElementChild.click();
            }
          });
          item.firstElementChild.addEventListener("click", (event) => {
            // console.log("jgdebug anker click: ",event.target);
            if(hoverlay === null && item.firstElementChild.hasAttribute("href")) {
              window.location = item.firstElementChild.href;
              return true;
            }
            if (hoverlay !== null) {
              if (hoverlay.classList.contains("hide")) {
                hoverlay.classList.remove("hide");
              }
            }
            if (item.classList.contains("no--border")) {
              item.classList.remove("no--border");
            }

            const expander = item.firstElementChild.nextElementSibling;
            if (expander !== null) {
              const style = window.getComputedStyle(expander);
              if (style.display !== "none") {
                // console.log("jgdebug expander click: ", expander);
                if (expander.classList.contains("expander--is-open")) {
                  expander.classList.remove("expander--is-open");
                } else {
                  expander.classList.add("expander--is-open");
                }
              }
            }
          });
          prepareSwipeContainer(item);
          const hoverLayClose = item.querySelector(".swipe-container .close");
          if (hoverLayClose !== null) {
            hoverLayClose.addEventListener("click", () => {
              const closestHoverLay = hoverLayClose.closest(
                ".main-navigation__hoverlay"
              );
              if (closestHoverLay !== null) {
                // console.log("jgdebug",closestHoverLay);
                closestHoverLay.classList.add("hide");
              }
              const closestList = hoverLayClose.closest("li");
              if (closestList !== null) {
                // console.log("jgdebug",closestHoverLay);
                closestList.classList.add("no--border");
              }
            });
          }
        }
      }
    }

    // close all expanded levels when going back to top level
    Array.prototype.forEach.call(
      navigation.querySelectorAll(".previous-level"),
      (linkItem) => {
        linkItem.addEventListener("click", (e) => {
          Array.prototype.forEach.call(navigation.querySelectorAll(".expander.expander--is-open"),(match) => {
              match.classList.remove("expander--is-open");
          });
          e.preventDefault();
        });
      }
    );

    // flag is-active -
    const breadcrumbElement = document.querySelector("[data-breadcrumbids]");
    if (breadcrumbElement !== undefined && breadcrumbElement !== null) {
      const breadcrumbItemsStr = breadcrumbElement.getAttribute(
        "data-breadcrumbids"
      );
      const breadCrumpItemArray = breadcrumbItemsStr.split(",");
      const checkMediaQuery = window.matchMedia("(min-width: 1025px)");
      breadCrumpItemArray.forEach((breadcrumpid, index) => {
        const selector = "li[data-assetid='" + breadcrumpid + "']";
        const activeNavItem = document.querySelector(selector);
        if (activeNavItem !== undefined && activeNavItem !== null) {
          if (isV2Version && index === 0 && checkMediaQuery.matches) {
            activeNavItem.classList.add("active_v2");
          } else if (isV2Version && checkMediaQuery.matches) {
            activeNavItem.classList.add("activeSubPage");
          } else {
            activeNavItem.classList.add("active");
          }
        }
      });
    }

    const userGroups = getGroupsCookieValue("omvgroups");

    function getGroupsCookieValue(cookieName) {
      const b = document.cookie.match(
        "(^|;)\\s*" + cookieName + "\\s*=\\s*([^;]+)"
      );
      return b ? b.pop() : "";
    }
    const permittedNavItems = document.querySelectorAll(
      ".nav__item-permitted--permitted"
    );
    Array.prototype.forEach.call(
      permittedNavItems,
      function (permittedNavItem) {
        const permissions = permittedNavItem.getAttribute(
          "data-item-permission"
        );
        // console.log("jgdebug userGroups: ", userGroups);
        if (permissions && userGroups) {
          const permissionSubstring = permissions.substring(
            0,
            permissions.length - 1
          );
          const permissionArray = permissionSubstring.split(",");
          let permissionFound = false;
          Array.prototype.forEach.call(permissionArray, function (permission) {
            // console.log("jgdebug nav permission: ", permission);
            if (permission.length > 2 && userGroups.indexOf(permission) >= 0) {
              permittedNavItem.classList.remove(
                "nav__item-permitted--permitted"
              );
              permissionFound = true;
            }
          });
          if (!permissionFound) {
            permittedNavItem.remove();
          }
        }
      }
    );

    // expander: open/close menu level
    const expanderSelector = isV2Version ? ".main-navigation--level-2>li .expander" : ".expander";
      Array.prototype.forEach.call(
        navigation.querySelectorAll(expanderSelector),
        (expander) => {
          expander.addEventListener("click", (e) => {
            // console.log("jgdebug expander click", e.target);
            if (expander.classList.contains("expander--is-open")) {
              expander.classList.remove("expander--is-open");
            } else {
              expander.classList.add("expander--is-open");
            }
            e.preventDefault();
          });
        }
      );


    //show/ hide user menu
    const userMenuElement = document.querySelector(".js-usermenu-trigger");
    if (userMenuElement !== undefined && userMenuElement !== null) {
      const userCn = getGroupsCookieValue("omv");
      if (userCn && userCn !== "") {
        userMenuElement.classList.remove("js-usermenu-trigger");
      } else {
        userMenuElement.remove();
      }
    }

    let nav = document.querySelector("nav.omv-main");
    const swipes = nav.querySelectorAll(".js-swipe");
    if (swipes.length > 0) {
      Array.prototype.forEach.call(swipes, function (swipeModule) {
        new swipe(swipeModule);
      });
    }
  }

  if (navigation.hasAttribute("data-json")) {
    window.globalNavigation.then(function (navigationsObject) {
      // console.log("jgdebug ", navigationsObject);
      buildNavigation(navigationsObject);
      initNavigation();
    });
  } else {
    initNavigation();
  }

  function buildNavigation(navigationJSON) {
    navigationJSON.forEach((children) => {
      children.children.forEach((child) => {
        // console.log(child);
        const level_1_Item = navigation.querySelector(
          'li[data-assetid="' + child.id + '"]'
        );
        if (
          level_1_Item !== null &&
          child.children !== undefined &&
          child.children.length > 0
        ) {
          const level_1_expander = document.createElement("div");
          level_1_expander.classList.add("expander", "hide-on--desktop");
          level_1_expander.setAttribute("title", "Expand");
          level_1_Item.append(level_1_expander);
          const level_1_hoverlay = document.createElement("div");
          level_1_hoverlay.classList.add("main-navigation__hoverlay", "hide");
          const level_2_swipeContainer = document.createElement("div");
          level_2_swipeContainer.classList.add("swipe-container", "js-swipe");
          const level_2_swipeButtonLeft = document.createElement("div");
          level_2_swipeButtonLeft.classList.add(
            "swipe__button",
            "swipe__button--left",
            "js-swipe-left",
            "is-disabled",
            "hide-on--max-tablet"
          );
          level_2_swipeContainer.append(level_2_swipeButtonLeft);
          const level_2_List = document.createElement("ul");
          level_2_List.classList.add(
            "main-navigation--level-2",
            "js-swipe-wrapper"
          );
          const level_2_PreviousLevel = document.createElement("li");
          level_2_PreviousLevel.classList.add(
            "previous-level",
            "hide-on--desktop"
          );
          const level_2_PreviousLevelAnker = document.createElement("a");
          level_2_PreviousLevelAnker.setAttribute("href", "#");
          level_2_PreviousLevelAnker.setAttribute("titel", child.label);
          level_2_PreviousLevelAnker.innerHTML = "Back";
          level_2_PreviousLevel.append(level_2_PreviousLevelAnker);
          level_2_List.append(level_2_PreviousLevel);
          const level_2_PreviousHome = document.createElement("li");
          level_2_PreviousHome.classList.add(
            "previous-home",
            "hide-on--desktop"
          );
          const level_2_PreviousHomeAnker = document.createElement("a");
          level_2_PreviousHomeAnker.setAttribute("href", child._link_);
          level_2_PreviousHomeAnker.setAttribute("titel", child.label);
          level_2_PreviousHomeAnker.innerHTML = child.label;
          level_2_PreviousHome.append(level_2_PreviousHomeAnker);
          level_2_List.append(level_2_PreviousHome);
          child.children.forEach((level_2_Child) => {
            // console.log(level_2_Child);
            const level_2_Item = createNavigationItems(level_2_Child);
            const level_2_hoverlay = document.createElement("div");
            level_2_hoverlay.classList.add("main-navigation__hoverlay");
            if (
              level_2_Child.children !== undefined &&
              level_2_Child.children.length > 0
            ) {
              const level_2_expander = document.createElement("div");
              level_2_expander.classList.add("expander", "hide-on--desktop");
              level_2_expander.setAttribute("title", "Expand");
              level_2_Item.append(level_2_expander);
              let level_3_MainNavigationList = document.createElement("ul");
              level_3_MainNavigationList.classList.add(
                "main-navigation--level-3"
              );
              let level_3_MainNavigationColumn = document.createElement("li");
              level_3_MainNavigationColumn.classList.add(
                "main-navigation__column"
              );
              let level_3_List = document.createElement("ul");
              level_2_Child.children.forEach((level_3_Child) => {
                if (level_3_Child.subtype === "Separator") {
                  level_3_MainNavigationColumn.append(level_3_List);
                  level_3_MainNavigationList.append(
                    level_3_MainNavigationColumn
                  );
                  level_3_MainNavigationColumn = document.createElement("li");
                  level_3_MainNavigationColumn.classList.add(
                    "main-navigation__column"
                  );
                  level_3_List = document.createElement("ul");
                } else {
                  const level_3_Item = createNavigationItems(level_3_Child);
                  if (
                    level_3_Child.children !== undefined &&
                    level_3_Child.children.length > 0
                  ) {
                    const level_3_expander = document.createElement("div");
                    level_3_expander.classList.add(
                      "expander",
                      "hide-on--desktop"
                    );
                    level_3_expander.setAttribute("title", "Expand");
                    level_3_Item.append(level_3_expander);
                    const level_4_List = document.createElement("ul");
                    level_4_List.classList.add("main-navigation--level-4");
                    level_3_Child.children.forEach((level_4_Child) => {
                      const level_4_Item = createNavigationItems(level_4_Child);
                      level_4_List.append(level_4_Item);
                    });
                    level_3_Item.append(level_4_List);
                  }
                  level_3_List.append(level_3_Item);
                  //console.log(level_3_List);
                }
              });
              level_3_MainNavigationColumn.append(level_3_List);
              level_3_MainNavigationList.append(level_3_MainNavigationColumn);
              level_2_hoverlay.append(level_3_MainNavigationList);
            }
            level_2_Item.append(level_2_hoverlay);
            level_2_List.append(level_2_Item);
          });
          level_2_swipeContainer.append(level_2_List);
          const level_2_swipeButtonRight = document.createElement("div");
          level_2_swipeButtonRight.classList.add(
            "swipe__button",
            "swipe__button--right",
            "js-swipe-right",
            "is-disabled",
            "hide-on--max-tablet"
          );
          level_2_swipeContainer.append(level_2_swipeButtonRight);
          level_1_hoverlay.append(level_2_swipeContainer);
          level_1_Item.append(level_1_hoverlay);
        }
      });
    });
  }

  function createNavigationItems(child) {
    // console.log(child);
    const permitted = child.permissions === "" ? "nav__item-permitted--not_permitted" : "nav__item-permitted--permitted";
    const level_Item = document.createElement("li");
    level_Item.classList.add(permitted);
    level_Item.setAttribute("data-assetid", child.id);
    level_Item.setAttribute("data-item-permission", child.permissions);
    level_Item.setAttribute("tabindex", "0");
    const level_Anker = document.createElement("a");
    level_Anker.setAttribute("href", child._link_);
    level_Anker.setAttribute("data-link-text", child.label);
    level_Anker.setAttribute("title", child.label);
    level_Anker.setAttribute("tabindex", "-1");
    if (child.target !== undefined && child.target === "Open in new Tab") {
      level_Anker.setAttribute("target", "_blank");
    }
    level_Anker.innerHTML = child.label;
    level_Item.append(level_Anker);
    return level_Item;
  }

  function prepareSubListItems(item, levelId, islevel3) {
    const subList = item.querySelector("ul");
    if (subList != null) {
      let levelCounter = 0;
      for (let i = 0; i < subList.children.length; i++) {
        const item = subList.children[i];
        if (item.classList.contains("main-navigation__column")) {
          if (i === 0) {
            level3Counter = 0;
          }
          prepareSubListItems(item, levelId, true);
        } else if (item.hasAttribute("tabindex")) {
          levelCounter++;
          if (islevel3) {
            level3Counter++;
            item.setAttribute("id", levelId + "_" + level3Counter);
          } else {
            item.setAttribute("id", levelId + "_" + levelCounter);
          }
          prepareSubListItems(item, levelId + "_" + levelCounter, false);
        }
      }
    }
  }

  function prepareSwipeContainer(listItem) {
    const swipeContainer = listItem.querySelector(".swipe-container");
    // console.log("jgdebug prepareSwipeContainer ", listItem);
    if (swipeContainer !== null) {
      const closeNode = document.createElement("div");
      closeNode.classList.add("close");
      closeNode.setAttribute("tabindex", "0");
      Array.prototype.forEach.call(
        swipeContainer.querySelectorAll(".swipe__button"),
        (swipeButton) => {
          swipeButton.remove();
        }
      );
      swipeContainer.appendChild(closeNode);
    }
  }

  navigation.onkeydown = function (event) {
    const currentFocus = event.target.id;
    const currentElement = document.getElementById(currentFocus);
    if (currentElement === null) {
      return;
    }
    const levelFlag = currentFocus.lastIndexOf("_");
    console.log("jgdebug " + event.keyCode, currentFocus);
    if ([9, 13, 37, 38, 39, 40].indexOf(event.keyCode) === -1) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    let newFocus;
    if (event.keyCode === 37 || (event.shiftKey && event.keyCode === 9)) {
      if (countInstances(currentFocus, "_") === 1) {
        Array.prototype.forEach.call(
          navigation.querySelectorAll(".main-navigation--level-2 li.active"),
          (activeItem) => {
            activeItem.classList.remove("active");
          }
        );
      }
      goLeft(currentFocus, levelFlag, currentElement);
    }

    ////////////
    else if (
      event.keyCode === 39 ||
      (event.keyCode === 9 &&
        (levelFlag === -1 || countInstances(currentFocus, "_") > 2))
    ) {
      if (countInstances(currentFocus, "_") === 1) {
        Array.prototype.forEach.call(
          navigation.querySelectorAll(".main-navigation--level-2 li.active"),
          (activeItem) => {
            activeItem.classList.remove("active");
          }
        );
      }
      goRight(currentFocus, levelFlag, currentElement);
    } else if (event.keyCode === 13) {
      currentElement.firstElementChild.click();
    }
    ////////////
    else if (event.keyCode === 38) {
      const countLevels = countInstances(currentFocus, "_");
      if (countLevels > 0) {
        currentElement.blur();
        newFocus = currentFocus.substring(0, levelFlag);
        const newFocusElement = document.getElementById(newFocus);
        // console.log('jgdebug newFocus: ', newFocus);
        if (countInstances(newFocus, "_") === 0) {
          const overlay = newFocusElement.querySelector(
            ".main-navigation__hoverlay"
          );
          if (overlay !== null) {
            if (isV2Version) {
              overlay.classList.add("hide");
            } else {
              overlay.style.display = "none";
            }
          }
          Array.prototype.forEach.call(
            navigation.querySelectorAll("li.active"),
            (activeItem) => {
              activeItem.classList.remove("active");
            }
          );
        }
        newFocusElement.focus();
      }
    } else if (event.keyCode === 40) {
      newFocus = currentFocus + "_1";
      const newFocusElement = document.getElementById(newFocus);
      // console.log('jgdebug newFocus:', newFocusElement);
      if (newFocusElement !== null) {
        currentElement.classList.add("active");
        currentElement.blur();
        const parentOverlay = newFocusElement.closest(
          ".main-navigation__hoverlay"
        );
        if (isV2Version) {
          parentOverlay.classList.remove("hide");
        } else {
          parentOverlay.style.display = "block";
        }
        newFocusElement.focus();
      }
    }
  };

  function goLeft(currentFocus, levelFlag, currentElement) {
    let focus = currentFocus;
    const isFirstLevel = countInstances(currentFocus, "_") === 0;
    const swipeButton = navigation.querySelector(
      ".main-navigation-wrapper>.swipe-container .swipe__button--left"
    );
    if (levelFlag > -1) {
      focus = currentFocus.substring(levelFlag + 1);
    }
    let newFocusId = Number(focus) - 1;
    if (levelFlag > -1) {
      newFocusId = currentFocus.substring(0, levelFlag) + "_" + newFocusId;
    }
    // console.log("jgdebug newFocusId", newFocusId);
    const newFocusElement = document.getElementById("" + newFocusId);
    if (newFocusElement !== null) {
      currentElement.blur();
      if (isFirstLevel) {
        const currentOverlay = currentElement.querySelector(
          ".main-navigation__hoverlay"
        );
        if (currentOverlay !== null) {
          if (isV2Version) {
            currentOverlay.classList.add("hide");
          } else {
            currentOverlay.style.display = "none";
          }
        }
        const overlay = newFocusElement.querySelector(
          ".main-navigation__hoverlay"
        );
        if (overlay !== null) {
          if (isV2Version) {
            overlay.classList.remove("hide");
          } else {
            overlay.style.display = "block";
          }
        }
        if (
          swipeButton !== null &&
          !swipeButton.classList.contains("is-disabled") &&
          countInstances(currentFocus, "_") === 1
        ) {
          swipeButton.click();
        }
      }
      newFocusElement.focus();
    }
  }

  function goRight(currentFocus, levelFlag, currentElement) {
    let focus = currentFocus;
    const isFirstLevel = countInstances(currentFocus, "_") === 0;
    if (levelFlag > -1) {
      focus = currentFocus.substring(levelFlag + 1);
    }
    let newFocusId = Number(focus) + 1;
    if (levelFlag > -1) {
      newFocusId = currentFocus.substring(0, levelFlag) + "_" + newFocusId;
    }
    // console.log("jgdebug newFocusId", newFocusId);
    const newFocusElement = document.getElementById("" + newFocusId);
    if (newFocusElement !== null) {
      currentElement.blur();
      const currentOverlay = currentElement.querySelector(
        ".main-navigation__hoverlay"
      );
      if (isFirstLevel) {
        if (currentOverlay !== null) {
          if (isV2Version) {
            currentOverlay.classList.add("hide");
          } else {
            currentOverlay.style.display = "none";
          }
        }
        const overlay = newFocusElement.querySelector(
          ".main-navigation__hoverlay"
        );
        if (overlay !== null) {
          if (isV2Version) {
            overlay.classList.remove("hide");
          } else {
            overlay.style.display = "block";
          }
        }
        const swipeButton = navigation.querySelector(
          ".main-navigation-wrapper>.swipe-container .swipe__button--right"
        );
        // console.log("jg debug swipe button", swipeButton);
        if (
          swipeButton !== null &&
          !swipeButton.classList.contains("is-disabled") &&
          countInstances(currentFocus, "_") < 2
        ) {
          // console.log("jgdebug click");
          swipeButton.click();
        }
      }
      newFocusElement.focus();
    } else if (isFirstLevel) {
      findNextTabStop(currentElement);
    }
  }

  function countInstances(string, word) {
    return string.split(word).length - 1;
  }

  function findNextTabStop(currentElement) {
    const mainDivElement = document.querySelector("div.main");
    if (mainDivElement) {
      let universe = mainDivElement.querySelectorAll(
        "input, button, select, textarea, a[href]"
      );
      let list = Array.prototype.filter.call(universe, function (item) {
        return item.tabIndex >= "0";
      });
      if (list !== null && list.length > 0) {
        const nextEl = list[0];
        // console.log("jgdebug nextEl", nextEl);
        currentElement.blur();
        const currentOverlay = currentElement.querySelector(
          ".main-navigation__hoverlay"
        );
        if (currentOverlay !== null) {
          if (isV2Version) {
            currentOverlay.classList.add("hide");
          } else {
            currentOverlay.style.display = "none";
          }
        }
        nextEl.focus();
      }
    }
  }
}
