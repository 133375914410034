
// Import components
import downloadHandler from '../../assets/scripts/helpers/download-handler.js';
import responsiveImages from '../../assets/scripts/helpers/responsive-images.js';
import hamburger from '../../components/02-atoms/hamburger/hamburger';
import tabs from '../../components/03-molecules/05-tabs/tabs.js';
import stickyBar from '../../components/03-molecules/20-sticky-bar/sticky-bar.js';
import loadMore from '../../components/03-molecules/load-more/load-more.js';
import accordion from '../../components/04-organisms/00-accordion/accordion.js';
import tabCordion from '../../components/03-molecules/tabcordion/tabcordion.js';
import routex from '../../components/04-organisms/16-routex/routex.js';
import slider from '../../components/04-organisms/slider/slider.js';
import viewSwitch from '../scripts/modules/view-switch.js';
import contentFilterView from '../../components/04-organisms/content-filter-view/content-filter-view.js';
import mainNavigation from '../../components/04-organisms/01-main-navigation/main-navigation';
import commenting from '../../components/04-organisms/embed/09-commenting/commenting.js';
import pagination from '../../components/03-molecules/pagination/pagination.js';
import websiteSwitcher from '../../components/03-molecules/website-switcher/website-switcher.js';
import userMenu from '../../components/03-molecules/user-menu/user-menu.js';
import swipe from '../../components/04-organisms/swipe/swipe.js';
import investorRelations from '../../components/05-pages/investor-relations/investor-relations.js';
import search from '../../components/03-molecules/03-search/search.js';
import sharing from '../../components/03-molecules/21-share-panel/share-panel.js';
import imageGallery from '../../components/04-organisms/08-image-gallery/image-gallery.js';
import lightbox from '../../components/04-organisms/lightbox/lightbox.js';
import myFavorites from '../../components/05-pages/my-favorites/my-favorites.js';
import iframeLoader from '../../components/03-molecules/iframe/iframe-loader';
import footer from '../../components/03-molecules/04-footer/footer.js';
import toast from '../../components/03-molecules/toast/toast.js';
import stepModule from '../../components/05-pages/09-step-module/step-module.js';
import embed from '../../components/03-molecules/22-embed/embed.js';
import video from '../../components/03-molecules/13-video/video.js';
import monogram from '../../components/05-pages/monogram/monogram.js';
import prospectus from '../../components/05-pages/prospectus/prospectus.js';
import linkModule from '../../components/03-molecules/link-module/linl-module';
import newsSlider from "../../components/04-organisms/news-slider/news-slider.js";
import pressSlider from "../../components/04-organisms/press-slider/press-slider.js";
import qrCodeGenerator from "../../components/05-pages/qr-code/qr-code.js";
import balanceInquiry from "../../components/05-pages/balance-inquiry/balance-inquiry.js";
import correspondenceAgreement from "../../components/05-pages/correspondence-agreement/correspondence-agreement.js";
import azuremediaplayer from "../../components/03-molecules/14-azure-media-player/azuremediaplayer.js";
import translations from "../../assets/scripts/helpers/translations.js";
import complaintRequest from "../../components/05-pages/complaint-request/complaint-request.js";



/**
 * IE11
 * fetch: https://github.com/github/fetch
 * // promise: https://github.com/taylorhakes/promise-polyfill
 * promise and array (forEach): https://github.com/zloirock/core-js
 */
import "whatwg-fetch";
import "core-js/es/promise";

/**
 * set a class to body tag to easily identify the screen/type-of-device in styles and js
 * taken from: ./styles/config/_media.css
 *
 * @custom-media --mobile screen and (max-width: 767px);
 * @custom-media --min-tablet screen and (min-width: 768px);
 * @custom-media --tablet screen and (min-width: 768px) and (max-width: 1024px);
 * @custom-media --max-tablet screen and (max-width: 1024px);
 * @custom-media --desktop screen and (min-width: 1025px);
 * @custom-media --large-desktop screen and (min-width: 1440px);
 */
function setScreenStyle() {
	const viewportWidth = window.innerWidth;
	const screenStyle = (viewportWidth <= 767) ? 'is-mobile' : ((viewportWidth > 767 && viewportWidth <= 1024) ? 'is-tablet' : ((viewportWidth >= 1440) ? 'is-large-desktop' : 'is-desktop'));
  const bodyElement = document.querySelector("body");
  if (bodyElement !== null) {
    bodyElement.classList.remove(
      "is-mobile",
      "is-tablet",
      "is-desktop",
      "is-large-desktop"
    );
    bodyElement.classList.add(screenStyle);
  }
	/* fix when mobile nav is open and browser is resized to desktop, nav switches, but the overlay stays */
	if (screenStyle === 'is-desktop') {
		const overlayForNavigation = document.querySelector(".js-overlay.is-active.for-navigation");
		if (overlayForNavigation) {
			overlayForNavigation.classList.remove("is-active", "for-navigation");
		}

	} else {
		if (document.querySelector(".main-navigation.is-active")) {
			const overlay = document.querySelector(".js-overlay");
			if (overlay) {
				overlay.classList.add("is-active", "for-navigation");
			}
		}
	}
}
window.addEventListener('resize',function(){
  //is-desktop, is-large-desktop
  setScreenStyle();
});
setScreenStyle();


/** Run Order:
 *  newsArchive must be initialized before the tabs because the archive has event listeners that must listen before
 *  the tabs are used
 */
downloadHandler();
responsiveImages();
slider();
hamburger();
mainNavigation();
stickyBar();
loadMore();
accordion();
viewSwitch();
contentFilterView();
tabs();
pagination();
websiteSwitcher();
userMenu();
myFavorites();
iframeLoader();
routex();
commenting();
investorRelations();
search();
imageGallery();
lightbox();
sharing();
footer();
toast();
stepModule();
embed();
video();
monogram();
prospectus();
linkModule();
newsSlider();
pressSlider();
qrCodeGenerator();
balanceInquiry();
correspondenceAgreement();
azuremediaplayer();
translations();
complaintRequest();

/**
 * module Swipe
 */
const swipes = document.getElementsByClassName("js-swipe");
if(swipes.length > 0) {
    Array.prototype.forEach.call(swipes, function(swipeModule) {
    new swipe(swipeModule);
  });
}


/**
 * module TabCordion
 */
const tabCordions = document.getElementsByClassName("tabcordion");

if(tabCordions.length > 0) {
  Array.prototype.forEach.call(tabCordions, function (tabCordionModule) {
    new tabCordion(tabCordionModule);
  });
}
