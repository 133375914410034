/**
 * Use jQuery UI for drag&drop
 * https://jqueryui.com/sortable/#connect-lists
 */


export default function () {

  const fav1 = document.querySelector(".js-sortable-drop");
  const fav2 = document.querySelector(".js-sortable-drag");
  const confirmBtns = document.getElementsByClassName("js-confirm");

  if(fav1 && fav2) {

    $( ".js-sortable-drop, .js-sortable-drag" ).sortable({
      connectWith: ".js-connectedSortable",
      placeholder: "sortable-placeholder",
      start: function( event, ui ) {
        $(ui.item).addClass("on-hover");
      },
      stop:function( event, ui ) {
        $(ui.item).removeClass("on-hover");
      }
    }).disableSelection();

  }


  /**
   * On button click signal loading, get the data-url from the button wrapper and initiate the loading.
   */
  function handleClick() {
    this.classList.add("is-loading");
    let dataUrl = this.getAttribute("data-url");

    dataUrl += "&favorites=" + getIds();
    loadData(dataUrl);
  }


  /**
   * Get the ID of each element inside the drop zone (left-hand side of the My Favorites config page).
   *
   * @returns {string}
   */
  function getIds() {
    const cards = document.querySelectorAll(".js-sortable-drop .sortable__card");
    let comma = "";
    let ids = "";
    Array.prototype.forEach.call(cards, function (card) {
      ids += comma + card.getAttribute("data-id");
      comma = ",";
    });
    return ids;
  }


  /**
   * Send data (all selected IDs) to the server.
   * If the server doesn't send an error go to the finish function to show a confirmation.
   *
   * @param dataUrl
   */
  function loadData(dataUrl) {
    console.log("url with selected favorites: ", dataUrl);
    fetch(dataUrl)
      .then(r => r.text())
      .then(data => finish())
      .catch(e => console.error(e));
  }


  /**
   * Open the overview page.
   * // Hide the spinner and exchange the wording inside button to signal confirmation from the server.
   */
  function finish() {
    // Array.prototype.forEach.call(confirmBtns, function (confirmBtn) {
    //   confirmBtn.classList.remove("is-loading");
    //   const btn = confirmBtn.querySelector("button");
    //   btn.textContent = btn.getAttribute("data-confirmed") || "Confirmed";
    // });

    window.location.href = confirmBtns[0].getAttribute("data-redirect");
  }


  /**
   * Reset the buttons when the user changes the selection - by dragging or dropping elements.
   */
  // function resetBtns() {
  //   Array.prototype.forEach.call(confirmBtns, function (confirmBtn) {
  //     const btn = confirmBtn.querySelector("button");
  //     btn.textContent = btn.getAttribute("data-confirm") || "Confirm";
  //   });
  // }


  if(confirmBtns.length > 0) {
    Array.prototype.forEach.call(confirmBtns, function (confirmBtn) {
      confirmBtn.addEventListener('mouseup', handleClick);
    });
  }

}
