export default function () {
  const toast = document.querySelector('.toast');
  // console.log("toastjs");
  if (toast) {
    const toastClose = document.querySelector('#toast-close');
    const toastUpdatedDate = toast.getAttribute('data--updateddate');
    const toastSite = toast.getAttribute('data--site');
    const toastDimension = toast.getAttribute('data--dim');
    let updateddate = toastUpdatedDate !== null ? toastUpdatedDate.replace(/[\W_]+/g,"_") : "1";
    let site = toastSite !== null ? toastSite : "site";
    let dimension = toastDimension !== null ? toastDimension : "dimension";
    let cookieValue = updateddate + '-' + site + '-' + dimension;
    if (toastClose) {
      toastClose.addEventListener('click', function(evt) {
        evt.preventDefault();
        toast.setAttribute("style", "display:none");
        document.cookie = "omv_toast=" + cookieValue +"; Secure";
      });
    }

    const hideToast = checkHideCookieExists(cookieValue);
    if(hideToast) {
      toast.setAttribute("style", "display:none");
    } else {
      toast.setAttribute("style", "display:block");
    }

    const mql = window.matchMedia('(min-width: 1025px)');
    if(mql.matches) {
      const stickyBar = document.querySelector(".sticky-bar");
      if (stickyBar) {
        const toastHeight = (toast.getBoundingClientRect().height) + 200;
        console.log('jgdebug topValue', toastHeight);
        stickyBar.style.top = toastHeight + 'px';
      }
    }
  }

  function checkHideCookieExists(cookieValue) {
    if (document.cookie.split(';').some((item) => item.trim().startsWith('omv_toast=' + cookieValue))) {
      // console.log(' The cookie "omv_toast" exists', 'omv_toast=' + cookieValue);
      return true;
    }
    // console.log(' The cookie "omv_toast" do not exists', 'omv_toast=' + cookieValue);
    return false;
  }
}
