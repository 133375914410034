import Pagination from './pagination-plugin.js';

/**
 https://github.com/rainjay/pagination
 */

export default function () {

  const container = document.querySelector(".pagination");

  if(!container) { return };

  let allowPageSwitch = false;
  let currentPage = 1;
  const resultsPerPageSelect = document.querySelector(".js-search-results-setting");


  function setLabels() {
    const prevLabel = container.getAttribute("labelPrev") || "Previous page";
    const nextLabel = container.getAttribute("labelNext") || "Next page";

    // omv stylesheet(s) should always loaded first (before external stuff like google fonts)!!!
    document.styleSheets[0].insertRule('.pagination>li:first-child:after { content: "' + prevLabel + '" }', 0);
    document.styleSheets[0].insertRule('.pagination>li:last-child:before { content: "' + nextLabel + '" }', 0);
  }


  function getResultsCount() {
    return container.getAttribute("dataResults");
  }


  function getResultsPerPage() {
    let resultsPerPage = container.getAttribute("pageSize");

    if(resultsPerPageSelect) {
      resultsPerPage = resultsPerPageSelect.value;
    }
    return resultsPerPage;
  }


  /**
   * Go to the selected page on click inside the pagination or if the select box is changed.
   * @param p page properties
   */
  function goToPage(p) {
    const url = container.getAttribute("dataUrl");
    const pageSize = resultsPerPageSelect[resultsPerPageSelect.selectedIndex].value;
    if(p.current) {
      currentPage = p.current;
    }
    const href = `${url}&page=${currentPage}&pageSize=${pageSize}`;

    if(!allowPageSwitch) {
      allowPageSwitch = true;
      return;
    }
    window.location.href = href;
  }
  if(resultsPerPageSelect) {
    resultsPerPageSelect.addEventListener('change', goToPage);
  }

  /**
   * Init the pagination
   */
  Pagination.config({
    ulClass: 'pagination',
    lang: 'en',
    hideIfEmpty: true
  });

  const paging = new Pagination(
    getResultsCount(),
    getResultsPerPage(),
    function(p) {
      goToPage(p);
    },
    '.pagination',
    setLabels()
  );

  paging.goToPage(container.getAttribute("dataPage"));

  if(paging.getCurrentPage() == 1) {
    allowPageSwitch = true;
  }

};
