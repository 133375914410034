export default function () {
  const cookieHintButtons = document.querySelectorAll('.embed__wrapper .modal button');
  Array.prototype.forEach.call(cookieHintButtons, (cookieHintButton) => {
    cookieHintButton.addEventListener('click', e => {
      const modal = cookieHintButton.parentNode;
      const modalParent = modal.parentNode;
      modalParent.setAttribute("style", "min-height:unset;");
      const iframe = modalParent.firstChild.nextSibling;
      modal.classList.remove("active");
      iframe.classList.remove("hide");
      iframe.classList.add("active");
    });
  });
}
