import Glide, { Controls, Breakpoints, Swipe } from '@glidejs/glide/dist/glide.modular.esm';

export default function () {
  
  const imageGalleries = document.getElementsByClassName("glide");

  if (imageGalleries.length === 0) { return; }

  Array.prototype.forEach.call(imageGalleries, function (gallery) {
    new Glide(gallery, {
      type: 'carousel',
      startAt: 0,
      focusAt: 'center',
      gap: 0,
      direction: document.querySelector("html").getAttribute("dir"),
      breakpoints: {
        768: { perView: 1, peek: 0 },
        1024: { perView: 1, peek: 80 },
        1440: { perView: 1, peek: 145 },
        5000: { perView: 1, peek: 145 }
      }
    }).mount({ Controls, Breakpoints, Swipe });
  });
};
