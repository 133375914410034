import stopAllComponents from '../../../assets/scripts/helpers/stop-components';

export default function () {

	if (document.getElementsByClassName('js-sticky-bar').length === 0) return;


	const stickyBar = document.querySelector(".js-sticky-bar");
	const mobileTrigger = document.querySelector(".js-sticky-mobile-trigger");
	const overlay = document.querySelector(".js-overlay");
	const closeButtons = document.getElementsByClassName("js-overlay-close");
	const stickyBarItemLink = document.getElementsByClassName('js-sticky-bar-item--link');
	const stickyBarItemPageCentered = document.getElementsByClassName("js-sticky-bar-item--overlay-page-centered");
	const stickyBarItemBarAttached = document.getElementsByClassName("js-sticky-bar-item--overlay-attached-to-stickybar");
	const body = document.querySelector("body");

	//FIXME: should not be in stickybar.js, but it uses overlay handling. best would be to separate stickybar and overlay handling.
	const nonStickyBarOverlayTrigger = document.getElementsByClassName("js-switch-overlay");

	let contentLoadStatus = {};

	const omvLdapGroups = getCookieValue("omvgroups");

	function getCookieValue(a) {
		const b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
		return b ? b.pop() : '';
	}
    const permittedStickyBarItems = document.querySelectorAll(".sticky-bar__item-permitted--permitted");
    Array.prototype.forEach.call(permittedStickyBarItems, function(permittedStickyBarItem) {
      const permissions = permittedStickyBarItem.getAttribute("data-item-permission");
	    // console.log("jgdebug omvLdapGroups: ", omvLdapGroups);
      if (permissions && omvLdapGroups) {
          const permissionSubstring = permissions.substring(0,permissions.length - 1);
          const permissionArray = permissionSubstring.split(",");
					let permissionFound = false;
          Array.prototype.forEach.call(permissionArray, function(permission) {
						// console.log("jgdebug permission: ", permission);
            if (permission.length > 2 && omvLdapGroups.indexOf(permission) >= 0) {
              permittedStickyBarItem.style.display = 'flex';
							permissionFound = true;
            }
          });
					if(!permissionFound) {
						permittedStickyBarItem.remove();
					}
      }
    });
	/*
	  push SEO-related data to GTM/GA
	 */
	function pushSeoData(item, subLabel) {
		const el = this instanceof Element ? this : item;
		const label = el.getAttribute("data-item-label") || 'sticky-bar-label-missing';

		if (dataLayer) {
			if (subLabel) {
				dataLayer.push({ 'sticky-bar-data-content': label,  'event': 'sticky-bar', 'sticky-bar-data-label': subLabel});
			} else {
				dataLayer.push({ 'sticky-bar-data-content': label, 'event': 'sticky-bar'});
			}
		}
	}


	/*
	  add some css if sticky bar is not completely in viewport
	 */
	function checkViewport() {
		let $stickyBar = $('.js-sticky-bar');
		const viewportHeight = $(window).height();
		const stickyBarOffset = $stickyBar.find('.sticky-bar__wrapper').offset();
		const stickyBarHeight = $stickyBar.find('.sticky-bar__wrapper').height();

		if (stickyBarOffset.top + stickyBarHeight > viewportHeight) {
			$stickyBar.addClass('sticky-bar--small');
		} else {
			$stickyBar.removeClass('sticky-bar--small');
		}
	}

	/*
	  The mobile trigger can show and hide the overlay and the main part of the sticky bar.
	  This doesn't open the content!
	 */
	function toggleBar() {
		if (stickyBar.classList.contains("is-active")) {
			closeBar();
		} else {
			stopAllComponents();
			// open the sticky bar
			stickyBar.classList.add("is-active");
			overlay.classList.add("is-active");
			body.classList.add("noscroll");
			stickyBar.classList.remove("content-is-visible");
			closeContent();
		}
	}

	/*
	  Show the overlay and open the content.
	  On desktop overlay and content open at once.
	 */
	function showContent(itemId) {
		closeContent();
		overlay.classList.add("is-active");

		// send the sticky bar in the background
		stickyBar.classList.add("content-is-visible");

		// close the sticky bar in the background
		stickyBar.classList.remove("is-active");

		// check if there is an iframe and set the src
		const container = document.querySelector(".overlay__container--" + itemId);
		let iframe = container.getElementsByTagName('iframe')[0];
		if (iframe && iframe.dataset.src) {
			iframe.src = iframe.dataset.src;
		}

		// open the overlay
		container.classList.add("is-active");
		body.classList.add("noscroll");
	}

	function showShareModalOnMobile(item){
		closeContent();
		overlay.classList.add("is-active");

		// send the sticky bar in the background
		stickyBar.classList.add("content-is-visible");

		// close the sticky bar in the background
		stickyBar.classList.remove("is-active");

		const attachedOverlay = item.querySelector(".attached-overlay");
		if(attachedOverlay) {
			const cloneOverlay = attachedOverlay.cloneNode(true);
			overlay.append(cloneOverlay);
			cloneOverlay.id = "attached-overlay-social-share";
		}
	}

	/*
	  open a page-centered overlay
	 */
	function openCentered() {
		const viewportWidth = window.innerWidth;
		const itemId = this.getAttribute("data-item-id");

		stopAllComponents();

		if (viewportWidth > 1023 && viewportWidth < 1360) {
			// For tablet landscape first show the label of the sticky bar button and open the content delayed.
			this.classList.add("delayed");

			setTimeout(() => {
				showContent(itemId);
				this.classList.remove("delayed");
			}, 800);

		} else {
			showContent(itemId);
		}
	}

	/*
	  open bar-attached overlay if desktop version of sticky bar
	 */
	function loadOverlay() {

		if (document.getElementsByTagName("body")[0].classList.contains('is-mobile')
		 || document.getElementsByTagName("body")[0].classList.contains('is-tablet')
		) {
			return;
		}

		const stickyBarItem = this;
		const iconType = this.getAttribute("data-icon-type");

		// if has no property of that iconType (not called at all) or is loading already or done:
		if (!contentLoadStatus.hasOwnProperty(iconType) || !['loading', 'done'].includes(contentLoadStatus[iconType])) {

			if (iconType === 'my-favorites') {

				let maxItems = 10;

				const overlay = this.querySelector(".attached-overlay");
				const loadContentUrl = overlay.getAttribute("data-load-content-url");
				let contentNode = overlay.querySelector(".overlay__favorites");

				if (!loadContentUrl) {
					console.warn("Error: data attribute load-content-url not found in attached-overlay container.");
					return;
				}

				contentLoadStatus[iconType] = 'loading';
				stopAllComponents();

				$.getJSON(loadContentUrl)
					.done(function (data) {

						if (data && typeof data === 'object') {

							for (let i = 0; i < data.length; i++) {

								if (i === maxItems) {
									break;
								}

								const label = data[i]['label'] || '';
								const url	= data[i]['source-url'] || '';

								if (!label || !url) {
									maxItems++;
									continue;
								}

								let itemNode = document.createElement("p");
								let itemLinkNode = document.createElement("a");
								itemLinkNode.setAttribute("target", "_blank");
								itemLinkNode.setAttribute("href", url);
								itemLinkNode.appendChild(document.createTextNode(label));

								itemNode.appendChild(itemLinkNode);
								contentNode.appendChild(itemNode);
							}

							contentLoadStatus[iconType] = 'done';
						}
					})
					.fail(function () {
						console.log("Could not load data from:", loadContentUrl);
						contentLoadStatus[iconType] = 'failed';
						contentNode.createTextNode("Could not find your favorites.");
					})
					.always(function () {

						// seo push if click on link
						const overlayContent = overlay.querySelector(".overlay__content");
						Array.prototype.forEach.call(overlayContent.getElementsByTagName("a"), function (link)  {
							link.addEventListener('mouseup', () => {
								pushSeoData(stickyBarItem, link.textContent);
							});
						});

						// spinner
						const spinner = contentNode.querySelector('.spinner');
						if (spinner) {
							spinner.remove();
						}
					});
			}
		}
	}

	/**
	 * open a link if we are on mobile
	 */
	function openLinkOnMobile() {
		if (!document.getElementsByTagName("body")[0].classList.contains('is-mobile')
		 && !document.getElementsByTagName("body")[0].classList.contains('is-tablet')
		) {
			return;
		}

		// handle seo push
		pushSeoData(this);

		const iconType = this.getAttribute("data-icon-type");
		if(iconType !== undefined && iconType === "social-share") {
			stopAllComponents();
			showShareModalOnMobile(this);
		}

		const overlay = this.querySelector(".attached-overlay");
		const sourceLink = overlay.getAttribute("data-source");

		if (sourceLink) {
			window.open(sourceLink, '_self');
		}
	}

	function closeContent() {
		const activeContainer = document.querySelector(".overlay__container.is-active") || document.querySelector(".attached-overlay.is-active");
		if (activeContainer !== null) {
			activeContainer.classList.remove("is-active");
		}
		const attachedOverlaySocialShare = document.querySelector("#attached-overlay-social-share");
		if(attachedOverlaySocialShare) {
			attachedOverlaySocialShare.remove();
		}
	}


	function closeBar() {
		if (stickyBar) {
			stickyBar.classList.remove("content-is-visible", "is-active");
		}
		if (overlay) {
			overlay.classList.remove("is-active");
		}
		body.classList.remove("noscroll");
		closeContent();
	}


	mobileTrigger.addEventListener('mouseup', toggleBar);

	Array.prototype.forEach.call(closeButtons, function (btn) {
		btn.addEventListener('mouseup', closeBar);
	});

	Array.prototype.forEach.call(stickyBarItemLink, function (item)  {
		item.addEventListener('mouseup', pushSeoData);
	});

	Array.prototype.forEach.call(stickyBarItemBarAttached, function (item) {
		item.addEventListener('mouseenter', loadOverlay);
		item.addEventListener('mouseup', openLinkOnMobile);
	});

	Array.prototype.forEach.call(stickyBarItemPageCentered, function (item) {
		item.addEventListener('mouseup', openCentered);
		item.addEventListener('mouseup', pushSeoData);
	});

	//FIXME: sonderlocke for non-stickybar overlays...
	Array.prototype.forEach.call(nonStickyBarOverlayTrigger, function (item) {
		item.addEventListener('mouseup', openCentered);
	});

	//close the sticky bar if another component is opened
	window.addEventListener('stopAllComponents', closeBar);

	// Close everything if escape key is pressed
	window.addEventListener('keyup', e => {
		if (e.keyCode === 27) {
			closeBar();
		}
	});

	$(window).resize(function () {
		checkViewport();
	});

	checkViewport();
};
