import stopAllComponents from '../../../assets/scripts/helpers/stop-components';

export default function () {

	const searchButtons = document.getElementsByClassName('js-search-submit');
	const searchTriggers = document.getElementsByClassName('js-search-trigger');
	const headerSearch = document.querySelector('nav.omv-main .search');

	if (searchButtons.length === 0) {
		return;
	}


	/**
     Since the search button is only visible if the search field is in focus the submit doesn't work on click. It must
     be triggered on mousedown.
	 */
	function handleSubmit() {
		const form = this.parentNode;
    let submitIsValid = false;
    if (form.querySelector('input[name="searchText"]') !== null) {
      if (form.querySelector('input[name="searchText"]').value === "") {
        alert("Please input a Value");
        return false;
      }
      submitIsValid = form.querySelector('input[name="searchText"]').value.length > 0;
    }
    if (submitIsValid === true) {
      form.submit();
    }
    return submitIsValid;
	}

	/**
	 *  Toggle the search field
	 */
	function toggleSearchField() {

		if (headerSearch.classList.contains("is-active")) {
			// close the search field
			closeAll();
		} else {
			stopAllComponents();
			// open the search field
			Array.prototype.forEach.call(searchTriggers, function (mobileTrigger) {
				mobileTrigger.classList.add("is-active");
			});
			headerSearch.classList.add("is-active");
			headerSearch.querySelector(".input--search").focus();
		}
	}

	/**
	 * Close the search bar and set all search triggers to not active.
	 */
	function closeAll() {
		Array.prototype.forEach.call(searchTriggers, function (mobileTrigger) {
			mobileTrigger.classList.remove("is-active");
		});
		headerSearch.classList.remove("is-active");
	}

	Array.prototype.forEach.call(searchButtons, function (searchButton) {
		searchButton.addEventListener('mousedown', handleSubmit);
	});

	Array.prototype.forEach.call(searchTriggers, function (searchTrigger) {
		searchTrigger.addEventListener('mouseup', toggleSearchField);
	});

	//close the mobile search bar if another component is opened
	window.addEventListener('stopAllComponents', closeAll);
}
