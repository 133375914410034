import stopAllComponents from '../../../assets/scripts/helpers/stop-components';


export default function () {

  const triggers = document.getElementsByClassName('js-website-switcher-trigger');
  const layer = document.querySelector('.js-website-switcher-layer');
  const closeBtns = document.getElementsByClassName('js-website-switcher-close');


  if(triggers.length === 0) return;

  if(triggers.length === 1) {
    triggers[0].style.display = "none";
  }

  function toggleLayer() {
    if(this.classList.contains("is-active")) {
      closeLayer();
    }
    else {
      stopAllComponents();

      triggers[0].classList.add("is-active"); // trigger[0] is the button in the header
      layer.classList.add("is-active");
      document.querySelector("body").scrollTop = 0;
    }
  }
  function closeLayer() {
    triggers[0].classList.remove("is-active");
    layer.classList.remove("is-active");
  }


  Array.prototype.forEach.call(triggers, function(trigger) {
    trigger.addEventListener('mouseup', toggleLayer);
  });

  Array.prototype.forEach.call(closeBtns, function(btn) {
    btn.addEventListener('mouseup', closeLayer);
  });

  window.addEventListener('stopAllComponents', closeLayer);

  // Close the website switcher layer if escape key is pressed
  window.addEventListener('keyup', e => {
    if (e.keyCode === 27) {
      closeLayer();
    }
  });

};
