export default function () {


  const headlines = document.getElementsByClassName("js-slider-headline");

  // control font-size of the layers headline - decrease if more than 30 chars
  if(headlines.length > 0) {
    Array.prototype.forEach.call(headlines, function(headline) {
      if(headline.textContent.length > 30) {
        headline.classList.add("slider__headline--small");
      }
    });
  }

  let duration = 1000;
  const sliders = document.querySelectorAll(".js-slider");
  // console.log("jgdebug slider", sliders);
  if (sliders === null) {
    return;
  }

  Array.prototype.forEach.call(sliders, function (slider) {
    const slides = slider.getElementsByClassName("js-slider-slide");
    const thumbs = slider.getElementsByClassName("js-slider-thumb");
    const nextArrows = slider.querySelectorAll(".next");
    const prevArrows = slider.querySelectorAll(".prev");
    let index = 0;
    let loop;
    let touchstartX,
      touchstartY,
      touchendX,
      touchendY = 0;
    if (thumbs.length <= 1) {
      return;
    }
    //console.log("jgdebug found slider");
    function loopSlides() {
      index++;
      switchSlide(index % thumbs.length);
    }

    function handleClick(e) {
      if (!slider.classList.contains("slider--v2")) {
        clearInterval(loop);
      }
      const index = Array.from(e.target.parentElement.children).indexOf(
        e.target
      );
      switchSlide(index);
    }

    function switchSlide(index) {
      slider
        .querySelector(".js-slider-slide.is-active")
        .classList.remove("is-active");
      slider
        .querySelector(".js-slider-thumb.is-active")
        .classList.remove("is-active");
      const layer = slider.querySelector(".js-slider-layer.is-active");
      if (layer) {
        layer.classList.remove("is-active");
      }
      // console.log("jgdebug index:", index);
      slides[index].classList.add("is-active");
      thumbs[index].classList.add("is-active");
      const layerId = thumbs[index].getAttribute("data-layer");
      // console.log("jgdebug layerID:", layerId);
      const nextLayer = document.getElementById(layerId);
      if (nextLayer) {
        nextLayer.classList.add("is-active");
      }
    }

    if (slider) {
      const time = slider.getAttribute("data-duration");
      if (time) {
        duration = time;
      }
    }

    function initLoop() {
      loop = setInterval(function () {
        loopSlides();
      }, duration);
    }
    initLoop();

    Array.prototype.forEach.call(thumbs, function (thumb) {
      thumb.addEventListener("mouseup", handleClick);
      thumb.addEventListener("dblclick", initLoop);
    });

    if (nextArrows !== null) {
      Array.prototype.forEach.call(nextArrows, function (nextArrow) {
        nextArrow.addEventListener("mouseup", function () {
          if (index < thumbs.length - 1) {
            index++;
          } else {
            index = 0;
          }
          switchSlide(index % thumbs.length);
        });
      });
    }
    if (prevArrows !== null) {
      Array.prototype.forEach.call(prevArrows, function (prevArrow) {
        prevArrow.addEventListener("mouseup", function () {
          if (index > 0) {
            index--;
          } else {
            index = thumbs.length - 1;
          }
          switchSlide(index % thumbs.length);
        });
      });
    }

    slider.addEventListener(
      "touchstart",
      function (event) {
        touchstartX = event.changedTouches[0].screenX;
        touchstartY = event.changedTouches[0].screenY;
      },
      { passive: true }
    );

    slider.addEventListener(
      "touchend",
      function (event) {
        touchendX = event.changedTouches[0].screenX;
        touchendY = event.changedTouches[0].screenY;
        handleGesture();
      },
      false
    );
    function handleGesture() {
      if (touchendX < touchstartX) {
        console.log("Swiped Left");
        clearInterval(loop);
        if (index < thumbs.length - 1) {
          index++;
        } else {
          index = 0;
        }
        switchSlide(index % thumbs.length);
      }

      if (touchendX > touchstartX) {
        console.log("Swiped Right");
        clearInterval(loop);
        if (index > 0) {
          index--;
        } else {
          index = thumbs.length - 1;
        }
        switchSlide(index % thumbs.length);
      }

      if (touchendY === touchstartY) {
        console.log("Tap");
      }
    }
  });
}
