import triggerResize from '../../../assets/scripts/helpers/resize-trigger.js';


export default function () {

  const tabs = document.getElementsByClassName("tabs__item");
  const tabsMobile = document.querySelector(".tabs__mobile");

  if(tabs.length == 0) return;


  /*
    Set tabs active / inactive.
   */
  function switchTabs() {

    const index = [...this.parentElement.getElementsByClassName("tabs__item")].indexOf(this);
    const contentWrapper = this.parentElement.parentElement.parentElement.querySelector(".tabs__content-wrapper");
    const contentElement = contentWrapper.children[index];
    const activeElement = contentWrapper.querySelector(".is-active");

    if(activeElement) {
      activeElement.classList.remove("is-active");
    }
    if(contentElement) {
      contentElement.classList.add("is-active");
    }

    if(this.parentElement.querySelector(".tabs__mobile")) {
      // select the corresponding option in the select box to keep the tabs and the select box (for mobile) in sync
      this.parentElement.parentElement.getElementsByTagName('option')[index].selected = 'selected';
    }

    triggerResize();
  }


  /*
    Mobile: get the selection of a select box and activate the right tab.
   */
  function controlTabItems() {

    const i = this.selectedIndex;
    const el = this.parentElement.children[i].querySelector("input");
    el.click();
  }


  /**
   *  On page load show the content of the selected tab - Firefox remembers selected input fields
  */
  function clickToOpen() {
    const event = document.createEvent('Event');
    const selectedTabs = document.getElementsByClassName("tabs__item input:checked");
    event.initEvent('click', true, true);

    Array.prototype.forEach.call(selectedTabs, function(tab) {
      tab.dispatchEvent(event);
    });
  }


  Array.prototype.forEach.call(tabs, function(tab) {
    tab.addEventListener('click', switchTabs);
  });

  if(tabsMobile) {
    tabsMobile.addEventListener("change", controlTabItems);
  }

  clickToOpen();

};
