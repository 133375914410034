export default function () {
  const qrWrapper= document.querySelector(".qr-code--wrapper");
  if (qrWrapper !== null) {
    const buttonElement = qrWrapper.querySelectorAll(".tablinks");
    const generateButtonElement = qrWrapper.querySelector("#generate-qr-code");
    const tabContent = qrWrapper.querySelectorAll(".tabcontent");
    const serviceUrl = qrWrapper.getAttribute("data-host") !== null ? qrWrapper.getAttribute("data-host") : "";
    tabContent[0].style.display = "flex";

    buttonElement.forEach(function (item) {
      item.addEventListener("click", function (event) {
        const errorFields = qrWrapper.querySelectorAll(".error");
        errorFields.forEach(function (errorFields) {
          errorFields.classList.remove("error");
        });

        const qrCodeItems = qrWrapper.querySelectorAll(".qr-code--item");
        qrCodeItems.forEach(function (qrCodeItem) {
          qrCodeItem.innerHTML = `
            <img src="/assets/images/qr-code.png" class="qr-code-default" alt="QR-Code">
            <div class="spinner"></div>
          `;
        });
        Array.prototype.forEach.call(qrWrapper.querySelectorAll(".button--link-container"),
          (qrCodeButton) => {
            const parentNode = qrCodeButton.parentNode;
            if (qrCodeButton.hasAttribute("style")) {
              qrCodeButton.removeAttribute("style");
            }
            qrCodeButton = removeAllListeners(qrCodeButton);
          }
        );

        for (let x = 0; x < buttonElement.length; x++) {
          if (event.target.id == buttonElement[x].id) {
            buttonElement[x].className = buttonElement[x].className.replace(" active","");
            tabContent[x].style.display = "flex";
            tabContent[x].className += " active";
            event.currentTarget.className += " active";
          } else {
            tabContent[x].style.display = "none";
            tabContent[x].className = tabContent[x].className.replace(" active","");
            buttonElement[x].className = buttonElement[x].className.replace(" active","");
          }
        }
      });
    });
    if(generateButtonElement !== null){
      generateButtonElement.addEventListener("click", function (event) {
        const qrCodeItems = qrWrapper.querySelectorAll(".qr-code--item");
        qrCodeItems.forEach(function (qrCodeItem) {
          const styles = window.getComputedStyle(qrCodeItem);
          if (styles.display !== 'none') {
            const spinner = qrCodeItem.querySelector(".spinner");
            let dataUrl = checkFields(serviceUrl);
            if (dataUrl !== null) {
              spinner.classList.add("loading");
              // console.log("jgdebug serviceUrl", dataUrl);
              // console.log("jgdebug serviceUrl", serviceUrl);
              // decodeURIComponent(dataUrl);
              // dataUrl = decodeURIComponent(dataUrl);
              //console.log("jgdebug serviceUrl", dataUrl);
              fetch(dataUrl)
                .then((response) => response.json())
                .then((data) => {
                  const qrCodeImageUrl = serviceUrl+"/services/downloads" + data.uri;
                  const qrCodeImage = qrCodeItem.querySelector(
                    ".qr-code--item img"
                  );
                  if (qrCodeImage !== null) {
                    spinner.classList.remove("loading");
                    qrCodeImage.src = qrCodeImageUrl;
                    qrCodeImage.style.opacity = 1;
                    qrCodeImage.style.display = "block";
                    qrCodeImage.parentElement.style.padding = "0";
                  }
                  Array.prototype.forEach.call(qrCodeItem.parentNode.querySelectorAll(".button--link-container"),
                    (qrCodeButton) => {
                      qrCodeButton = removeAllListeners(qrCodeButton);
                      qrCodeButton.style.display = "block";
                      let downLoadUrl = qrCodeImageUrl;
                      let fileType = "png";
                      if (qrCodeButton.classList.contains("svg")) {
                        downLoadUrl = downLoadUrl + "?qr_code_filetype=svg";
                        fileType = "svg";
                      }
                      qrCodeButton.addEventListener(
                        "mouseup",
                        function (event) {
                          event.preventDefault();
                          downloadImage(downLoadUrl, fileType);
                          // console.log(qrCodeButton.parentNode);
                        }
                      );
                    }
                  );
                })
                .catch((e) => {
                  spinner.classList.remove("loading");
                  alert("An error occurred while generating the QR code. Please try again later.");
                  console.error(e);
                });
            }
          }
        });
      });
    }
  }

  function removeAllListeners(element) {
    var clone = element.cloneNode(true);
    element.parentNode.replaceChild(clone, element);
    return clone;
  }

  function downloadImage(url, fileType) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        let file = new File([blob], "qrcode.png", {
          type: "image/png",
        });
        if (fileType === "svg") {
          file = new File([blob], "qrcode.svg", {
            type: "image/svg+xml",
          });
        }
        const link = document.createElement("a");
        const url = URL.createObjectURL(file);

        link.href = url;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
  }

  function checkFields(serviceUrl) {
    const activeTabContent = qrWrapper.querySelector(".tabcontent.active");

    let dataUrl = null;
    if (activeTabContent !== null) {
      const requiredFields = activeTabContent.querySelectorAll("[required]");
      let inputCheckFailed = false;
      requiredFields.forEach(function (requiredField) {
        const labelElement = activeTabContent.querySelector(
          "[for='" + requiredField.id + "']"
        );
        if (labelElement !== null) {
          labelElement.classList.remove("error");
          labelElement.classList.remove("error-email-validation");
          labelElement.classList.remove("error-url-validation");
        }
        requiredField.classList.remove("error");
        if (inputCheckFailed) {
          return;
        }
        if (requiredField.value.length === 0) {
          requiredField.classList.add("error");
          if (labelElement !== null) {
            labelElement.classList.add("error");
          }
          inputCheckFailed = true;
          return;
        }
        if (requiredField.type === "email") {
          inputCheckFailed = !validateEmail(requiredField);
          if (inputCheckFailed === true) {
            requiredField.classList.add("error");
            if (labelElement !== null) {
              labelElement.classList.add("error-email-validation");
            }
            return;
          }
        }
        if (requiredField.name !== null && requiredField.name === "Url") {
          const isValidUrl = (urlString) => {
            var urlPattern = new RegExp(
              "^(https?:\\/\\/)?" + // validate protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+:]*)*" + // validate port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
                "(\\#[-a-z\\d_]*)?$",
              "i"
            ); // validate fragment locator
            return !!urlPattern.test(urlString);
          };
          inputCheckFailed = !isValidUrl(requiredField.value);
          if (inputCheckFailed === true) {
            requiredField.classList.add("error");
            if (labelElement !== null) {
              labelElement.classList.add("error-url-validation");
            }
          }
        }
        return;
      });
      if (inputCheckFailed === false) {
        dataUrl = serviceUrl + "/services/downloads/qrcode/create?qr_code_type=";
        dataUrl += activeTabContent.getAttribute("data-qr_code_type");
        const inputFields = activeTabContent.querySelectorAll("[id]");
        inputFields.forEach(function (inputField) {
          //const inputValue = encodeURIComponent(inputField.value);
          let inputValue = inputField.value.replace(/&/g, '%26').replace(/:/g, '%3A').replace(/#/g, '%23').replace(/\//g, '%2F').replace(/\?/g, '%3F').replace(/=/g, '%3D');
          // uppercase all escaped characters
          inputValue = inputValue.replace(/%[0-9a-f]{2}/g, function (x) {
             return x.toUpperCase();
          });
          //base64 encode the value
          inputValue = btoa(inputValue);
          dataUrl += "&" + inputField.id + "=" + inputValue;
          dataUrl += "&base64=true";
          console.log("jgdebug dataUrl base 64 url", dataUrl);
        });
      }
    }
    return dataUrl;
  }

  function validateEmail(inputText) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (inputText.value.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }
}
