export default function () {
  const pressSlider = document.querySelector(".press-slider");
  if (pressSlider === null) return;
  let touchstartX,
    touchstartY,
    touchendX,
    touchendY = 0;
  const carousel = pressSlider.querySelector("[data-target='carousel']");
  const buttonWrapper = pressSlider.querySelector(".button-wrapper");
  const card = carousel.querySelector("[data-target='card']");
  const leftButton = pressSlider.querySelector("[data-action='slideLeft']");
  const rightButton = pressSlider.querySelector("[data-action='slideRight']");
  const dots = pressSlider.querySelector(".dots");
  const sliderDots = pressSlider.getElementsByClassName("slider__dot");
  let carouselIndex = 0;
  const mediaQuery = window.matchMedia("(max-width: 767px)");
  const isMobile = mediaQuery.matches;
  const viewItems = 1;
  const carouselEndIndex = 3;

  // Prepare to limit the direction in which the carousel can slide,
  // and to control how much the carousel advances by each time.
  // In order to slide the carousel so that only three cards are perfectly visible each time,
  // you need to know the carousel width, and the margin placed on a given card in the carousel
  const carouselWidth = carousel.offsetWidth;
  const cardStyle = card.currentStyle || window.getComputedStyle(card);
  const cardMarginRight = Number(cardStyle.marginRight.match(/\d+/g)[0]);

  // Count the number of total cards you have
  const cardCount = carousel.querySelectorAll("[data-target='card']").length;

  // Define an offset property to dynamically update by clicking the button controls
  // as well as a maxX property so the carousel knows when to stop at the upper limit
  let offset = 0;
  const maxX = -(
    (cardCount / viewItems) * carouselWidth +
    cardMarginRight * (cardCount / viewItems) -
    carouselWidth -
    cardMarginRight
  );

  // Add the click events
  leftButton.addEventListener("click", goLeft);

  rightButton.addEventListener("click", goRight);

  carousel.addEventListener(
    "touchstart",
    function (event) {
      touchstartX = event.changedTouches[0].screenX;
      touchstartY = event.changedTouches[0].screenY;
    },
    { passive: true }
  );

  carousel.addEventListener(
    "touchend",
    function (event) {
      touchendX = event.changedTouches[0].screenX;
      touchendY = event.changedTouches[0].screenY;
      handleGesture();
    },
    false
  );


  Array.prototype.forEach.call(
    pressSlider.querySelectorAll(".slider__dot"),
    (sliderDot) => {
      sliderDot.addEventListener(
        "click",
        function (event) {
          const dotItemIndex = Array.from(sliderDot.parentNode.children).indexOf(sliderDot);
          const currentCarouselIndex = carouselIndex;
          if (dotItemIndex > currentCarouselIndex) {
            for (let i = currentCarouselIndex + 1; i <= dotItemIndex; i++) {
              goRight();
            }
          } else if (dotItemIndex < currentCarouselIndex) {
            for (let i = currentCarouselIndex - 1; i >= dotItemIndex; i--) {
              goLeft();
            }
          }
        },
        false
      );
    }
  );

  Array.prototype.forEach.call(
    carousel.querySelectorAll(".pressItem"),
    (pressItem) => {
      pressItem.addEventListener(
        "click",
        function (event) {
          const dataUrl = pressItem.getAttribute("data-url");
          if (dataUrl !== null) {
            location.href = dataUrl;
          }
        },
        false
      );
    }
  );

  function handleGesture() {
    if (touchendX < touchstartX) {
      console.log("Swiped Left");
      goRight();
    }

    if (touchendX > touchstartX) {
      console.log("Swiped Right");
      goLeft();
    }

    if (touchendY === touchstartY) {
      console.log("Tap");
    }
  }

  function goLeft() {
    console.log("jgdebug goLeft viewItems", offset);
    if (offset !== 0) {
      offset += carouselWidth + cardMarginRight;
      carousel.style.transform = `translateX(${offset}px)`;
      if (carouselIndex > 0) {
        carouselIndex--;
      }
      dots
        .querySelector(".slider__dot.is-active")
        .classList.remove("is-active");
      sliderDots[carouselIndex].classList.add("is-active");
    }
  }

  function goRight() {
    console.log("jgdebug goRight viewItems", viewItems);
    if (offset !== maxX) {
      offset -= carouselWidth + cardMarginRight;
      carousel.style.transform = `translateX(${offset}px)`;
      if (carouselIndex <= carouselEndIndex) {
        carouselIndex++;
      }
      dots
        .querySelector(".slider__dot.is-active")
        .classList.remove("is-active");
      sliderDots[carouselIndex].classList.add("is-active");
    }
  }
}
