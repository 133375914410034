export default function () {
  const correspondenceAgreementForm = document.querySelector(
    ".correspondence-agreement-form--wrapper"
  );
  const language = document.querySelector("html").getAttribute("lang");
  let apiClientKey,
    crm_number_type,
    crm_number,
    code_clc,
    client_email,
    AccountGuid,
    AccountName;
  if (correspondenceAgreementForm !== null) {
    // console.log("correspondence-agreement.js loaded");
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("key")) {
      const keyParam = queryParams.get("key");
      const validateKeyUrl = "/services/onlineagreement/validatekey";
      const formData = new FormData();
      formData.append("key", keyParam);
      fetch(validateKeyUrl, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json()
        })
        .then((data) => {
          // console.log("validate key is sent");
          // console.log("responseJson: ", data);
          if (data.hasOwnProperty("email")) {
            const emailInput = correspondenceAgreementForm.querySelector(
              "#email"
            );
            if (emailInput !== null) {
              emailInput.parentNode.classList.remove("is-invalid");
              emailInput.parentNode.classList.add("is-valid");
              emailInput.setAttribute("disabled", "disabled");
              emailInput.value = data.email;
              apiClientKey = keyParam;
              client_email = data.email;
            }
            const captchaRow = correspondenceAgreementForm.querySelector(
              "#captcha-row"
            );
            if (captchaRow !== null) {
              const captchaElement = document.createElement("div");
              captchaElement.setAttribute("class", "frc-captcha");
              captchaElement.dataset.sitekey = "FCMMRK2P0T5GRTAT";
              captchaElement.setAttribute(
                "data-puzzle-endpoint",
                "https://eu-api.friendlycaptcha.eu/api/v1/puzzle"
              );
              captchaElement.dataset.callback = "frcCallback";
              captchaElement.dataset.lang = language;
              captchaRow.appendChild(captchaElement);
              captchaRow.classList.remove("hide");
              const script = document.createElement("script");
              script.type = "text/javascript";
              script.src = "/assets/correspondence-agreement/friendly-challenge-0.9.12.min.js";
              document.head.appendChild(script);
              // console.log("loadScript");
            }
          }
        }).catch((error) => {
          alert("An error has occurred, please try again later or contact us by email");
          console.log("error", error)
        });
    } else {
      const validateEmailButton = correspondenceAgreementForm.querySelector(
        "#validate-email"
      );
      if (validateEmailButton !== null) {
        const emailInput = correspondenceAgreementForm.querySelector("#email");
        validateEmailButton.addEventListener("click", function () {
          // console.log("validate email button clicked");
          const email = emailInput.value;
          // console.log("email: ", email);
          const emailIsValid = validateEmail(email);
          if (emailIsValid) {
            const sendConfirmMailService =
              "/services/onlineagreement/sendconfirmmail";
            const targetUrl = window.location.origin + window.location.pathname;
            const formData = new FormData();
            formData.append("email", email);
            formData.append("lang", language);
            formData.append("targetUrl", targetUrl);
            fetch(sendConfirmMailService, {
              method: "POST",
              body: formData,
            }).then((response) => {
              if (!response.ok) {
                throw Error(response.statusText);
              }
              emailInput.parentNode.classList.remove("is-invalid");
              emailInput.parentNode.classList.add("is-valid");
              emailInput.setAttribute("disabled", "disabled");
            }).catch((error) => {
              alert("An error has occurred, please try again later or contact us by email");
              console.log("error", error)
            });
          } else {
            // console.log("email is invalid");
            emailInput.parentNode.classList.remove("is-valid");
            emailInput.parentNode.classList.add("is-invalid");
            emailInput.removeAttribute("disabled");
          }
        });
      }
    }
    const crmNumbersRow = correspondenceAgreementForm.querySelector(
      "#crm-numbers"
    );
    if (crmNumbersRow !== null) {
      // Add an event listener to the input element
      const crmNumberInputElement = crmNumbersRow.querySelector("#crm-number");
      crmNumberInputElement.addEventListener("input", (event) => {
        // Get the input value
        const inputValue = event.target.value;

        if (inputValue.match(/\D/g) !== null) {
          const errorData = event.target.getAttribute("data-error");
          if (errorData !== null) {
            alert(errorData);
          }
        }

        // Replace all non-numeric characters with an empty string
        const numericValue = inputValue.replace(/\D/g, "");

        // Set the input value to the numeric value
        event.target.value = numericValue;
      });
      const crmRadioButtons = crmNumbersRow.querySelectorAll(
        'input[name="crm_number_type"]'
      );
      const crmNumerLabel = document.querySelector(
        'label[for="crm-number"] span'
      );
      let replacerString = "CNP";
      crmRadioButtons.forEach((radioButton) => {
        radioButton.addEventListener("click", (event) => {
          if (crmNumerLabel !== null) {
            if (radioButton.checked === true) {
              const replaceRegex = new RegExp(replacerString, "g");
              const textContent = crmNumerLabel.textContent.replace(
                replaceRegex,
                event.target.value
              );
              crmNumerLabel.textContent = textContent;
              replacerString = event.target.value;
            }
          }
        });
      });
      const crmNumbersButton = crmNumbersRow.querySelector(
        "#validate-crm-client"
      );
      const crmApiDownRow = correspondenceAgreementForm.querySelector(
        "#api-down"
      );
      if (crmNumbersButton !== null) {
        crmNumbersButton.addEventListener("click", function (e) {
          e.preventDefault();
          const checkFields = checkRequiredFields(crmNumbersRow, true);
          if (checkFields === true && apiClientKey !== null) {
            // call CRM API
            crmNumbersButton.parentNode.parentNode.classList.add("loading");
            // https://authoring-dev.omvproject.com/sites/Satellite?pagename=CustomElements/Utils/Verification&crm-number=1690921420011&crm-dp=MGABVTAR00126&crm-email=jg@exa.de&apitype=crm
            const apitype = "crm";
            crm_number = crmNumbersRow.querySelector("#crm-number").value;
            code_clc = crmNumbersRow.querySelector("#code-clc").value;
            crm_number_type = crmNumbersRow.querySelector(
              'input[name="crm_number_type"]:checked'
            ).value;
            const crmApiUrl = `/sites/Satellite?pagename=CustomElements/Utils/Verification&crm-id=${crm_number}&crm-dp=${code_clc}&crm-email=${client_email}&apitype=${apitype}`;
            fetch(crmApiUrl)
              .then((response) => {
                if (!response.ok) {
                  crmIsNotAvailable();
                }
                return response.json();
              })
              .then((data) => {
                if (
                  data.hasOwnProperty("StatusCode") &&
                  data.StatusCode === "Found"
                ) {
                  crmNumbersButton.parentNode.parentNode.classList.remove(
                    "is-invalid"
                  );
                  crmNumbersButton.parentNode.parentNode.classList.add(
                    "is-valid"
                  );

                  const allInputs = crmNumbersRow.querySelectorAll("input");
                  allInputs.forEach(function (input) {
                    input.setAttribute("disabled", "disabled");
                  });
                  if (
                    data.hasOwnProperty("EmailExists") &&
                    data.EmailExists === false
                  ) {
                    AccountGuid = data.AccountGuid;
                    AccountName = data.AccountName;
                    const hideFormRows = document.querySelectorAll(
                      "#correspondence-agreement-form .correspondence-agreement-form-row.hide"
                    );
                    if (hideFormRows !== null) {
                      hideFormRows.forEach(function (row) {
                        row.classList.remove("hide");
                      });
                    }
                    const submitButton = document.querySelector(
                      "#correspondence-agreement-submit"
                    );
                    if (submitButton !== null) {
                      submitButton.classList.remove("hide");
                    }
                    const crmAction = crmNumbersRow.getAttribute("data-crmaction");
                    if (crmAction !== null) {
                      var expires = (new Date(Date.now()+ (30*60*1000))).toUTCString();
                      document.cookie = `caapi=${crmAction}; path=/; expires=${expires}`;
                    }
                  } else if (
                    data.hasOwnProperty("EmailExists") &&
                    data.EmailExists === true
                  ) {
                    const emailExistsRow = document.querySelector(
                      "#email-exists"
                    );
                    if (emailExistsRow !== null) {
                      emailExistsRow.classList.remove("hide");
                    }
                  }
                } else {
                  crmNumbersButton.parentNode.parentNode.classList.add(
                    "is-invalid"
                  );
                  crmNumbersButton.parentNode.parentNode.classList.remove(
                    "is-valid"
                  );
                }
                crmNumbersButton.parentNode.parentNode.classList.remove(
                  "loading"
                );
              })
              .catch((error) => {
                console.error("Error:", error);
                if (crmApiDownRow !== null) {
                  crmApiDownRow.classList.remove("hide");
                }
              })
              .finally(() => {
                crmNumbersButton.parentNode.parentNode.classList.remove(
                  "loading"
                );
              });
          }
        });
      }
    }

    const submitButton = correspondenceAgreementForm.querySelector(
      "#correspondence-agreement-submit"
    );
    if (submitButton !== null) {
      const form = correspondenceAgreementForm.querySelector(
        "form#correspondence-agreement-form"
      );
      if (form !== null) {
        form.addEventListener("change", function () {
          const checkFields = checkRequiredFields(form, false);
          if (checkFields === true) {
            submitButton.disabled = false;
          } else {
            submitButton.disabled = true;
          }
        });
      }
      // replace diacrictics
      const replaceDiacritics = function (str, errorData) {
        const diacritics = /[^a-zA-Z0-9_ -]/g;
        const character = "";
        if (str.match(diacritics) !== null) {
          if (errorData !== null) {
            alert(errorData);
          }
        }
        str = str.replace(diacritics, character);
        return str;
      };

      const inputsToReplaceDiacritics = correspondenceAgreementForm.querySelectorAll(
        ".check_diacritics"
      );
      if (inputsToReplaceDiacritics !== null) {
        inputsToReplaceDiacritics.forEach(function (input) {
          input.addEventListener("change", function () {
            const value = input.value;
            const errorData = input.getAttribute("data-error");
            const replacedValue = replaceDiacritics(value, errorData);
            input.value = replacedValue;
          });
        });
      }

      submitButton.addEventListener("click", function (e) {
        e.preventDefault();
        const documentUploadRow = correspondenceAgreementForm.querySelector(
          "#document-upload"
        );
        if (documentUploadRow !== null) {
          documentUploadRow.classList.add("loading");
        }
        const checkFields = checkRequiredFields(
          correspondenceAgreementForm,
          true
        );
        if (checkFields === true && apiClientKey !== null) {
          if (form !== null) {
            const formData = new FormData(form);
            formData.append("key", apiClientKey);
            formData.append("crm_number_type", crm_number_type);
            formData.append("crm_number", crm_number);
            formData.append("code_clc", code_clc);
            if (AccountGuid !== null) {
              formData.append("AccountGuid", AccountGuid);
            }
            if (AccountName !== null) {
              formData.append("AccountName", AccountName);
            }
            const submitUrl = "/services/onlineagreement/senddata";
            fetch(submitUrl, {
              method: "POST",
              enctype: "multipart/form-data",
              body: formData,
            })
              .then((response) => {
                if (!response.ok) {
                  throw Error(response.statusText);
                }
                return response.text();
              })
              .then((data) => {
                // console.log("response text: ", data);
                upDateClientEmail(AccountGuid, client_email);
                const formRows = form.querySelectorAll(
                  ".correspondence-agreement-form-row"
                );
                if (formRows !== null) {
                  formRows.forEach(function (row) {
                    row.classList.add("is-valid");
                    row.classList.remove("is-invalid");
                  });
                }
                const inputs = form.querySelectorAll("input");
                if (inputs !== null) {
                  inputs.forEach(function (input) {
                    input.setAttribute("disabled", "disabled");
                  });
                }
                submitButton.classList.add("hide");
                const successMessage = correspondenceAgreementForm.querySelector(
                  "#success-message"
                );
                if (successMessage !== null) {
                  successMessage.classList.remove("hide");
                }
                //window.location.replace(location.pathname);
              })
              .catch((error) => {
                alert(
                  "An error has occurred, please try again later or contact us by email"
                );
                console.log("error", error);
              })
              .finally(() => {
                if (documentUploadRow !== null) {
                  documentUploadRow.classList.remove("loading");
                }
              });
          }
        }
      });
    }
    // upload button onchange event, show file name and size
    const copieIdentification = correspondenceAgreementForm.querySelector(
      "#copie-identification"
    );
    if (copieIdentification !== null) {
      // Get input element and add event listener
      copieIdentification.onchange = function () {
         const maxSize = 1000000;
         if (this.files[0].size > maxSize) {
            const alertMessage = correspondenceAgreementForm.querySelector('label[for="copie-identification"] small');
            if (alertMessage !== null) {
               alert(alertMessage.textContent);
            }
            this.files[0].value = "";
         } else {
          correspondenceAgreementForm.querySelector("#copie-identification-name").textContent =
          this.files[0].name + " (" + this.files[0].size + " bytes)";
        }
      };
    }
  }

  //send separate email if the CRM is not available
  function crmIsNotAvailable() {
    var formdata = new FormData();
    var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
    };

    fetch("/services/onlineagreement/sendcrmisnotavailable", requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.text();
      })
      .then((result) => console.log(result))
      .catch((error) => {
        alert("An error has occurred, please try again later or contact us by email");
        console.log("error", error)
      })
      .finally(() => {
        document.cookie = "caapi=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
      });
  }

  function upDateClientEmail(accountid, email) {
    if (accountid === null || email === null) {
      return;
    }
    const crmApiUrl = `/sites/Satellite?pagename=CustomElements/Utils/Verification&crm-account-id=${accountid}&crm-email=${email}&apitype=crmupdate`;
    fetch(crmApiUrl)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        // console.log("upDateClientEmail responseJson: ", data);
      })
      .catch((error) => {
        console.error("upDateClientEmail Error:", error);
        crmIsNotAvailable();
      })
      .finally(() => {
        document.cookie = "caapi=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
      });
  }

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gim;
    return re.test(email);
  }

  // check required fields
  function checkRequiredFields(parentRow, addValidationClass) {
    const requiredInputFields = parentRow.querySelectorAll("input[required]");
    const errorMessages = parentRow.querySelectorAll(".error-message");
    if (errorMessages !== null) {
      errorMessages.forEach(function (message) {
        message.classList.add("hide");
      });
    }
    // console.log("requiredInputFields: ", requiredInputFields);
    let fieldsValid = false;
    for (const input of requiredInputFields) {
      const parentRow = input.closest(".correspondence-agreement-form-row");
      if (
        input.value === "" ||
        (input.type === "checkbox" && !input.checked) ||
        (input.type === "file" && input.files.length === 0)
      ) {
        if (parentRow !== null && addValidationClass === true) {
          parentRow.classList.add("is-invalid");
        }
        fieldsValid = false;
        break;
      } else {
        const inputCrmType = parentRow.querySelector("input[value='CNP']");
        if (inputCrmType !== null && inputCrmType.checked) {
          const inputCrmNumber = parentRow.querySelector(
            "input[name='crm_number']"
          );
          if (inputCrmNumber !== null) {
            const crmNumber = inputCrmNumber.value;
            const regex = /^[0-9]{7,13}$/;
            if (!regex.test(crmNumber)) {
              if (parentRow !== null && addValidationClass === true) {
                parentRow.classList.add("is-invalid");
              }
              const errorMessage = parentRow.querySelector("#cnp-error");
              if (errorMessage !== null) {
                errorMessage.classList.remove("hide");
              }
              fieldsValid = false;
              break;
            }
            const position4 = parseInt(crmNumber.charAt(3));
            const position5 = parseInt(crmNumber.charAt(4));
            const position6 = parseInt(crmNumber.charAt(5));
            const position7 = parseInt(crmNumber.charAt(6));
            /*
            console.log("jgdebug: position4)", position4);
            console.log("jgdebug: position5)", position5);
            console.log("jgdebug: position6)", position6);
            console.log("jgdebug: position7)", position7);
            */
            if (position4 > 1) {
              if (parentRow !== null && addValidationClass === true) {
                parentRow.classList.add("is-invalid");
              }
              const errorMessage = parentRow.querySelector("#cnp-error");
              if (errorMessage !== null) {
                errorMessage.classList.remove("hide");
              }
              fieldsValid = false;
              break;
            } else if (position4 === 0 && position5 === 0) {
              if (parentRow !== null && addValidationClass === true) {
                parentRow.classList.add("is-invalid");
              }
              const errorMessage = parentRow.querySelector("#cnp-error");
              if (errorMessage !== null) {
                errorMessage.classList.remove("hide");
              }
              fieldsValid = false;
              break;
            } else if (position4 === 1 && position5 > 2) {
              if (parentRow !== null && addValidationClass === true) {
                parentRow.classList.add("is-invalid");
              }
              const errorMessage = parentRow.querySelector("#cnp-error");
              if (errorMessage !== null) {
                errorMessage.classList.remove("hide");
              }
              fieldsValid = false;
              break;
            } else if (position6 > 3) {
              if (parentRow !== null && addValidationClass === true) {
                parentRow.classList.add("is-invalid");
              }
              const errorMessage = parentRow.querySelector("#cnp-error");
              if (errorMessage !== null) {
                errorMessage.classList.remove("hide");
              }
              fieldsValid = false;
              break;
            } else if (position6 === 0 && position7 === 0) {
              if (parentRow !== null && addValidationClass === true) {
                parentRow.classList.add("is-invalid");
              }
              const errorMessage = parentRow.querySelector("#cnp-error");
              if (errorMessage !== null) {
                errorMessage.classList.remove("hide");
              }
              fieldsValid = false;
              break;
            } else if (position6 === 3 && position7 > 1) {
              if (parentRow !== null && addValidationClass === true) {
                parentRow.classList.add("is-invalid");
              }
              const errorMessage = parentRow.querySelector("#cnp-error");
              if (errorMessage !== null) {
                errorMessage.classList.remove("hide");
              }
              fieldsValid = false;
              break;
            }
            fieldsValid = true;
          } else {
            if (parentRow !== null && addValidationClass === true) {
              parentRow.classList.remove("is-invalid");
            }
            fieldsValid = true;
          }
        } else {
          if (parentRow !== null && addValidationClass === true) {
            parentRow.classList.remove("is-invalid");
          }
          fieldsValid = true;
        }
      }
    }
    return fieldsValid;
  }
}
