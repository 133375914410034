/**
 * Scroll to an element
 *
 * element: the element to scroll to (selector)
 * duration: time in milliseconds
 * delay: time in milliseconds
 *
 * The idea comes from: https://gist.github.com/andjosh/6764939
 */
function scrollToElement(element, duration, delay) {

  const start = window.pageYOffset;
  const increment = 20;
  let currentTime = 0;

  const animateScroll = (() => {
    currentTime += increment;

    const change = element.offsetTop - start;
    const val = Math.easeInOutQuad(currentTime, start, change, duration);
    // set the value twice for Safari...
    document.body.scrollTop = val;
    // ... for Chrome
    document.documentElement.scrollTop = val;
    if (currentTime < duration && change != 0) {
      // if change = 0 we're already there - no need to scroll
      setTimeout(animateScroll, increment);
    }
  });

  setTimeout(function(){
    animateScroll();
  }, delay);
}

Math.easeInOutQuad = function (t, b, c, d) {

  t /= d/2;
  if (t < 1) return c/2*t*t + b;
  t--;
  return -c/2 * (t*(t-2) - 1) + b;
};

export default scrollToElement;