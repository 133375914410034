/**
 * Init all share buttons.
 *
 * All scripts are not loaded until the user clicks the share button - no data should be transferred before, for more privacy
 */


export default function () {

  /*
   * two-click-behaviour on share links
   *
   */
  const twoClickStuff = document.querySelectorAll('.share-panel .tt-trigger');
  Array.prototype.forEach.call(twoClickStuff, (item) => {
    item.addEventListener('click', e => {
      if (item.classList.contains('clicked1')) {
        item.classList.add('clicked2');
        item.classList.remove('clicked1');

      } else {
        item.classList.add('clicked');
        item.classList.add('clicked1');
        e.preventDefault();
      }
    });
  });



  /**
   * Load various social services.
   * Facebook: share button and timeline
   * Google: share button
   *
   * @param d
   * @param s
   * @param id
   */
  function initSocialService(d, s, id, url) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = url;
    fjs.parentNode.insertBefore(js, fjs);
  }


  /**
   * Facebook
   *
   * @type {NodeList}
   */
  const facebookBtns = document.querySelectorAll(".js-unlock-share-facebook");
  if(facebookBtns.length > 0) {
    Array.prototype.forEach.call(facebookBtns, function (facebookBtn) {
      facebookBtn.addEventListener('mousedown', function () {
        initSocialService(document, 'script', 'facebook-jssdk', 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.1');
      });
    });
  }

  /**
   * LinkedIn
   *
   * @type {NodeList}
   */
  const linkedInBtns = document.querySelectorAll(".js-unlock-share-linkedin");
  if(linkedInBtns.length > 0) {
    Array.prototype.forEach.call(linkedInBtns, function (linkedInBtn) {
      linkedInBtn.addEventListener('mousedown', function () {
        initSocialService(document, 'script', '', '//platform.linkedin.com/in.js');

        Array.prototype.forEach.call(linkedInBtns, function (linkedInBtn) {
          linkedInBtn.classList.add("is-active");
        });
      });
    });
  }


  /**
   * Twitter
   *
   * @param d
   * @param s
   * @param id
   * @returns {Window.twttr|*|{}}
   */
  window.twttr = function(d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0],
      t = window.twttr || {};
    if (d.getElementById(id)) return t;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://platform.twitter.com/widgets.js";
    fjs.parentNode.insertBefore(js, fjs);

    t._e = [];
    t.ready = function(f) {
      t._e.push(f);
    };

    return t;
  };

  const twitterBtns = document.querySelectorAll(".js-unlock-share-twitter");
  if(twitterBtns.length > 0) {
    Array.prototype.forEach.call(twitterBtns, function (twitterBtn) {
      twitterBtn.addEventListener('mousedown', function () {
        window.twttr(document, "script", "twitter-wjs");

        Array.prototype.forEach.call(twitterBtns, function (twitterBtn) {
          twitterBtn.classList.add("is-active");
        });
      });
    });
  }


  /**
   * Google+
   *
   * @type {NodeList}
   */
  const gPlusBtns = document.querySelectorAll(".js-unlock-share-gplus");
  if(gPlusBtns.length > 0) {
    Array.prototype.forEach.call(gPlusBtns, function (gPlusBtn) {
      gPlusBtn.addEventListener('mousedown', function () {
        initSocialService(document, 'script', '', 'https://apis.google.com/js/platform.js');

        Array.prototype.forEach.call(gPlusBtns, function (gPlusBtn) {
          gPlusBtn.classList.add("is-active");
        });
      });
    });
  }
};
